@font-face {
    font-family: "GTWalsheimPro";
    src: url("./GTWalsheimPro-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "GTWalsheimPro";
    src: url("./GTWalsheimPro-Medium.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: normal;
    src: local("DM Sans Regular"), url("./DMSans-Regular.woff") format("woff");
}

@font-face {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: bold;
    src: local("DM Sans Bold"), url("./DMSans-Bold.woff") format("woff");
}

@font-face {
    font-family: "DM Sans";
    font-style: italic;
    font-weight: normal;
    src: local("DM Sans Italic"), url("./DMSans-Italic.woff") format("woff");
}

/*# sourceMappingURL=fonts.css.map */
