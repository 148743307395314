@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");

a,
*,
button {
    outline: none !important;
}

.close:active, .close:focus {
    outline: none;
}

.br-tl {
    border-top-left-radius: 0.29rem !important;
}

.br-tr {
    border-top-right-radius: 0.29rem !important;
}

.br-bl {
    border-bottom-left-radius: 0.29rem !important;
}

.br-br {
    border-bottom-right-radius: 0.29rem !important;
}

@media (min-width: 1280px) {
    .br-lg-right-0 {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
}

@media (min-width: 1920px) {
    .br-xl-right-0 {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
}

.opacity-1 {
    opacity: 0.1 !important;
}

.opacity-2 {
    opacity: 0.2 !important;
}

.opacity-3 {
    opacity: 0.3 !important;
}

.opacity-4 {
    opacity: 0.4 !important;
}

.opacity-5 {
    opacity: 0.5 !important;
}

.opacity-6 {
    opacity: 0.6 !important;
}

.opacity-7 {
    opacity: 0.7 !important;
}

.opacity-8 {
    opacity: 0.8 !important;
}

.opacity-9 {
    opacity: 0.9 !important;
}

.d-30 {
    height: 30px !important;
    line-height: 30px !important;
    width: 30px !important;
}

.d-40 {
    height: 40px !important;
    line-height: 40px !important;
    width: 40px !important;
}

.d-50 {
    height: 50px !important;
    line-height: 50px !important;
    width: 50px !important;
}

.d-60 {
    height: 60px !important;
    line-height: 60px !important;
    width: 60px !important;
}

.d-70 {
    height: 70px !important;
    line-height: 70px !important;
    width: 70px !important;
}

.d-80 {
    height: 80px !important;
    line-height: 80px !important;
    width: 80px !important;
}

.d-90 {
    height: 90px !important;
    line-height: 90px !important;
    width: 90px !important;
}

.d-100 {
    height: 100px !important;
    line-height: 100px !important;
    width: 100px !important;
}

.d-110 {
    height: 110px !important;
    line-height: 110px !important;
    width: 110px !important;
}

.d-120 {
    height: 120px !important;
    line-height: 120px !important;
    width: 120px !important;
}

.d-130 {
    height: 130px !important;
    line-height: 130px !important;
    width: 130px !important;
}

.d-140 {
    height: 140px !important;
    line-height: 140px !important;
    width: 140px !important;
}

.line-height-sm {
    line-height: 1.3;
}

.line-height-md {
    line-height: 2;
}

.line-height-1 {
    line-height: 1;
}

.h-auto {
    height: auto;
}

.h-100 {
    height: 100%;
}

.w-auto {
    width: auto;
}

.w-43 {
    width: 43%;
}

.w-50 {
    min-width: 50%;
}

.w-70 {
    min-width: 70%;
}

.max-w-70 {
    max-width: 70%;
}

.divider {
    background: #eeeff8;
    height: 1px;
    overflow: hidden;
}

.text-decoration-underline {
    text-decoration: underline !important;
}

.border-1 {
    border-width: 1px !important;
    border-style: solid;
}

.border-2 {
    border-width: 2px !important;
    border-style: solid;
}

.border-3 {
    border-width: 3px !important;
    border-style: solid;
}

.font-size-xs {
    font-size: 0.7307692308rem;
}

.font-size-sm {
    font-size: 0.83125rem;
}

.font-size-md {
    font-size: 0.95rem;
}

.font-size-lg {
    font-size: 1.1875rem;
}

.font-size-xl {
    font-size: 1.425rem;
}

.font-size-xxl {
    font-size: 1.6625rem;
}

.bg-composed-wrapper--bg, .bg-composed-wrapper--image {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 3;
    opacity: 0.15;
}

.bg-composed-wrapper {
    position: relative;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    display: flex;
    align-items: center;
}

.bg-composed-wrapper--image {
    background-size: cover;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.bg-composed-wrapper--content {
    position: relative;
    z-index: 5;
    width: 100%;
}

.bg-composed-wrapper--bg {
    z-index: 5;
}

.bg-composed-filter-rm {
    filter: grayscale(0%);
}

.shadow-xxl {
    box-shadow: 0 0.18rem 0.3rem rgba(166, 166, 185, 0.5), 0 0.326rem 3rem rgba(122, 123, 151, 0.3) !important;
}

.shadow-sm-dark {
    box-shadow: 0 0.313rem 0.8rem rgba(7, 9, 25, 0.3), 0 0.126rem 0.225rem rgba(7, 9, 25, 0.1) !important;
}

.bg-white-50 {
    background-color: rgba(255, 255, 255, 0.2);
}

.text-black {
    color: #070919;
}

.img-fit-container {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.gradient-icon {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.blockquote {
    font-family: "Times New Roman", Times, serif;
}

.p-top-a {
    top: auto !important;
}

.pos-r {
    left: auto;
    right: 0;
}

.rounded-right,
.rounded-left {
    border-radius: 0 !important;
}

.d-44 {
    height: 44px;
    line-height: 44px;
    width: 44px;
}

.MuiGrid-item,
.MuiPaper-root {
    position: relative;
}

.MuiCard-root {
    transition: all 0.2s ease-in-out;
}

.btn-gradient .MuiButton-label {
    display: block;
}

a.MuiButton-outlined:hover {
    color: initial;
}

.min-w-auto {
    min-width: 5px !important;
}

.overflow-visible {
    overflow: visible !important;
}

.overflow-auto {
    overflow: auto !important;
}

.MuiButton-root.btn-inverse:hover {
    background-color: rgba(255, 255, 255, 0.07);
}

.card-box.mb-4,
a.card.mb-4,
.MuiCard-root.mb-4 {
    margin-bottom: 16px !important;
}

.mb-4-spacing {
    margin-bottom: 32px !important;
}

.m-center {
    margin: 0 auto !important;
}

.visuallyHidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: 20px;
    width: 1px;
}

.table .thead-light th span {
    color: #3b3e66;
    border-color: #dcdef1;
}

.main-loader {
    position: absolute;
    top: 0;
    height: 10px;
    width: 100%;
    z-index: 999;
}

@media (max-width: 1279.98px) {
    .login-title-hide-md {
        display: none;
    }
}

.min-vh-100-md-up {
    min-height: 100%;
}

@media (min-width: 960px) {
    .min-vh-100-md-up {
        min-height: 100vh !important;
    }
}

@media (max-width: 599.98px) {
    .login-title-hide-xs {
        display: none;
    }
}

@media (max-width: 959.98px) {
    .margin-top-sm {
        margin-top: 50px;
    }
}

.bg-neutral-primary {
    background-color: rgb(235.6310344828, 237.5206896552, 246.9689655172) !important;
}

a.bg-neutral-primary:hover, a.bg-neutral-primary:focus,
button.bg-neutral-primary:hover,
button.bg-neutral-primary:focus {
    background-color: rgb(199.5793103448, 204.9862068966, 232.0206896552) !important;
}

.bg-neutral-secondary {
    background-color: white !important;
}

a.bg-neutral-secondary:hover, a.bg-neutral-secondary:focus,
button.bg-neutral-secondary:hover,
button.bg-neutral-secondary:focus {
    background-color: rgb(229.5, 229.5, 229.5) !important;
}

.bg-neutral-success {
    background-color: rgb(229.1709677419, 249.4290322581, 237.2741935484) !important;
}

a.bg-neutral-success:hover, a.bg-neutral-success:focus,
button.bg-neutral-success:hover,
button.bg-neutral-success:focus {
    background-color: rgb(187.2193548387, 240.3806451613, 208.4838709677) !important;
}

.bg-neutral-info {
    background-color: rgb(231.8678571429, 251.3714285714, 252.7321428571) !important;
}

a.bg-neutral-info:hover, a.bg-neutral-info:focus,
button.bg-neutral-info:hover,
button.bg-neutral-info:focus {
    background-color: rgb(185.4214285714, 244.0857142857, 248.1785714286) !important;
}

.bg-neutral-warning {
    background-color: rgb(255, 251.1923076923, 241.5) !important;
}

a.bg-neutral-warning:hover, a.bg-neutral-warning:focus,
button.bg-neutral-warning:hover,
button.bg-neutral-warning:focus {
    background-color: rgb(255, 236.8076923077, 190.5) !important;
}

.bg-neutral-danger {
    background-color: rgb(255, 244.6, 245.8682926829) !important;
}

a.bg-neutral-danger:hover, a.bg-neutral-danger:focus,
button.bg-neutral-danger:hover,
button.bg-neutral-danger:focus {
    background-color: rgb(255, 193.6, 201.087804878) !important;
}

.bg-neutral-dark {
    background-color: rgb(245.2125, 245.2125, 247.3875) !important;
}

a.bg-neutral-dark:hover, a.bg-neutral-dark:focus,
button.bg-neutral-dark:hover,
button.bg-neutral-dark:focus {
    background-color: rgb(216.525, 216.525, 225.075) !important;
}

.bg-neutral-first {
    background-color: rgb(234.0115384615, 245.9461538462, 254.5884615385) !important;
}

a.bg-neutral-first:hover, a.bg-neutral-first:focus,
button.bg-neutral-first:hover,
button.bg-neutral-first:focus {
    background-color: rgb(183.9923076923, 224.3692307692, 253.6076923077) !important;
}

.bg-neutral-second {
    background-color: rgb(225.1214285714, 226.3928571429, 231.4785714286) !important;
}

a.bg-neutral-second:hover, a.bg-neutral-second:focus,
button.bg-neutral-second:hover,
button.bg-neutral-second:focus {
    background-color: rgb(196.5857142857, 199.0714285714, 209.0142857143) !important;
}

.bg-brand-facebook {
    background-color: #3b5999 !important;
}

a.bg-brand-facebook:hover, a.bg-brand-facebook:focus,
button.bg-brand-facebook:hover,
button.bg-brand-facebook:focus {
    background-color: rgb(44.8066037736, 67.5896226415, 116.1933962264) !important;
}

.bg-brand-twitter {
    background-color: #1da1f2 !important;
}

a.bg-brand-twitter:hover, a.bg-brand-twitter:focus,
button.bg-brand-twitter:hover,
button.bg-brand-twitter:focus {
    background-color: rgb(11.9665271967, 133.4728033473, 208.0334728033) !important;
}

.bg-brand-google {
    background-color: #dd4b39 !important;
}

a.bg-brand-google:hover, a.bg-brand-google:focus,
button.bg-brand-google:hover,
button.bg-brand-google:focus {
    background-color: rgb(193.7327586207, 50.8793103448, 33.2672413793) !important;
}

.bg-brand-instagram {
    background-color: #e4405f !important;
}

a.bg-brand-instagram:hover, a.bg-brand-instagram:focus,
button.bg-brand-instagram:hover,
button.bg-brand-instagram:focus {
    background-color: rgb(211.1513761468, 29.8486238532, 64.119266055) !important;
}

.bg-brand-pinterest {
    background-color: #bd081c !important;
}

a.bg-brand-pinterest:hover, a.bg-brand-pinterest:focus,
button.bg-brand-pinterest:hover,
button.bg-brand-pinterest:focus {
    background-color: rgb(140.0710659898, 5.9289340102, 20.7512690355) !important;
}

.bg-brand-youtube {
    background-color: #cd201f !important;
}

a.bg-brand-youtube:hover, a.bg-brand-youtube:focus,
button.bg-brand-youtube:hover,
button.bg-brand-youtube:focus {
    background-color: rgb(160.6991525424, 25.0847457627, 24.3008474576) !important;
}

.bg-brand-slack {
    background-color: #3aaf85 !important;
}

a.bg-brand-slack:hover, a.bg-brand-slack:focus,
button.bg-brand-slack:hover,
button.bg-brand-slack:focus {
    background-color: rgb(45.30472103, 136.69527897, 103.8884120172) !important;
}

.bg-brand-dribbble {
    background-color: #ea4c89 !important;
}

a.bg-brand-dribbble:hover, a.bg-brand-dribbble:focus,
button.bg-brand-dribbble:hover,
button.bg-brand-dribbble:focus {
    background-color: rgb(228.645, 30.355, 106.91) !important;
}

.bg-brand-github {
    background-color: #222222 !important;
}

a.bg-brand-github:hover, a.bg-brand-github:focus,
button.bg-brand-github:hover,
button.bg-brand-github:focus {
    background-color: rgb(8.5, 8.5, 8.5) !important;
}

.text-facebook {
    color: #3b5999 !important;
}

a.text-facebook:hover, a.text-facebook:focus {
    color: rgb(37.7099056604, 56.8844339623, 97.7900943396) !important;
}

.text-twitter {
    color: #1da1f2 !important;
}

a.text-twitter:hover, a.text-twitter:focus {
    color: rgb(10.5794979079, 118.0020920502, 183.9205020921) !important;
}

.text-google {
    color: #dd4b39 !important;
}

a.text-google:hover, a.text-google:focus {
    color: rgb(171.9698275862, 45.1637931034, 29.5301724138) !important;
}

.text-instagram {
    color: #e4405f !important;
}

a.text-instagram:hover, a.text-instagram:focus {
    color: rgb(188.8096330275, 26.6903669725, 57.3348623853) !important;
}

.text-pinterest {
    color: #bd081c !important;
}

a.text-pinterest:hover, a.text-pinterest:focus {
    color: rgb(115.6065989848, 4.8934010152, 17.1269035533) !important;
}

.text-youtube {
    color: #cd201f !important;
}

a.text-youtube:hover, a.text-youtube:focus {
    color: rgb(138.5487288136, 21.6271186441, 20.9512711864) !important;
}

.text-slack {
    color: #3aaf85 !important;
}

a.text-slack:hover, a.text-slack:focus {
    color: rgb(38.9570815451, 117.5429184549, 89.3326180258) !important;
}

.text-dribbble {
    color: #ea4c89 !important;
}

a.text-dribbble:hover, a.text-dribbble:focus {
    color: rgb(208.9825, 24.5175, 95.735) !important;
}

.text-github {
    color: #222222 !important;
}

a.text-github:hover, a.text-github:focus {
    color: black !important;
}

.bg-warm-flame {
    background-image: linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%) !important;
}

.bg-night-fade {
    background-image: linear-gradient(to top, #a18cd1 0%, #fbc2eb 100%) !important;
}

.bg-sunny-morning {
    background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%) !important;
}

.bg-tempting-azure {
    background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%) !important;
}

.bg-amy-crisp {
    background-image: linear-gradient(120deg, #a6c0fe 0%, #f68084 100%) !important;
}

.bg-heavy-rain {
    background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%) !important;
}

.bg-mean-fruit {
    background-image: linear-gradient(120deg, #fccb90 0%, #d57eeb 100%) !important;
}

.bg-malibu-beach {
    background-image: linear-gradient(to right, #4facfe 0%, #00f2fe 100%) !important;
}

.bg-deep-blue {
    background-image: linear-gradient(120deg, #e0c3fc 0%, #8ec5fc 100%) !important;
}

.bg-ripe-malin {
    background-image: linear-gradient(120deg, #f093fb 0%, #f5576c 100%) !important;
}

.bg-arielle-smile {
    background-image: radial-gradient(circle 248px at center, #16d9e3 0%, #30c7ec 47%, #46aef7 100%) !important;
}

.bg-tim-valores {
    background-image: linear-gradient(120deg, rgba(28, 40, 90, 0.75) 0%, rgba(46, 57, 103, 0.75) 70%, rgba(35, 69, 132, 0.75) 100%) !important;
}

.bg-plum-plate {
    background-image: linear-gradient(135deg, #667eea 0%, #764ba2 100%) !important;
}

.bg-happy-fisher {
    background-image: linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%) !important;
}

.bg-happy-itmeo {
    background-image: linear-gradient(180deg, #2af598 0%, #009efd 100%) !important;
}

.bg-mixed-hopes {
    background-image: linear-gradient(to top, #c471f5 0%, #fa71cd 100%) !important;
}

.bg-strong-bliss {
    background-image: linear-gradient(to right, #f78ca0 0%, #f9748f 19%, #fd868c 60%, #fe9a8b 100%) !important;
}

.bg-grow-early {
    background-image: linear-gradient(to top, #0ba360 0%, #3cba92 100%) !important;
}

.bg-love-kiss {
    background-image: linear-gradient(to top, #ff0844 0%, #ffb199 100%) !important;
}

.bg-premium-dark {
    background-image: linear-gradient(to right, #434343 0%, black 100%) !important;
}

.bg-happy-green {
    background-image: linear-gradient(to bottom, #00b09b, #96c93d) !important;
}

.bg-vicious-stance {
    background-image: linear-gradient(60deg, #29323c 0%, #485563 100%) !important;
}

.bg-midnight-bloom {
    background-image: linear-gradient(-20deg, #2b5876 0%, #4e4376 100%) !important;
}

.bg-night-sky {
    background-image: linear-gradient(to top, #1e3c72 0%, #1e3c72 1%, #2a5298 100%) !important;
}

.bg-slick-carbon {
    background-image: linear-gradient(to bottom, #323232 0%, #3F3F3F 40%, #1C1C1C 150%), linear-gradient(to top, rgba(255, 255, 255, 0.4) 0%, rgba(0, 0, 0, 0.25) 200%) !important;
    background-blend-mode: multiply;
}

.bg-royal {
    background-image: linear-gradient(to right, #141e30, #243b55) !important;
}

.bg-asteroid {
    background-image: linear-gradient(to right, #0f2027, #203a43, #2c5364) !important;
}

.bg-skim-blue {
    background-image: linear-gradient(135deg, #ABDCFF 10%, #0396FF 100%) !important;
}

.bg-light-pure {
    background-image: linear-gradient(135deg, #CE9FFC 10%, #7367F0 100%) !important;
}

.bg-nice-redora {
    background-image: linear-gradient(135deg, #F761A1 10%, #8C1BAB 100%) !important;
}

.bg-red-lights {
    background-image: linear-gradient(135deg, #F05F57 10%, #360940 100%) !important;
}

.bg-serious-blue {
    background-image: linear-gradient(135deg, #97ABFF 10%, #123597 100%) !important;
}

.bg-deep-sky {
    background-image: linear-gradient(135deg, #6B73FF 10%, #000DFF 100%) !important;
}

.bg-sunrise-purple {
    background-image: linear-gradient(135deg, #3B2667 10%, #BC78EC 100%) !important;
}

.bg-composed-img-1 {
    background-image: url("images/composed-bg/bg1.jpg");
}

.bg-composed-img-2 {
    background-image: url("images/composed-bg/bg2.jpg");
}

.bg-composed-img-3 {
    background-image: url("images/composed-bg/bg3.jpg");
}

.bg-composed-img-4 {
    background-image: url("images/composed-bg/bg4.jpg");
}

.bg-composed-img-5 {
    background-image: url("images/composed-bg/bg5.jpg");
}

.bg-gray-100 {
    background: #f8f9ff !important;
}

.bg-gray-200 {
    background: #f4f5fd !important;
}

.bg-gray-300 {
    background: #eeeff8 !important;
}

.bg-gray-400 {
    background: #e6e7f1 !important;
}

.bg-gray-500 {
    background: #dfe0ea !important;
}

.color-swatch {
    position: relative;
    margin: 0 0 2rem;
}

.color-swatch--bg {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
    height: 84px;
    width: 100%;
    margin: 0 auto;
}

.color-swatch--title {
    background: #fff;
    border: #e6e7f1 solid 1px;
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 0.75rem;
    padding: 0.6666666667rem 1rem;
    text-align: center;
    color: #a6a6b9;
}

.heading-3 {
    text-transform: uppercase;
    font-size: 0.8636363636rem;
    font-weight: bold;
    margin: 0 0 1rem;
    padding: 0;
    color: #7a7b97;
}

.demo-box-positions {
    height: 100px;
    border-radius: 0;
}

.icon-demo-box {
    padding: 2rem 0 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.icon-demo-box .MuiCard-root {
    margin: 0 1rem 2rem;
    padding: 0 !important;
    width: 100px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: all 0.2s ease-in-out;
}

.icon-demo-box .MuiCard-root i,
.icon-demo-box .MuiCard-root span {
    font-size: 2.5rem;
}

.icon-demo-box .MuiCard-root:hover {
    transform: scale(1.8);
    z-index: 9;
    border-radius: 30px;
    box-shadow: 0rem 5rem 14rem 0 rgba(255, 255, 255, 0.3), 0 0.8rem 2.3rem rgba(0, 0, 0, 0.6), 0 0.2rem 0.3rem rgba(0, 0, 0, 0.45);
}

.heading-2-demo {
    padding-left: 0;
    font-size: 2.3rem;
    text-align: center;
    font-weight: bold;
}

.heading-2-demo::before {
    display: none;
}

.heading-2-demo + p {
    margin: 0;
    color: #fff;
    opacity: 0.7;
    font-size: 1.3rem;
    text-align: center;
}

.slider-item {
    background: #eeeff8;
    border-radius: 0.65rem;
    color: #d1d2db;
    font-size: 36px;
    padding: 0;
    position: relative;
    height: 150px;
    line-height: 150px;
    text-align: center;
    transition: all 0.2s ease-in-out;
}

.btn-go-back {
    position: fixed !important;
    left: 2rem;
    top: 2rem;
    z-index: 55;
}

.demo-img-wrapper {
    background: #f8f9ff;
    border-radius: 0.29rem;
    color: #d1d2db;
    font-size: 4rem;
    padding: 3rem;
    text-align: center;
    height: 100%;
    position: relative;
    transition: all 0.2s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
}

.demo-img-wrapper:after {
    content: "This demo placeholder should be replaced with either images or components.";
    position: absolute;
    left: 50%;
    width: 300px;
    margin-left: -150px;
    font-size: 0.95rem;
    background: #070919;
    border-radius: 0.29rem;
    visibility: hidden;
    opacity: 0;
    padding: 1rem;
    transition: all 0.2s ease-in-out;
    color: #fff;
    line-height: 1.6rem;
}

.demo-img-wrapper:hover {
    border-color: #2F80ED;
}

.demo-img-wrapper:hover:after {
    visibility: visible;
    opacity: 0.9;
}

.demo-img-wrapper i {
    margin: auto;
}

.card.demo-img-wrapper-discrete {
    background-color: rgba(255, 255, 255, 0.1);
    border: 0 !important;
    font-size: 3rem;
    color: rgba(255, 255, 255, 0.6);
}

.card.demo-img-wrapper-discrete:after {
    font-size: 0.86rem !important;
}

.card.demo-img-wrapper-discrete:hover {
    background-color: rgba(255, 255, 255, 0.15);
}

.card.demo-img-wrapper-dark {
    background-color: #a6a6b9;
    border-color: #070919;
}

.card.demo-img-wrapper-dark:after {
    background-color: #3b3e66;
    color: #e6e7f1;
}

.card.demo-img-wrapper-dark:hover {
    border-color: #eeeff8;
}

.header-drawer-open .theme-configurator {
    opacity: 0 !important;
    visibility: hidden !important;
}

.details-helper {
    position: absolute;
    width: 100%;
    bottom: 13px;
    left: 0;
}

:root {
    --blue: #2F80ED;
    --indigo: #7420ff;
    --purple: #793de6;
    --pink: #fc26a4;
    --red: #f83245;
    --orange: #f4772e;
    --yellow: #ffc926;
    --green: #1bc943;
    --teal: #18e1a5;
    --cyan: #11c5db;
    --white: #fff;
    --gray: #d1d2db;
    --gray-dark: #7a7b97;
    --primary: #2F80ED;
    --secondary: #f8f9ff;
    --success: #1bc943;
    --info: #11c5db;
    --warning: #f4772e;
    --danger: #f83245;
    --light: #f4f5fd;
    --gray: #f8f9ff;
    --dark: #7a7b97;
    --first: #2F80ED;
    --second: #070919;
    --breakpoint-xs: 0;
    --breakpoint-sm: 600px;
    --breakpoint-md: 960px;
    --breakpoint-lg: 1280px;
    --breakpoint-xl: 1920px;
    --font-family-sans-serif: "DM Sans";
    --font-family-monospace: "Times New Roman", Times, serif;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(7, 9, 25, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
    display: block;
}

body {
    margin: 0;
    font-family: "DM Sans";
    font-size: 0.95rem;
    font-weight: 400;
    line-height: 1.5;
    color: #3b3e66;
    text-align: left;
    background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
    text-decoration: underline;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    text-decoration-skip-ink: none;
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
}

ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
    margin-bottom: 0;
}

dt {
    font-weight: 700;
}

dd {
    margin-bottom: 0.5rem;
    margin-left: 0;
}

blockquote {
    margin: 0 0 1rem;
}

b,
strong {
    font-weight: bolder;
}

small {
    font-size: 80%;
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

a:not(.MuiButtonBase-root) {
    color: #3b3e66;
    text-decoration: none;
    background-color: transparent;
}

a:not(.MuiButtonBase-root):hover {
    color: #2F80ED;
    text-decoration: none;
}

a.card.card-box {
    background-color: #fff;
}

a:not([href]) {
    color: inherit;
    text-decoration: none;
}

a:not([href]):hover {
    color: inherit;
    text-decoration: none;
}

pre,
code,
kbd,
samp {
    font-family: "Times New Roman", Times, serif;
    font-size: 1em;
}

pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
}

figure {
    margin: 0 0 1rem;
}

img {
    vertical-align: middle;
    border-style: none;
}

svg {
    overflow: hidden;
    vertical-align: middle;
}

table {
    border-collapse: collapse;
}

caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #d1d2db;
    text-align: left;
    caption-side: bottom;
}

th {
    text-align: inherit;
}

label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

button {
    border-radius: 0;
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

select {
    word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
    -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
    cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

input[type=radio],
input[type=checkbox] {
    box-sizing: border-box;
    padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
    -webkit-appearance: listbox;
}

textarea {
    overflow: auto;
    resize: vertical;
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
}

progress {
    vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
    height: auto;
}

[type=search] {
    outline-offset: -2px;
    -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}

output {
    display: inline-block;
}

summary {
    display: list-item;
    cursor: pointer;
}

template {
    display: none;
}

[hidden] {
    display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}

h1, .h1 {
    font-size: 2.375rem;
}

h2, .h2 {
    font-size: 1.9rem;
}

h3, .h3 {
    font-size: 1.6625rem;
}

h4, .h4 {
    font-size: 1.425rem;
}

h5, .h5 {
    font-size: 1.1875rem;
}

h6, .h6 {
    font-size: 0.95rem;
}

.lead {
    font-size: 1.1875rem;
    font-weight: 300;
}

.display-1 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2;
}

.display-2 {
    font-size: 3rem;
    font-weight: 300;
    line-height: 1.2;
}

.display-3 {
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1.2;
}

.display-4 {
    font-size: 1.7rem;
    font-weight: 300;
    line-height: 1.2;
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(7, 9, 25, 0.1);
}

small,
.small {
    font-size: 80%;
    font-weight: 400;
}

mark,
.mark {
    padding: 0.2em;
    background-color: #fcf8e3;
}

.list-unstyled {
    padding-left: 0;
    list-style: none;
}

.list-inline {
    padding-left: 0;
    list-style: none;
}

.list-inline-item {
    display: inline-block;
}

.list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
}

.initialism {
    font-size: 90%;
    text-transform: uppercase;
}

.blockquote {
    margin-bottom: 1rem;
    font-size: 1.1875rem;
}

.blockquote-footer {
    display: block;
    font-size: 80%;
    color: #d1d2db;
}

.blockquote-footer::before {
    content: "— ";
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.img-thumbnail {
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid #eeeff8;
    border-radius: 0.65rem;
    max-width: 100%;
    height: auto;
}

.figure {
    display: inline-block;
}

.figure-img {
    margin-bottom: 0.5rem;
    line-height: 1;
}

.figure-caption {
    font-size: 90%;
    color: #d1d2db;
}

.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #3b3e66;
}

.table th,
.table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid rgb(219.9375, 222, 240.5625);
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid rgb(219.9375, 222, 240.5625);
}

.table tbody + tbody {
    border-top: 2px solid rgb(219.9375, 222, 240.5625);
}

.table-sm th,
.table-sm td {
    padding: 0.3rem;
}

.table-bordered {
    border: 1px solid rgb(219.9375, 222, 240.5625);
}

.table-bordered th,
.table-bordered td {
    border: 1px solid rgb(219.9375, 222, 240.5625);
}

.table-bordered thead th,
.table-bordered thead td {
    border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
    border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgb(249.05, 249.75, 253.95);
}

.table-hover tbody tr:hover {
    color: #3b3e66;
    background-color: rgb(255, 251.1923076923, 241.5);
}

.table-primary,
.table-primary > th,
.table-primary > td {
    background-color: rgb(196.76, 219.44, 249.96);
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
    border-color: rgb(146.84, 188.96, 245.64);
}

.table-hover .table-primary:hover {
    background-color: rgb(173.2909734513, 205.1103539823, 247.9290265487);
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
    background-color: rgb(173.2909734513, 205.1103539823, 247.9290265487);
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
    background-color: rgb(253.04, 253.32, 255);
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
    border-color: rgb(251.36, 251.88, 255);
}

.table-hover .table-secondary:hover {
    background-color: rgb(227.54, 231.4628571429, 255);
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
    background-color: rgb(227.54, 231.4628571429, 255);
}

.table-success,
.table-success > th,
.table-success > td {
    background-color: rgb(191.16, 239.88, 202.36);
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
    border-color: rgb(136.44, 226.92, 157.24);
}

.table-hover .table-success:hover {
    background-color: rgb(170.5429787234, 234.9970212766, 185.36);
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
    background-color: rgb(170.5429787234, 234.9970212766, 185.36);
}

.table-info,
.table-info > th,
.table-info > td {
    background-color: rgb(188.36, 238.76, 244.92);
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
    border-color: rgb(131.24, 224.84, 236.28);
}

.table-hover .table-info:hover {
    background-color: rgb(166.2103649635, 233.362189781, 241.5696350365);
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
    background-color: rgb(166.2103649635, 233.362189781, 241.5696350365);
}

.table-warning,
.table-warning > th,
.table-warning > td {
    background-color: rgb(251.92, 216.92, 196.48);
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
    border-color: rgb(249.28, 184.28, 146.32);
}

.table-hover .table-warning:hover {
    background-color: rgb(250.645, 201.1563636364, 172.255);
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
    background-color: rgb(250.645, 201.1563636364, 172.255);
}

.table-danger,
.table-danger > th,
.table-danger > td {
    background-color: rgb(253.04, 197.6, 202.92);
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
    border-color: rgb(251.36, 148.4, 158.28);
}

.table-hover .table-danger:hover {
    background-color: rgb(252.1980188679, 172.9419811321, 180.5473584906);
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
    background-color: rgb(252.1980188679, 172.9419811321, 180.5473584906);
}

.table-light,
.table-light > th,
.table-light > td {
    background-color: rgb(251.92, 252.2, 254.44);
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
    border-color: rgb(249.28, 249.8, 253.96);
}

.table-hover .table-light:hover {
    background-color: rgb(230.3430769231, 232.5846153846, 250.5169230769);
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
    background-color: rgb(230.3430769231, 232.5846153846, 250.5169230769);
}

.table-gray,
.table-gray > th,
.table-gray > td {
    background-color: rgb(253.04, 253.32, 255);
}

.table-gray th,
.table-gray td,
.table-gray thead th,
.table-gray tbody + tbody {
    border-color: rgb(251.36, 251.88, 255);
}

.table-hover .table-gray:hover {
    background-color: rgb(227.54, 231.4628571429, 255);
}

.table-hover .table-gray:hover > td,
.table-hover .table-gray:hover > th {
    background-color: rgb(227.54, 231.4628571429, 255);
}

.table-dark,
.table-dark > th,
.table-dark > td {
    background-color: rgb(217.76, 218.04, 225.88);
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: rgb(185.84, 186.36, 200.92);
}

.table-hover .table-dark:hover {
    background-color: rgb(203.4498734177, 203.8374683544, 214.6901265823);
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
    background-color: rgb(203.4498734177, 203.8374683544, 214.6901265823);
}

.table-first,
.table-first > th,
.table-first > td {
    background-color: rgb(196.76, 219.44, 249.96);
}

.table-first th,
.table-first td,
.table-first thead th,
.table-first tbody + tbody {
    border-color: rgb(146.84, 188.96, 245.64);
}

.table-hover .table-first:hover {
    background-color: rgb(173.2909734513, 205.1103539823, 247.9290265487);
}

.table-hover .table-first:hover > td,
.table-hover .table-first:hover > th {
    background-color: rgb(173.2909734513, 205.1103539823, 247.9290265487);
}

.table-second,
.table-second > th,
.table-second > td {
    background-color: rgb(185.56, 186.12, 190.6);
}

.table-second th,
.table-second td,
.table-second thead th,
.table-second tbody + tbody {
    border-color: rgb(126.04, 127.08, 135.4);
}

.table-hover .table-second:hover {
    background-color: rgb(172.329874477, 172.9965690377, 178.330125523);
}

.table-hover .table-second:hover > td,
.table-hover .table-second:hover > th {
    background-color: rgb(172.329874477, 172.9965690377, 178.330125523);
}

.table-active,
.table-active > th,
.table-active > td {
    background-color: rgb(255, 251.1923076923, 241.5);
}

.table-hover .table-active:hover {
    background-color: #fff4d8;
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
    background-color: #fff4d8;
}

.table .thead-dark th {
    color: #fff;
    background-color: #3b3e66;
    border-color: rgb(40.3105590062, 42.3602484472, 69.6894409938);
}

.table .thead-light th {
    color: #3b3e66;
    background-color: rgba(238.6, 239.48, 247.4, 0.7);
    border-color: rgb(219.9375, 222, 240.5625);
}

.table-dark {
    color: #fff;
    background-color: #3b3e66;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
    border-color: rgb(40.3105590062, 42.3602484472, 69.6894409938);
}

.table-dark.table-bordered {
    border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075);
}

@media (min-width: 800px) and (max-width: 1490px) {
    .table-responsive td, .table-responsive th {
        padding: 10px;
    }
}

@media (min-width: 800px) and (max-width: 1360px) {
    .table-responsive td, .table-responsive th {
        padding: 6px;
    }
}

@media (min-width: 800px) and (max-width: 990px) {
    .table-responsive td, .table-responsive th {
        padding: 8px 4px;
    }
}

@media (min-width: 800px) and (max-width: 960px) {
    .table-responsive td, .table-responsive th {
        padding: 6px 2px;
    }
}

@media (min-width: 800px) and (max-width: 900px) {
    .table-responsive td, .table-responsive th {
        padding: 6px 1px;
    }
}

@media (max-width: 599.98px) {
    .table-responsive-sm {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-sm > .table-bordered {
        border: 0;
    }
}

@media (max-width: 959.98px) {
    .table-responsive-md {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-md > .table-bordered {
        border: 0;
    }
}

@media (max-width: 1279.98px) {
    .table-responsive-lg {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-lg > .table-bordered {
        border: 0;
    }
}

@media (max-width: 1919.98px) {
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-xl > .table-bordered {
        border: 0;
    }
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
    border: 0;
}

.fade {
    transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
    .fade {
        transition: none;
    }
}

.fade:not(.show) {
    opacity: 0;
}

.collapse:not(.show) {
    display: none;
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
    .collapsing {
        transition: none;
    }
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(122, 123, 151, 0.3);
    border-radius: 0.65rem;
}

.card > hr {
    margin-right: 0;
    margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.65rem;
    border-top-right-radius: 0.65rem;
}

.card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.65rem;
    border-bottom-left-radius: 0.65rem;
}

.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
}

.card-title {
    margin-bottom: 0.75rem;
}

.card-subtitle {
    margin-top: -0.375rem;
    margin-bottom: 0;
}

.card-text:last-child {
    margin-bottom: 0;
}

.card-link:hover {
    text-decoration: none;
}

.card-link + .card-link {
    margin-left: 1.25rem;
}

.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: #fff;
    border-bottom: 0 solid rgba(122, 123, 151, 0.3);
}

.card-header:first-child {
    border-radius: 0.65rem 0.65rem 0 0;
}

.card-header + .list-group .list-group-item:first-child {
    border-top: 0;
}

.card-footer {
    padding: 0.75rem 1.25rem;
    background-color: #fff;
    border-top: 0 solid rgba(122, 123, 151, 0.3);
}

.card-footer:last-child {
    border-radius: 0 0 0.65rem 0.65rem;
}

.card-header-tabs {
    margin-right: -0.625rem;
    margin-bottom: -0.75rem;
    margin-left: -0.625rem;
    border-bottom: 0;
}

.card-header-pills {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
    flex-shrink: 0;
    width: 100%;
}

.card-img,
.card-img-top {
    border-top-left-radius: 0.65rem;
    border-top-right-radius: 0.65rem;
}

.card-img,
.card-img-bottom {
    border-bottom-right-radius: 0.65rem;
    border-bottom-left-radius: 0.65rem;
}

.card-deck .card {
    margin-bottom: 20px;
}

@media (min-width: 600px) {
    .card-deck {
        display: flex;
        flex-flow: row wrap;
        margin-right: -20px;
        margin-left: -20px;
    }

    .card-deck .card {
        flex: 1 0 0%;
        margin-right: 20px;
        margin-bottom: 0;
        margin-left: 20px;
    }
}

.card-group > .card {
    margin-bottom: 20px;
}

@media (min-width: 600px) {
    .card-group {
        display: flex;
        flex-flow: row wrap;
    }

    .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0;
    }

    .card-group > .card + .card {
        margin-left: 0;
        border-left: 0;
    }

    .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .card-group > .card:not(:last-child) .card-img-top,
    .card-group > .card:not(:last-child) .card-header {
        border-top-right-radius: 0;
    }

    .card-group > .card:not(:last-child) .card-img-bottom,
    .card-group > .card:not(:last-child) .card-footer {
        border-bottom-right-radius: 0;
    }

    .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .card-group > .card:not(:first-child) .card-img-top,
    .card-group > .card:not(:first-child) .card-header {
        border-top-left-radius: 0;
    }

    .card-group > .card:not(:first-child) .card-img-bottom,
    .card-group > .card:not(:first-child) .card-footer {
        border-bottom-left-radius: 0;
    }
}

.card-columns .card {
    margin-bottom: 0.75rem;
}

@media (min-width: 600px) {
    .card-columns {
        column-count: 3;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1;
    }

    .card-columns .card {
        display: inline-block;
        width: 100%;
    }
}

.accordion > .card {
    overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: 0;
}

.badge {
    display: inline-block;
    padding: 0 0.7em;
    font-size: 70%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.2rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .badge {
        transition: none;
    }
}

a.badge:hover, a.badge:focus {
    text-decoration: none;
}

.badge:empty {
    display: none;
}

.btn .badge {
    position: relative;
    top: -1px;
}

.badge-pill {
    padding-right: 0.85em;
    padding-left: 0.85em;
    border-radius: 10rem;
}

.badge-primary {
    color: #fff;
    background-color: #2F80ED;
}

a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: rgb(18.5575221239, 102.0663716814, 214.4424778761);
}

a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(47, 128, 237, 0.5);
}

.badge-secondary {
    color: #3b3e66;
    background-color: #f8f9ff;
}

a.badge-secondary:hover, a.badge-secondary:focus {
    color: #3b3e66;
    background-color: rgb(197, 205.2857142857, 255);
}

a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 255, 0.5);
}

.badge-success {
    color: #fff;
    background-color: #1bc943;
}

a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: rgb(20.9605263158, 156.0394736842, 52.0131578947);
}

a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(27, 201, 67, 0.5);
}

.badge-info {
    color: #fff;
    background-color: #11c5db;
}

a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: rgb(13.3262711864, 154.4279661017, 171.6737288136);
}

a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(17, 197, 219, 0.5);
}

.badge-warning {
    color: #fff;
    background-color: #f4772e;
}

a.badge-warning:hover, a.badge-warning:focus {
    color: #fff;
    background-color: rgb(227.05, 91.2545454545, 11.95);
}

a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(244, 119, 46, 0.5);
}

.badge-danger {
    color: #fff;
    background-color: #f83245;
}

a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: rgb(238.8443396226, 8.1556603774, 30.2924528302);
}

a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 50, 69, 0.5);
}

.badge-light {
    color: #3b3e66;
    background-color: #f4f5fd;
}

a.badge-light:hover, a.badge-light:focus {
    color: #3b3e66;
    background-color: rgb(200.8461538462, 205.7692307692, 245.1538461538);
}

a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(244, 245, 253, 0.5);
}

.badge-gray {
    color: #3b3e66;
    background-color: #f8f9ff;
}

a.badge-gray:hover, a.badge-gray:focus {
    color: #3b3e66;
    background-color: rgb(197, 205.2857142857, 255);
}

a.badge-gray:focus, a.badge-gray.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 255, 0.5);
}

.badge-dark {
    color: #fff;
    background-color: #7a7b97;
}

a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: rgb(97.417721519, 98.3544303797, 124.582278481);
}

a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(122, 123, 151, 0.5);
}

.badge-first {
    color: #fff;
    background-color: #2F80ED;
}

a.badge-first:hover, a.badge-first:focus {
    color: #fff;
    background-color: rgb(18.5575221239, 102.0663716814, 214.4424778761);
}

a.badge-first:focus, a.badge-first.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(47, 128, 237, 0.5);
}

.badge-second {
    color: #fff;
    background-color: #070919;
}

a.badge-second:hover, a.badge-second:focus {
    color: #fff;
    background-color: black;
}

a.badge-second:focus, a.badge-second.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(7, 9, 25, 0.5);
}

@keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0;
    }
    to {
        background-position: 0 0;
    }
}

.progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: 0.7125rem;
    background-color: #f4f5fd;
    border-radius: 0.2rem;
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #2F80ED;
    transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
    .progress-bar {
        transition: none;
    }
}

.progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem;
}

.progress-bar-animated {
    animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
        animation: none;
    }
}

.list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
}

.list-group-item-action {
    width: 100%;
    color: #7a7b97;
    text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #070919;
    text-decoration: none;
    background-color: #f4f5fd;
}

.list-group-item-action:active {
    color: black;
    background-color: #eeeff8;
}

.list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    background-color: #fff;
    border: 1px solid rgba(7, 9, 25, 0.125);
}

.list-group-item:first-child {
    border-top-left-radius: 0.65rem;
    border-top-right-radius: 0.65rem;
}

.list-group-item:last-child {
    border-bottom-right-radius: 0.65rem;
    border-bottom-left-radius: 0.65rem;
}

.list-group-item.disabled, .list-group-item:disabled {
    color: #d1d2db;
    pointer-events: none;
    background-color: #fff;
}

.list-group-item.active {
    z-index: 2;
    color: black;
    background-color: #eeeff8;
    border-color: #eeeff8;
}

.list-group-item + .list-group-item {
    border-top-width: 0;
}

.list-group-item + .list-group-item.active {
    margin-top: -1px;
    border-top-width: 1px;
}

.list-group-horizontal {
    flex-direction: row;
}

.list-group-horizontal .list-group-item:first-child {
    border-bottom-left-radius: 0.65rem;
    border-top-right-radius: 0;
}

.list-group-horizontal .list-group-item:last-child {
    border-top-right-radius: 0.65rem;
    border-bottom-left-radius: 0;
}

.list-group-horizontal .list-group-item.active {
    margin-top: 0;
}

.list-group-horizontal .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
}

.list-group-horizontal .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
}

@media (min-width: 600px) {
    .list-group-horizontal-sm {
        flex-direction: row;
    }

    .list-group-horizontal-sm .list-group-item:first-child {
        border-bottom-left-radius: 0.65rem;
        border-top-right-radius: 0;
    }

    .list-group-horizontal-sm .list-group-item:last-child {
        border-top-right-radius: 0.65rem;
        border-bottom-left-radius: 0;
    }

    .list-group-horizontal-sm .list-group-item.active {
        margin-top: 0;
    }

    .list-group-horizontal-sm .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }

    .list-group-horizontal-sm .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

@media (min-width: 960px) {
    .list-group-horizontal-md {
        flex-direction: row;
    }

    .list-group-horizontal-md .list-group-item:first-child {
        border-bottom-left-radius: 0.65rem;
        border-top-right-radius: 0;
    }

    .list-group-horizontal-md .list-group-item:last-child {
        border-top-right-radius: 0.65rem;
        border-bottom-left-radius: 0;
    }

    .list-group-horizontal-md .list-group-item.active {
        margin-top: 0;
    }

    .list-group-horizontal-md .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }

    .list-group-horizontal-md .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

@media (min-width: 1280px) {
    .list-group-horizontal-lg {
        flex-direction: row;
    }

    .list-group-horizontal-lg .list-group-item:first-child {
        border-bottom-left-radius: 0.65rem;
        border-top-right-radius: 0;
    }

    .list-group-horizontal-lg .list-group-item:last-child {
        border-top-right-radius: 0.65rem;
        border-bottom-left-radius: 0;
    }

    .list-group-horizontal-lg .list-group-item.active {
        margin-top: 0;
    }

    .list-group-horizontal-lg .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }

    .list-group-horizontal-lg .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

@media (min-width: 1920px) {
    .list-group-horizontal-xl {
        flex-direction: row;
    }

    .list-group-horizontal-xl .list-group-item:first-child {
        border-bottom-left-radius: 0.65rem;
        border-top-right-radius: 0;
    }

    .list-group-horizontal-xl .list-group-item:last-child {
        border-top-right-radius: 0.65rem;
        border-bottom-left-radius: 0;
    }

    .list-group-horizontal-xl .list-group-item.active {
        margin-top: 0;
    }

    .list-group-horizontal-xl .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }

    .list-group-horizontal-xl .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

.list-group-flush .list-group-item {
    border-right-width: 0;
    border-left-width: 0;
    border-radius: 0;
}

.list-group-flush .list-group-item:first-child {
    border-top-width: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
    border-bottom-width: 0;
}

.list-group-item-primary {
    color: rgb(27.8, 70.88, 135.24);
    background-color: rgb(196.76, 219.44, 249.96);
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: rgb(27.8, 70.88, 135.24);
    background-color: rgb(173.2909734513, 205.1103539823, 247.9290265487);
}

.list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: rgb(27.8, 70.88, 135.24);
    border-color: rgb(27.8, 70.88, 135.24);
}

.list-group-item-secondary {
    color: rgb(132.32, 133.8, 144.6);
    background-color: rgb(253.04, 253.32, 255);
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: rgb(132.32, 133.8, 144.6);
    background-color: rgb(227.54, 231.4628571429, 255);
}

.list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: rgb(132.32, 133.8, 144.6);
    border-color: rgb(132.32, 133.8, 144.6);
}

.list-group-item-success {
    color: rgb(17.4, 108.84, 46.84);
    background-color: rgb(191.16, 239.88, 202.36);
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: rgb(17.4, 108.84, 46.84);
    background-color: rgb(170.5429787234, 234.9970212766, 185.36);
}

.list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: rgb(17.4, 108.84, 46.84);
    border-color: rgb(17.4, 108.84, 46.84);
}

.list-group-item-info {
    color: rgb(12.2, 106.76, 125.88);
    background-color: rgb(188.36, 238.76, 244.92);
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: rgb(12.2, 106.76, 125.88);
    background-color: rgb(166.2103649635, 233.362189781, 241.5696350365);
}

.list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: rgb(12.2, 106.76, 125.88);
    border-color: rgb(12.2, 106.76, 125.88);
}

.list-group-item-warning {
    color: rgb(130.24, 66.2, 35.92);
    background-color: rgb(251.92, 216.92, 196.48);
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: rgb(130.24, 66.2, 35.92);
    background-color: rgb(250.645, 201.1563636364, 172.255);
}

.list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: rgb(130.24, 66.2, 35.92);
    border-color: rgb(130.24, 66.2, 35.92);
}

.list-group-item-danger {
    color: rgb(132.32, 30.32, 47.88);
    background-color: rgb(253.04, 197.6, 202.92);
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: rgb(132.32, 30.32, 47.88);
    background-color: rgb(252.1980188679, 172.9419811321, 180.5473584906);
}

.list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: rgb(132.32, 30.32, 47.88);
    border-color: rgb(132.32, 30.32, 47.88);
}

.list-group-item-light {
    color: rgb(130.24, 131.72, 143.56);
    background-color: rgb(251.92, 252.2, 254.44);
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: rgb(130.24, 131.72, 143.56);
    background-color: rgb(230.3430769231, 232.5846153846, 250.5169230769);
}

.list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: rgb(130.24, 131.72, 143.56);
    border-color: rgb(130.24, 131.72, 143.56);
}

.list-group-item-gray {
    color: rgb(132.32, 133.8, 144.6);
    background-color: rgb(253.04, 253.32, 255);
}

.list-group-item-gray.list-group-item-action:hover, .list-group-item-gray.list-group-item-action:focus {
    color: rgb(132.32, 133.8, 144.6);
    background-color: rgb(227.54, 231.4628571429, 255);
}

.list-group-item-gray.list-group-item-action.active {
    color: #fff;
    background-color: rgb(132.32, 133.8, 144.6);
    border-color: rgb(132.32, 133.8, 144.6);
}

.list-group-item-dark {
    color: rgb(66.8, 68.28, 90.52);
    background-color: rgb(217.76, 218.04, 225.88);
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: rgb(66.8, 68.28, 90.52);
    background-color: rgb(203.4498734177, 203.8374683544, 214.6901265823);
}

.list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: rgb(66.8, 68.28, 90.52);
    border-color: rgb(66.8, 68.28, 90.52);
}

.list-group-item-first {
    color: rgb(27.8, 70.88, 135.24);
    background-color: rgb(196.76, 219.44, 249.96);
}

.list-group-item-first.list-group-item-action:hover, .list-group-item-first.list-group-item-action:focus {
    color: rgb(27.8, 70.88, 135.24);
    background-color: rgb(173.2909734513, 205.1103539823, 247.9290265487);
}

.list-group-item-first.list-group-item-action.active {
    color: #fff;
    background-color: rgb(27.8, 70.88, 135.24);
    border-color: rgb(27.8, 70.88, 135.24);
}

.list-group-item-second {
    color: #070919;
    background-color: rgb(185.56, 186.12, 190.6);
}

.list-group-item-second.list-group-item-action:hover, .list-group-item-second.list-group-item-action:focus {
    color: #070919;
    background-color: rgb(172.329874477, 172.9965690377, 178.330125523);
}

.list-group-item-second.list-group-item-action.active {
    color: #fff;
    background-color: #070919;
    border-color: #070919;
}

.align-baseline {
    vertical-align: baseline !important;
}

.align-top {
    vertical-align: top !important;
}

.align-middle {
    vertical-align: middle !important;
}

.align-bottom {
    vertical-align: bottom !important;
}

.align-text-bottom {
    vertical-align: text-bottom !important;
}

.align-text-top {
    vertical-align: text-top !important;
}

.bg-primary {
    background-color: #2F80ED !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
    background-color: rgb(18.5575221239, 102.0663716814, 214.4424778761) !important;
}

.bg-secondary {
    background-color: #f8f9ff !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
    background-color: rgb(197, 205.2857142857, 255) !important;
}

.bg-success {
    background-color: #1bc943 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
    background-color: rgb(20.9605263158, 156.0394736842, 52.0131578947) !important;
}

.bg-info {
    background-color: #11c5db !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
    background-color: rgb(13.3262711864, 154.4279661017, 171.6737288136) !important;
}

.bg-warning {
    background-color: #f4772e !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
    background-color: rgb(227.05, 91.2545454545, 11.95) !important;
}

.bg-danger {
    background-color: #f83245 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
    background-color: rgb(238.8443396226, 8.1556603774, 30.2924528302) !important;
}

.bg-light {
    background-color: #f4f5fd !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
    background-color: rgb(200.8461538462, 205.7692307692, 245.1538461538) !important;
}

.bg-gray {
    background-color: #f8f9ff !important;
}

a.bg-gray:hover, a.bg-gray:focus,
button.bg-gray:hover,
button.bg-gray:focus {
    background-color: rgb(197, 205.2857142857, 255) !important;
}

.bg-dark {
    background-color: #7a7b97 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
    background-color: rgb(97.417721519, 98.3544303797, 124.582278481) !important;
}

.bg-first {
    background-color: #2F80ED !important;
}

a.bg-first:hover, a.bg-first:focus,
button.bg-first:hover,
button.bg-first:focus {
    background-color: rgb(18.5575221239, 102.0663716814, 214.4424778761) !important;
}

.bg-second {
    background-color: #070919 !important;
}

a.bg-second:hover, a.bg-second:focus,
button.bg-second:hover,
button.bg-second:focus {
    background-color: black !important;
}

.bg-white {
    background-color: #fff !important;
}

.bg-transparent {
    background-color: transparent !important;
}

.border {
    border: 1px solid #eeeff8 !important;
}

.border-top {
    border-top: 1px solid #eeeff8 !important;
}

.border-right {
    border-right: 1px solid #eeeff8 !important;
}

.border-bottom {
    border-bottom: 1px solid #eeeff8 !important;
}

.border-left {
    border-left: 1px solid #eeeff8 !important;
}

.border-0 {
    border: 0 !important;
}

.border-top-0 {
    border-top: 0 !important;
}

.border-right-0 {
    border-right: 0 !important;
}

.border-bottom-0 {
    border-bottom: 0 !important;
}

.border-left-0 {
    border-left: 0 !important;
}

.border-primary {
    border-color: #2F80ED !important;
}

.border-secondary {
    border-color: #f8f9ff !important;
}

.border-success {
    border-color: #1bc943 !important;
}

.border-info {
    border-color: #11c5db !important;
}

.border-warning {
    border-color: #f4772e !important;
}

.border-danger {
    border-color: #f83245 !important;
}

.border-light {
    border-color: #f4f5fd !important;
}

.border-gray {
    border-color: #f8f9ff !important;
}

.border-dark {
    border-color: #7a7b97 !important;
}

.border-first {
    border-color: #2F80ED !important;
}

.border-second {
    border-color: #070919 !important;
}

.border-white {
    border-color: #fff !important;
}

.rounded-sm {
    border-radius: 0.29rem !important;
}

.rounded {
    border-radius: 0.65rem !important;
}

.rounded-top {
    border-top-left-radius: 0.65rem !important;
    border-top-right-radius: 0.65rem !important;
}

.rounded-right {
    border-top-right-radius: 0.65rem !important;
    border-bottom-right-radius: 0.65rem !important;
}

.rounded-bottom {
    border-bottom-right-radius: 0.65rem !important;
    border-bottom-left-radius: 0.65rem !important;
}

.rounded-left {
    border-top-left-radius: 0.65rem !important;
    border-bottom-left-radius: 0.65rem !important;
}

.rounded-lg {
    border-radius: 0.75rem !important;
}

.rounded-circle {
    border-radius: 50% !important;
}

.rounded-pill {
    border-radius: 50rem !important;
}

.rounded-0 {
    border-radius: 0 !important;
}

.clearfix::after {
    display: block;
    clear: both;
    content: "";
}

.d-none {
    display: none !important;
}

.d-inline {
    display: inline !important;
}

.d-inline-block {
    display: inline-block !important;
}

.d-block {
    display: block !important;
}

.d-table {
    display: table !important;
}

.d-table-row {
    display: table-row !important;
}

.d-table-cell {
    display: table-cell !important;
}

.d-flex {
    display: flex !important;
}

.d-inline-flex {
    display: inline-flex !important;
}

@media (min-width: 600px) {
    .d-sm-none {
        display: none !important;
    }

    .d-sm-inline {
        display: inline !important;
    }

    .d-sm-inline-block {
        display: inline-block !important;
    }

    .d-sm-block {
        display: block !important;
    }

    .d-sm-table {
        display: table !important;
    }

    .d-sm-table-row {
        display: table-row !important;
    }

    .d-sm-table-cell {
        display: table-cell !important;
    }

    .d-sm-flex {
        display: flex !important;
    }

    .d-sm-inline-flex {
        display: inline-flex !important;
    }
}

@media (min-width: 960px) {
    .d-md-none {
        display: none !important;
    }

    .d-md-inline {
        display: inline !important;
    }

    .d-md-inline-block {
        display: inline-block !important;
    }

    .d-md-block {
        display: block !important;
    }

    .d-md-table {
        display: table !important;
    }

    .d-md-table-row {
        display: table-row !important;
    }

    .d-md-table-cell {
        display: table-cell !important;
    }

    .d-md-flex {
        display: flex !important;
    }

    .d-md-inline-flex {
        display: inline-flex !important;
    }
}

@media (min-width: 1280px) {
    .d-lg-none {
        display: none !important;
    }

    .d-lg-inline {
        display: inline !important;
    }

    .d-lg-inline-block {
        display: inline-block !important;
    }

    .d-lg-block {
        display: block !important;
    }

    .d-lg-table {
        display: table !important;
    }

    .d-lg-table-row {
        display: table-row !important;
    }

    .d-lg-table-cell {
        display: table-cell !important;
    }

    .d-lg-flex {
        display: flex !important;
    }

    .d-lg-inline-flex {
        display: inline-flex !important;
    }
}

@media (min-width: 1920px) {
    .d-xl-none {
        display: none !important;
    }

    .d-xl-inline {
        display: inline !important;
    }

    .d-xl-inline-block {
        display: inline-block !important;
    }

    .d-xl-block {
        display: block !important;
    }

    .d-xl-table {
        display: table !important;
    }

    .d-xl-table-row {
        display: table-row !important;
    }

    .d-xl-table-cell {
        display: table-cell !important;
    }

    .d-xl-flex {
        display: flex !important;
    }

    .d-xl-inline-flex {
        display: inline-flex !important;
    }
}

@media print {
    .d-print-none {
        display: none !important;
    }

    .d-print-inline {
        display: inline !important;
    }

    .d-print-inline-block {
        display: inline-block !important;
    }

    .d-print-block {
        display: block !important;
    }

    .d-print-table {
        display: table !important;
    }

    .d-print-table-row {
        display: table-row !important;
    }

    .d-print-table-cell {
        display: table-cell !important;
    }

    .d-print-flex {
        display: flex !important;
    }

    .d-print-inline-flex {
        display: inline-flex !important;
    }
}

.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
}

.embed-responsive::before {
    display: block;
    content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.embed-responsive-21by9::before {
    padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
    padding-top: 56.25%;
}

.embed-responsive-4by3::before {
    padding-top: 75%;
}

.embed-responsive-1by1::before {
    padding-top: 100%;
}

.flex-row {
    flex-direction: row !important;
}

.flex-column {
    flex-direction: column !important;
}

.flex-row-reverse {
    flex-direction: row-reverse !important;
}

.flex-column-reverse {
    flex-direction: column-reverse !important;
}

.flex-wrap {
    flex-wrap: wrap !important;
}

.flex-nowrap {
    flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
}

.flex-fill {
    flex: 1 1 auto !important;
}

.flex-grow-0 {
    flex-grow: 0 !important;
}

.flex-grow-1 {
    flex-grow: 1 !important;
}

.flex-shrink-0 {
    flex-shrink: 0 !important;
}

.flex-shrink-1 {
    flex-shrink: 1 !important;
}

.justify-content-start {
    justify-content: flex-start !important;
}

.justify-content-end {
    justify-content: flex-end !important;
}

.justify-content-center {
    justify-content: center !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.justify-content-around {
    justify-content: space-around !important;
}

.align-items-start {
    align-items: flex-start !important;
}

.align-items-end {
    align-items: flex-end !important;
}

.align-items-center {
    align-items: center !important;
}

.align-items-baseline {
    align-items: baseline !important;
}

.align-items-stretch {
    align-items: stretch !important;
}

.align-content-start {
    align-content: flex-start !important;
}

.align-content-end {
    align-content: flex-end !important;
}

.align-content-center {
    align-content: center !important;
}

.align-content-between {
    align-content: space-between !important;
}

.align-content-around {
    align-content: space-around !important;
}

.align-content-stretch {
    align-content: stretch !important;
}

.align-self-auto {
    align-self: auto !important;
}

.align-self-start {
    align-self: flex-start !important;
}

.align-self-end {
    align-self: flex-end !important;
}

.align-self-center {
    align-self: center !important;
}

.align-self-baseline {
    align-self: baseline !important;
}

.align-self-stretch {
    align-self: stretch !important;
}

@media (min-width: 600px) {
    .flex-sm-row {
        flex-direction: row !important;
    }

    .flex-sm-column {
        flex-direction: column !important;
    }

    .flex-sm-row-reverse {
        flex-direction: row-reverse !important;
    }

    .flex-sm-column-reverse {
        flex-direction: column-reverse !important;
    }

    .flex-sm-wrap {
        flex-wrap: wrap !important;
    }

    .flex-sm-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .flex-sm-fill {
        flex: 1 1 auto !important;
    }

    .flex-sm-grow-0 {
        flex-grow: 0 !important;
    }

    .flex-sm-grow-1 {
        flex-grow: 1 !important;
    }

    .flex-sm-shrink-0 {
        flex-shrink: 0 !important;
    }

    .flex-sm-shrink-1 {
        flex-shrink: 1 !important;
    }

    .justify-content-sm-start {
        justify-content: flex-start !important;
    }

    .justify-content-sm-end {
        justify-content: flex-end !important;
    }

    .justify-content-sm-center {
        justify-content: center !important;
    }

    .justify-content-sm-between {
        justify-content: space-between !important;
    }

    .justify-content-sm-around {
        justify-content: space-around !important;
    }

    .align-items-sm-start {
        align-items: flex-start !important;
    }

    .align-items-sm-end {
        align-items: flex-end !important;
    }

    .align-items-sm-center {
        align-items: center !important;
    }

    .align-items-sm-baseline {
        align-items: baseline !important;
    }

    .align-items-sm-stretch {
        align-items: stretch !important;
    }

    .align-content-sm-start {
        align-content: flex-start !important;
    }

    .align-content-sm-end {
        align-content: flex-end !important;
    }

    .align-content-sm-center {
        align-content: center !important;
    }

    .align-content-sm-between {
        align-content: space-between !important;
    }

    .align-content-sm-around {
        align-content: space-around !important;
    }

    .align-content-sm-stretch {
        align-content: stretch !important;
    }

    .align-self-sm-auto {
        align-self: auto !important;
    }

    .align-self-sm-start {
        align-self: flex-start !important;
    }

    .align-self-sm-end {
        align-self: flex-end !important;
    }

    .align-self-sm-center {
        align-self: center !important;
    }

    .align-self-sm-baseline {
        align-self: baseline !important;
    }

    .align-self-sm-stretch {
        align-self: stretch !important;
    }
}

@media (min-width: 960px) {
    .flex-md-row {
        flex-direction: row !important;
    }

    .flex-md-column {
        flex-direction: column !important;
    }

    .flex-md-row-reverse {
        flex-direction: row-reverse !important;
    }

    .flex-md-column-reverse {
        flex-direction: column-reverse !important;
    }

    .flex-md-wrap {
        flex-wrap: wrap !important;
    }

    .flex-md-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .flex-md-fill {
        flex: 1 1 auto !important;
    }

    .flex-md-grow-0 {
        flex-grow: 0 !important;
    }

    .flex-md-grow-1 {
        flex-grow: 1 !important;
    }

    .flex-md-shrink-0 {
        flex-shrink: 0 !important;
    }

    .flex-md-shrink-1 {
        flex-shrink: 1 !important;
    }

    .justify-content-md-start {
        justify-content: flex-start !important;
    }

    .justify-content-md-end {
        justify-content: flex-end !important;
    }

    .justify-content-md-center {
        justify-content: center !important;
    }

    .justify-content-md-between {
        justify-content: space-between !important;
    }

    .justify-content-md-around {
        justify-content: space-around !important;
    }

    .align-items-md-start {
        align-items: flex-start !important;
    }

    .align-items-md-end {
        align-items: flex-end !important;
    }

    .align-items-md-center {
        align-items: center !important;
    }

    .align-items-md-baseline {
        align-items: baseline !important;
    }

    .align-items-md-stretch {
        align-items: stretch !important;
    }

    .align-content-md-start {
        align-content: flex-start !important;
    }

    .align-content-md-end {
        align-content: flex-end !important;
    }

    .align-content-md-center {
        align-content: center !important;
    }

    .align-content-md-between {
        align-content: space-between !important;
    }

    .align-content-md-around {
        align-content: space-around !important;
    }

    .align-content-md-stretch {
        align-content: stretch !important;
    }

    .align-self-md-auto {
        align-self: auto !important;
    }

    .align-self-md-start {
        align-self: flex-start !important;
    }

    .align-self-md-end {
        align-self: flex-end !important;
    }

    .align-self-md-center {
        align-self: center !important;
    }

    .align-self-md-baseline {
        align-self: baseline !important;
    }

    .align-self-md-stretch {
        align-self: stretch !important;
    }
}

@media (min-width: 1280px) {
    .flex-lg-row {
        flex-direction: row !important;
    }

    .flex-lg-column {
        flex-direction: column !important;
    }

    .flex-lg-row-reverse {
        flex-direction: row-reverse !important;
    }

    .flex-lg-column-reverse {
        flex-direction: column-reverse !important;
    }

    .flex-lg-wrap {
        flex-wrap: wrap !important;
    }

    .flex-lg-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .flex-lg-fill {
        flex: 1 1 auto !important;
    }

    .flex-lg-grow-0 {
        flex-grow: 0 !important;
    }

    .flex-lg-grow-1 {
        flex-grow: 1 !important;
    }

    .flex-lg-shrink-0 {
        flex-shrink: 0 !important;
    }

    .flex-lg-shrink-1 {
        flex-shrink: 1 !important;
    }

    .justify-content-lg-start {
        justify-content: flex-start !important;
    }

    .justify-content-lg-end {
        justify-content: flex-end !important;
    }

    .justify-content-lg-center {
        justify-content: center !important;
    }

    .justify-content-lg-between {
        justify-content: space-between !important;
    }

    .justify-content-lg-around {
        justify-content: space-around !important;
    }

    .align-items-lg-start {
        align-items: flex-start !important;
    }

    .align-items-lg-end {
        align-items: flex-end !important;
    }

    .align-items-lg-center {
        align-items: center !important;
    }

    .align-items-lg-baseline {
        align-items: baseline !important;
    }

    .align-items-lg-stretch {
        align-items: stretch !important;
    }

    .align-content-lg-start {
        align-content: flex-start !important;
    }

    .align-content-lg-end {
        align-content: flex-end !important;
    }

    .align-content-lg-center {
        align-content: center !important;
    }

    .align-content-lg-between {
        align-content: space-between !important;
    }

    .align-content-lg-around {
        align-content: space-around !important;
    }

    .align-content-lg-stretch {
        align-content: stretch !important;
    }

    .align-self-lg-auto {
        align-self: auto !important;
    }

    .align-self-lg-start {
        align-self: flex-start !important;
    }

    .align-self-lg-end {
        align-self: flex-end !important;
    }

    .align-self-lg-center {
        align-self: center !important;
    }

    .align-self-lg-baseline {
        align-self: baseline !important;
    }

    .align-self-lg-stretch {
        align-self: stretch !important;
    }
}

@media (min-width: 1920px) {
    .flex-xl-row {
        flex-direction: row !important;
    }

    .flex-xl-column {
        flex-direction: column !important;
    }

    .flex-xl-row-reverse {
        flex-direction: row-reverse !important;
    }

    .flex-xl-column-reverse {
        flex-direction: column-reverse !important;
    }

    .flex-xl-wrap {
        flex-wrap: wrap !important;
    }

    .flex-xl-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .flex-xl-fill {
        flex: 1 1 auto !important;
    }

    .flex-xl-grow-0 {
        flex-grow: 0 !important;
    }

    .flex-xl-grow-1 {
        flex-grow: 1 !important;
    }

    .flex-xl-shrink-0 {
        flex-shrink: 0 !important;
    }

    .flex-xl-shrink-1 {
        flex-shrink: 1 !important;
    }

    .justify-content-xl-start {
        justify-content: flex-start !important;
    }

    .justify-content-xl-end {
        justify-content: flex-end !important;
    }

    .justify-content-xl-center {
        justify-content: center !important;
    }

    .justify-content-xl-between {
        justify-content: space-between !important;
    }

    .justify-content-xl-around {
        justify-content: space-around !important;
    }

    .align-items-xl-start {
        align-items: flex-start !important;
    }

    .align-items-xl-end {
        align-items: flex-end !important;
    }

    .align-items-xl-center {
        align-items: center !important;
    }

    .align-items-xl-baseline {
        align-items: baseline !important;
    }

    .align-items-xl-stretch {
        align-items: stretch !important;
    }

    .align-content-xl-start {
        align-content: flex-start !important;
    }

    .align-content-xl-end {
        align-content: flex-end !important;
    }

    .align-content-xl-center {
        align-content: center !important;
    }

    .align-content-xl-between {
        align-content: space-between !important;
    }

    .align-content-xl-around {
        align-content: space-around !important;
    }

    .align-content-xl-stretch {
        align-content: stretch !important;
    }

    .align-self-xl-auto {
        align-self: auto !important;
    }

    .align-self-xl-start {
        align-self: flex-start !important;
    }

    .align-self-xl-end {
        align-self: flex-end !important;
    }

    .align-self-xl-center {
        align-self: center !important;
    }

    .align-self-xl-baseline {
        align-self: baseline !important;
    }

    .align-self-xl-stretch {
        align-self: stretch !important;
    }
}

.float-left {
    float: left !important;
}

.float-right {
    float: right !important;
}

.float-none {
    float: none !important;
}

@media (min-width: 600px) {
    .float-sm-left {
        float: left !important;
    }

    .float-sm-right {
        float: right !important;
    }

    .float-sm-none {
        float: none !important;
    }
}

@media (min-width: 960px) {
    .float-md-left {
        float: left !important;
    }

    .float-md-right {
        float: right !important;
    }

    .float-md-none {
        float: none !important;
    }
}

@media (min-width: 1280px) {
    .float-lg-left {
        float: left !important;
    }

    .float-lg-right {
        float: right !important;
    }

    .float-lg-none {
        float: none !important;
    }
}

@media (min-width: 1920px) {
    .float-xl-left {
        float: left !important;
    }

    .float-xl-right {
        float: right !important;
    }

    .float-xl-none {
        float: none !important;
    }
}

.overflow-auto {
    overflow: auto !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

.position-static {
    position: static !important;
}

.position-relative {
    position: relative !important;
}

.position-absolute {
    position: absolute !important;
}

.position-fixed {
    position: fixed !important;
}

.position-sticky {
    position: sticky !important;
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1330;
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1330;
}

@supports (position: sticky) {
    .sticky-top {
        position: sticky;
        top: 0;
        z-index: 1320;
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
}

.shadow-sm {
    box-shadow: 0 0.313rem 0.8rem rgba(122, 123, 151, 0.5), 0 0.126rem 0.225rem rgba(122, 123, 151, 0.3) !important;
}

.shadow {
    box-shadow: 0 0.5rem 1rem rgba(7, 9, 25, 0.55) !important;
}

.shadow-lg {
    box-shadow: 0 1rem 3rem rgba(7, 9, 25, 0.575) !important;
}

.shadow-none {
    box-shadow: none !important;
}

.w-25 {
    width: 25% !important;
}

.w-50 {
    width: 50% !important;
}

.w-75 {
    width: 75% !important;
}

.w-100 {
    width: 100% !important;
}

.w-auto {
    width: auto !important;
}

.h-25 {
    height: 25% !important;
}

.h-50 {
    height: 50% !important;
}

.h-75 {
    height: 75% !important;
}

.h-100 {
    height: 100% !important;
}

.h-auto {
    height: auto !important;
}

.mw-100 {
    max-width: 100% !important;
}

.mh-100 {
    max-height: 100% !important;
}

.min-vw-100 {
    min-width: 100vw !important;
}

.min-vh-100 {
    min-height: 100vh !important;
}

.vw-100 {
    width: 100vw !important;
}

.vh-100 {
    height: 100vh !important;
}

.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0, 0, 0, 0);
}

.m-0 {
    margin: 0 !important;
}

.mt-0,
.my-0 {
    margin-top: 0 !important;
}

.mr-0,
.mx-0 {
    margin-right: 0 !important;
}

.mb-0,
.my-0 {
    margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
    margin-left: 0 !important;
}

.m-1 {
    margin: 0.25rem !important;
}

.mt-1,
.my-1 {
    margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
    margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
    margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
    margin-left: 0.25rem !important;
}

.m-2 {
    margin: 0.5rem !important;
}

.mt-2,
.my-2 {
    margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
    margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
    margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
    margin-left: 0.5rem !important;
}

.m-3 {
    margin: 1rem !important;
}

.mt-3,
.my-3 {
    margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
    margin-right: 1rem !important;
}

.mb-3,
.my-3 {
    margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
    margin-left: 1rem !important;
}

.m-4 {
    margin: 1.5rem !important;
}

.mt-4,
.my-4 {
    margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
    margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
    margin-left: 1.5rem !important;
}

.m-5 {
    margin: 3rem !important;
}

.mt-5,
.my-5 {
    margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
    margin-right: 3rem !important;
}

.mb-5,
.my-5 {
    margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
    margin-left: 3rem !important;
}

.p-0 {
    padding: 0 !important;
}

.pt-0,
.py-0 {
    padding-top: 0 !important;
}

.pr-0,
.px-0 {
    padding-right: 0 !important;
}

.pb-0,
.py-0 {
    padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
    padding-left: 0 !important;
}

.p-1 {
    padding: 0.25rem !important;
}

.pt-1,
.py-1 {
    padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
    padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
    padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
    padding-left: 0.25rem !important;
}

.p-2 {
    padding: 0.5rem !important;
}

.pt-2,
.py-2 {
    padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
    padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
    padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
    padding-left: 0.5rem !important;
}

.p-3 {
    padding: 1rem !important;
}

.pt-3,
.py-3 {
    padding-top: 1rem !important;
}

.pr-3,
.px-3 {
    padding-right: 1rem !important;
}

.pb-3,
.py-3 {
    padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
    padding-left: 1rem !important;
}

.p-4 {
    padding: 1.5rem !important;
}

.pt-4,
.py-4 {
    padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
    padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
    padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
    padding-left: 1.5rem !important;
}

.p-5 {
    padding: 3rem !important;
}

.pt-5,
.py-5 {
    padding-top: 3rem !important;
}

.pr-5,
.px-5 {
    padding-right: 3rem !important;
}

.pb-5,
.py-5 {
    padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
    padding-left: 3rem !important;
}

.m-n1 {
    margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
    margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
    margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
    margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
    margin-left: -0.25rem !important;
}

.m-n2 {
    margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
    margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
    margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
    margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
    margin-left: -0.5rem !important;
}

.m-n3 {
    margin: -1rem !important;
}

.mt-n3,
.my-n3 {
    margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
    margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
    margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
    margin-left: -1rem !important;
}

.m-n4 {
    margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
    margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
    margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
    margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
    margin-left: -1.5rem !important;
}

.m-n5 {
    margin: -3rem !important;
}

.mt-n5,
.my-n5 {
    margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
    margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
    margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
    margin-left: -3rem !important;
}

.m-auto {
    margin: auto !important;
}

.mt-auto,
.my-auto {
    margin-top: auto !important;
}

.mr-auto,
.mx-auto {
    margin-right: auto !important;
}

.mb-auto,
.my-auto {
    margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
    margin-left: auto !important;
}

@media (min-width: 600px) {
    .m-sm-0 {
        margin: 0 !important;
    }

    .mt-sm-0,
    .my-sm-0 {
        margin-top: 0 !important;
    }

    .mr-sm-0,
    .mx-sm-0 {
        margin-right: 0 !important;
    }

    .mb-sm-0,
    .my-sm-0 {
        margin-bottom: 0 !important;
    }

    .ml-sm-0,
    .mx-sm-0 {
        margin-left: 0 !important;
    }

    .m-sm-1 {
        margin: 0.25rem !important;
    }

    .mt-sm-1,
    .my-sm-1 {
        margin-top: 0.25rem !important;
    }

    .mr-sm-1,
    .mx-sm-1 {
        margin-right: 0.25rem !important;
    }

    .mb-sm-1,
    .my-sm-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-sm-1,
    .mx-sm-1 {
        margin-left: 0.25rem !important;
    }

    .m-sm-2 {
        margin: 0.5rem !important;
    }

    .mt-sm-2,
    .my-sm-2 {
        margin-top: 0.5rem !important;
    }

    .mr-sm-2,
    .mx-sm-2 {
        margin-right: 0.5rem !important;
    }

    .mb-sm-2,
    .my-sm-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-sm-2,
    .mx-sm-2 {
        margin-left: 0.5rem !important;
    }

    .m-sm-3 {
        margin: 1rem !important;
    }

    .mt-sm-3,
    .my-sm-3 {
        margin-top: 1rem !important;
    }

    .mr-sm-3,
    .mx-sm-3 {
        margin-right: 1rem !important;
    }

    .mb-sm-3,
    .my-sm-3 {
        margin-bottom: 1rem !important;
    }

    .ml-sm-3,
    .mx-sm-3 {
        margin-left: 1rem !important;
    }

    .m-sm-4 {
        margin: 1.5rem !important;
    }

    .mt-sm-4,
    .my-sm-4 {
        margin-top: 1.5rem !important;
    }

    .mr-sm-4,
    .mx-sm-4 {
        margin-right: 1.5rem !important;
    }

    .mb-sm-4,
    .my-sm-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-sm-4,
    .mx-sm-4 {
        margin-left: 1.5rem !important;
    }

    .m-sm-5 {
        margin: 3rem !important;
    }

    .mt-sm-5,
    .my-sm-5 {
        margin-top: 3rem !important;
    }

    .mr-sm-5,
    .mx-sm-5 {
        margin-right: 3rem !important;
    }

    .mb-sm-5,
    .my-sm-5 {
        margin-bottom: 3rem !important;
    }

    .ml-sm-5,
    .mx-sm-5 {
        margin-left: 3rem !important;
    }

    .p-sm-0 {
        padding: 0 !important;
    }

    .pt-sm-0,
    .py-sm-0 {
        padding-top: 0 !important;
    }

    .pr-sm-0,
    .px-sm-0 {
        padding-right: 0 !important;
    }

    .pb-sm-0,
    .py-sm-0 {
        padding-bottom: 0 !important;
    }

    .pl-sm-0,
    .px-sm-0 {
        padding-left: 0 !important;
    }

    .p-sm-1 {
        padding: 0.25rem !important;
    }

    .pt-sm-1,
    .py-sm-1 {
        padding-top: 0.25rem !important;
    }

    .pr-sm-1,
    .px-sm-1 {
        padding-right: 0.25rem !important;
    }

    .pb-sm-1,
    .py-sm-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-sm-1,
    .px-sm-1 {
        padding-left: 0.25rem !important;
    }

    .p-sm-2 {
        padding: 0.5rem !important;
    }

    .pt-sm-2,
    .py-sm-2 {
        padding-top: 0.5rem !important;
    }

    .pr-sm-2,
    .px-sm-2 {
        padding-right: 0.5rem !important;
    }

    .pb-sm-2,
    .py-sm-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-sm-2,
    .px-sm-2 {
        padding-left: 0.5rem !important;
    }

    .p-sm-3 {
        padding: 1rem !important;
    }

    .pt-sm-3,
    .py-sm-3 {
        padding-top: 1rem !important;
    }

    .pr-sm-3,
    .px-sm-3 {
        padding-right: 1rem !important;
    }

    .pb-sm-3,
    .py-sm-3 {
        padding-bottom: 1rem !important;
    }

    .pl-sm-3,
    .px-sm-3 {
        padding-left: 1rem !important;
    }

    .p-sm-4 {
        padding: 1.5rem !important;
    }

    .pt-sm-4,
    .py-sm-4 {
        padding-top: 1.5rem !important;
    }

    .pr-sm-4,
    .px-sm-4 {
        padding-right: 1.5rem !important;
    }

    .pb-sm-4,
    .py-sm-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-sm-4,
    .px-sm-4 {
        padding-left: 1.5rem !important;
    }

    .p-sm-5 {
        padding: 3rem !important;
    }

    .pt-sm-5,
    .py-sm-5 {
        padding-top: 3rem !important;
    }

    .pr-sm-5,
    .px-sm-5 {
        padding-right: 3rem !important;
    }

    .pb-sm-5,
    .py-sm-5 {
        padding-bottom: 3rem !important;
    }

    .pl-sm-5,
    .px-sm-5 {
        padding-left: 3rem !important;
    }

    .m-sm-n1 {
        margin: -0.25rem !important;
    }

    .mt-sm-n1,
    .my-sm-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-sm-n1,
    .mx-sm-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-sm-n1,
    .my-sm-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-sm-n1,
    .mx-sm-n1 {
        margin-left: -0.25rem !important;
    }

    .m-sm-n2 {
        margin: -0.5rem !important;
    }

    .mt-sm-n2,
    .my-sm-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-sm-n2,
    .mx-sm-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-sm-n2,
    .my-sm-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-sm-n2,
    .mx-sm-n2 {
        margin-left: -0.5rem !important;
    }

    .m-sm-n3 {
        margin: -1rem !important;
    }

    .mt-sm-n3,
    .my-sm-n3 {
        margin-top: -1rem !important;
    }

    .mr-sm-n3,
    .mx-sm-n3 {
        margin-right: -1rem !important;
    }

    .mb-sm-n3,
    .my-sm-n3 {
        margin-bottom: -1rem !important;
    }

    .ml-sm-n3,
    .mx-sm-n3 {
        margin-left: -1rem !important;
    }

    .m-sm-n4 {
        margin: -1.5rem !important;
    }

    .mt-sm-n4,
    .my-sm-n4 {
        margin-top: -1.5rem !important;
    }

    .mr-sm-n4,
    .mx-sm-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-sm-n4,
    .my-sm-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ml-sm-n4,
    .mx-sm-n4 {
        margin-left: -1.5rem !important;
    }

    .m-sm-n5 {
        margin: -3rem !important;
    }

    .mt-sm-n5,
    .my-sm-n5 {
        margin-top: -3rem !important;
    }

    .mr-sm-n5,
    .mx-sm-n5 {
        margin-right: -3rem !important;
    }

    .mb-sm-n5,
    .my-sm-n5 {
        margin-bottom: -3rem !important;
    }

    .ml-sm-n5,
    .mx-sm-n5 {
        margin-left: -3rem !important;
    }

    .m-sm-auto {
        margin: auto !important;
    }

    .mt-sm-auto,
    .my-sm-auto {
        margin-top: auto !important;
    }

    .mr-sm-auto,
    .mx-sm-auto {
        margin-right: auto !important;
    }

    .mb-sm-auto,
    .my-sm-auto {
        margin-bottom: auto !important;
    }

    .ml-sm-auto,
    .mx-sm-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 960px) {
    .m-md-0 {
        margin: 0 !important;
    }

    .mt-md-0,
    .my-md-0 {
        margin-top: 0 !important;
    }

    .mr-md-0,
    .mx-md-0 {
        margin-right: 0 !important;
    }

    .mb-md-0,
    .my-md-0 {
        margin-bottom: 0 !important;
    }

    .ml-md-0,
    .mx-md-0 {
        margin-left: 0 !important;
    }

    .m-md-1 {
        margin: 0.25rem !important;
    }

    .mt-md-1,
    .my-md-1 {
        margin-top: 0.25rem !important;
    }

    .mr-md-1,
    .mx-md-1 {
        margin-right: 0.25rem !important;
    }

    .mb-md-1,
    .my-md-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-md-1,
    .mx-md-1 {
        margin-left: 0.25rem !important;
    }

    .m-md-2 {
        margin: 0.5rem !important;
    }

    .mt-md-2,
    .my-md-2 {
        margin-top: 0.5rem !important;
    }

    .mr-md-2,
    .mx-md-2 {
        margin-right: 0.5rem !important;
    }

    .mb-md-2,
    .my-md-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-md-2,
    .mx-md-2 {
        margin-left: 0.5rem !important;
    }

    .m-md-3 {
        margin: 1rem !important;
    }

    .mt-md-3,
    .my-md-3 {
        margin-top: 1rem !important;
    }

    .mr-md-3,
    .mx-md-3 {
        margin-right: 1rem !important;
    }

    .mb-md-3,
    .my-md-3 {
        margin-bottom: 1rem !important;
    }

    .ml-md-3,
    .mx-md-3 {
        margin-left: 1rem !important;
    }

    .m-md-4 {
        margin: 1.5rem !important;
    }

    .mt-md-4,
    .my-md-4 {
        margin-top: 1.5rem !important;
    }

    .mr-md-4,
    .mx-md-4 {
        margin-right: 1.5rem !important;
    }

    .mb-md-4,
    .my-md-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-md-4,
    .mx-md-4 {
        margin-left: 1.5rem !important;
    }

    .m-md-5 {
        margin: 3rem !important;
    }

    .mt-md-5,
    .my-md-5 {
        margin-top: 3rem !important;
    }

    .mr-md-5,
    .mx-md-5 {
        margin-right: 3rem !important;
    }

    .mb-md-5,
    .my-md-5 {
        margin-bottom: 3rem !important;
    }

    .ml-md-5,
    .mx-md-5 {
        margin-left: 3rem !important;
    }

    .p-md-0 {
        padding: 0 !important;
    }

    .pt-md-0,
    .py-md-0 {
        padding-top: 0 !important;
    }

    .pr-md-0,
    .px-md-0 {
        padding-right: 0 !important;
    }

    .pb-md-0,
    .py-md-0 {
        padding-bottom: 0 !important;
    }

    .pl-md-0,
    .px-md-0 {
        padding-left: 0 !important;
    }

    .p-md-1 {
        padding: 0.25rem !important;
    }

    .pt-md-1,
    .py-md-1 {
        padding-top: 0.25rem !important;
    }

    .pr-md-1,
    .px-md-1 {
        padding-right: 0.25rem !important;
    }

    .pb-md-1,
    .py-md-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-md-1,
    .px-md-1 {
        padding-left: 0.25rem !important;
    }

    .p-md-2 {
        padding: 0.5rem !important;
    }

    .pt-md-2,
    .py-md-2 {
        padding-top: 0.5rem !important;
    }

    .pr-md-2,
    .px-md-2 {
        padding-right: 0.5rem !important;
    }

    .pb-md-2,
    .py-md-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-md-2,
    .px-md-2 {
        padding-left: 0.5rem !important;
    }

    .p-md-3 {
        padding: 1rem !important;
    }

    .pt-md-3,
    .py-md-3 {
        padding-top: 1rem !important;
    }

    .pr-md-3,
    .px-md-3 {
        padding-right: 1rem !important;
    }

    .pb-md-3,
    .py-md-3 {
        padding-bottom: 1rem !important;
    }

    .pl-md-3,
    .px-md-3 {
        padding-left: 1rem !important;
    }

    .p-md-4 {
        padding: 1.5rem !important;
    }

    .pt-md-4,
    .py-md-4 {
        padding-top: 1.5rem !important;
    }

    .pr-md-4,
    .px-md-4 {
        padding-right: 1.5rem !important;
    }

    .pb-md-4,
    .py-md-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-md-4,
    .px-md-4 {
        padding-left: 1.5rem !important;
    }

    .p-md-5 {
        padding: 3rem !important;
    }

    .pt-md-5,
    .py-md-5 {
        padding-top: 3rem !important;
    }

    .pr-md-5,
    .px-md-5 {
        padding-right: 3rem !important;
    }

    .pb-md-5,
    .py-md-5 {
        padding-bottom: 3rem !important;
    }

    .pl-md-5,
    .px-md-5 {
        padding-left: 3rem !important;
    }

    .m-md-n1 {
        margin: -0.25rem !important;
    }

    .mt-md-n1,
    .my-md-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-md-n1,
    .mx-md-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-md-n1,
    .my-md-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-md-n1,
    .mx-md-n1 {
        margin-left: -0.25rem !important;
    }

    .m-md-n2 {
        margin: -0.5rem !important;
    }

    .mt-md-n2,
    .my-md-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-md-n2,
    .mx-md-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-md-n2,
    .my-md-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-md-n2,
    .mx-md-n2 {
        margin-left: -0.5rem !important;
    }

    .m-md-n3 {
        margin: -1rem !important;
    }

    .mt-md-n3,
    .my-md-n3 {
        margin-top: -1rem !important;
    }

    .mr-md-n3,
    .mx-md-n3 {
        margin-right: -1rem !important;
    }

    .mb-md-n3,
    .my-md-n3 {
        margin-bottom: -1rem !important;
    }

    .ml-md-n3,
    .mx-md-n3 {
        margin-left: -1rem !important;
    }

    .m-md-n4 {
        margin: -1.5rem !important;
    }

    .mt-md-n4,
    .my-md-n4 {
        margin-top: -1.5rem !important;
    }

    .mr-md-n4,
    .mx-md-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-md-n4,
    .my-md-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ml-md-n4,
    .mx-md-n4 {
        margin-left: -1.5rem !important;
    }

    .m-md-n5 {
        margin: -3rem !important;
    }

    .mt-md-n5,
    .my-md-n5 {
        margin-top: -3rem !important;
    }

    .mr-md-n5,
    .mx-md-n5 {
        margin-right: -3rem !important;
    }

    .mb-md-n5,
    .my-md-n5 {
        margin-bottom: -3rem !important;
    }

    .ml-md-n5,
    .mx-md-n5 {
        margin-left: -3rem !important;
    }

    .m-md-auto {
        margin: auto !important;
    }

    .mt-md-auto,
    .my-md-auto {
        margin-top: auto !important;
    }

    .mr-md-auto,
    .mx-md-auto {
        margin-right: auto !important;
    }

    .mb-md-auto,
    .my-md-auto {
        margin-bottom: auto !important;
    }

    .ml-md-auto,
    .mx-md-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 1280px) {
    .m-lg-0 {
        margin: 0 !important;
    }

    .mt-lg-0,
    .my-lg-0 {
        margin-top: 0 !important;
    }

    .mr-lg-0,
    .mx-lg-0 {
        margin-right: 0 !important;
    }

    .mb-lg-0,
    .my-lg-0 {
        margin-bottom: 0 !important;
    }

    .ml-lg-0,
    .mx-lg-0 {
        margin-left: 0 !important;
    }

    .m-lg-1 {
        margin: 0.25rem !important;
    }

    .mt-lg-1,
    .my-lg-1 {
        margin-top: 0.25rem !important;
    }

    .mr-lg-1,
    .mx-lg-1 {
        margin-right: 0.25rem !important;
    }

    .mb-lg-1,
    .my-lg-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-lg-1,
    .mx-lg-1 {
        margin-left: 0.25rem !important;
    }

    .m-lg-2 {
        margin: 0.5rem !important;
    }

    .mt-lg-2,
    .my-lg-2 {
        margin-top: 0.5rem !important;
    }

    .mr-lg-2,
    .mx-lg-2 {
        margin-right: 0.5rem !important;
    }

    .mb-lg-2,
    .my-lg-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-lg-2,
    .mx-lg-2 {
        margin-left: 0.5rem !important;
    }

    .m-lg-3 {
        margin: 1rem !important;
    }

    .mt-lg-3,
    .my-lg-3 {
        margin-top: 1rem !important;
    }

    .mr-lg-3,
    .mx-lg-3 {
        margin-right: 1rem !important;
    }

    .mb-lg-3,
    .my-lg-3 {
        margin-bottom: 1rem !important;
    }

    .ml-lg-3,
    .mx-lg-3 {
        margin-left: 1rem !important;
    }

    .m-lg-4 {
        margin: 1.5rem !important;
    }

    .mt-lg-4,
    .my-lg-4 {
        margin-top: 1.5rem !important;
    }

    .mr-lg-4,
    .mx-lg-4 {
        margin-right: 1.5rem !important;
    }

    .mb-lg-4,
    .my-lg-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-lg-4,
    .mx-lg-4 {
        margin-left: 1.5rem !important;
    }

    .m-lg-5 {
        margin: 3rem !important;
    }

    .mt-lg-5,
    .my-lg-5 {
        margin-top: 3rem !important;
    }

    .mr-lg-5,
    .mx-lg-5 {
        margin-right: 3rem !important;
    }

    .mb-lg-5,
    .my-lg-5 {
        margin-bottom: 3rem !important;
    }

    .ml-lg-5,
    .mx-lg-5 {
        margin-left: 3rem !important;
    }

    .p-lg-0 {
        padding: 0 !important;
    }

    .pt-lg-0,
    .py-lg-0 {
        padding-top: 0 !important;
    }

    .pr-lg-0,
    .px-lg-0 {
        padding-right: 0 !important;
    }

    .pb-lg-0,
    .py-lg-0 {
        padding-bottom: 0 !important;
    }

    .pl-lg-0,
    .px-lg-0 {
        padding-left: 0 !important;
    }

    .p-lg-1 {
        padding: 0.25rem !important;
    }

    .pt-lg-1,
    .py-lg-1 {
        padding-top: 0.25rem !important;
    }

    .pr-lg-1,
    .px-lg-1 {
        padding-right: 0.25rem !important;
    }

    .pb-lg-1,
    .py-lg-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-lg-1,
    .px-lg-1 {
        padding-left: 0.25rem !important;
    }

    .p-lg-2 {
        padding: 0.5rem !important;
    }

    .pt-lg-2,
    .py-lg-2 {
        padding-top: 0.5rem !important;
    }

    .pr-lg-2,
    .px-lg-2 {
        padding-right: 0.5rem !important;
    }

    .pb-lg-2,
    .py-lg-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-lg-2,
    .px-lg-2 {
        padding-left: 0.5rem !important;
    }

    .p-lg-3 {
        padding: 1rem !important;
    }

    .pt-lg-3,
    .py-lg-3 {
        padding-top: 1rem !important;
    }

    .pr-lg-3,
    .px-lg-3 {
        padding-right: 1rem !important;
    }

    .pb-lg-3,
    .py-lg-3 {
        padding-bottom: 1rem !important;
    }

    .pl-lg-3,
    .px-lg-3 {
        padding-left: 1rem !important;
    }

    .p-lg-4 {
        padding: 1.5rem !important;
    }

    .pt-lg-4,
    .py-lg-4 {
        padding-top: 1.5rem !important;
    }

    .pr-lg-4,
    .px-lg-4 {
        padding-right: 1.5rem !important;
    }

    .pb-lg-4,
    .py-lg-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-lg-4,
    .px-lg-4 {
        padding-left: 1.5rem !important;
    }

    .p-lg-5 {
        padding: 3rem !important;
    }

    .pt-lg-5,
    .py-lg-5 {
        padding-top: 3rem !important;
    }

    .pr-lg-5,
    .px-lg-5 {
        padding-right: 3rem !important;
    }

    .pb-lg-5,
    .py-lg-5 {
        padding-bottom: 3rem !important;
    }

    .pl-lg-5,
    .px-lg-5 {
        padding-left: 3rem !important;
    }

    .m-lg-n1 {
        margin: -0.25rem !important;
    }

    .mt-lg-n1,
    .my-lg-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-lg-n1,
    .mx-lg-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-lg-n1,
    .my-lg-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-lg-n1,
    .mx-lg-n1 {
        margin-left: -0.25rem !important;
    }

    .m-lg-n2 {
        margin: -0.5rem !important;
    }

    .mt-lg-n2,
    .my-lg-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-lg-n2,
    .mx-lg-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-lg-n2,
    .my-lg-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-lg-n2,
    .mx-lg-n2 {
        margin-left: -0.5rem !important;
    }

    .m-lg-n3 {
        margin: -1rem !important;
    }

    .mt-lg-n3,
    .my-lg-n3 {
        margin-top: -1rem !important;
    }

    .mr-lg-n3,
    .mx-lg-n3 {
        margin-right: -1rem !important;
    }

    .mb-lg-n3,
    .my-lg-n3 {
        margin-bottom: -1rem !important;
    }

    .ml-lg-n3,
    .mx-lg-n3 {
        margin-left: -1rem !important;
    }

    .m-lg-n4 {
        margin: -1.5rem !important;
    }

    .mt-lg-n4,
    .my-lg-n4 {
        margin-top: -1.5rem !important;
    }

    .mr-lg-n4,
    .mx-lg-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-lg-n4,
    .my-lg-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ml-lg-n4,
    .mx-lg-n4 {
        margin-left: -1.5rem !important;
    }

    .m-lg-n5 {
        margin: -3rem !important;
    }

    .mt-lg-n5,
    .my-lg-n5 {
        margin-top: -3rem !important;
    }

    .mr-lg-n5,
    .mx-lg-n5 {
        margin-right: -3rem !important;
    }

    .mb-lg-n5,
    .my-lg-n5 {
        margin-bottom: -3rem !important;
    }

    .ml-lg-n5,
    .mx-lg-n5 {
        margin-left: -3rem !important;
    }

    .m-lg-auto {
        margin: auto !important;
    }

    .mt-lg-auto,
    .my-lg-auto {
        margin-top: auto !important;
    }

    .mr-lg-auto,
    .mx-lg-auto {
        margin-right: auto !important;
    }

    .mb-lg-auto,
    .my-lg-auto {
        margin-bottom: auto !important;
    }

    .ml-lg-auto,
    .mx-lg-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 1920px) {
    .m-xl-0 {
        margin: 0 !important;
    }

    .mt-xl-0,
    .my-xl-0 {
        margin-top: 0 !important;
    }

    .mr-xl-0,
    .mx-xl-0 {
        margin-right: 0 !important;
    }

    .mb-xl-0,
    .my-xl-0 {
        margin-bottom: 0 !important;
    }

    .ml-xl-0,
    .mx-xl-0 {
        margin-left: 0 !important;
    }

    .m-xl-1 {
        margin: 0.25rem !important;
    }

    .mt-xl-1,
    .my-xl-1 {
        margin-top: 0.25rem !important;
    }

    .mr-xl-1,
    .mx-xl-1 {
        margin-right: 0.25rem !important;
    }

    .mb-xl-1,
    .my-xl-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-xl-1,
    .mx-xl-1 {
        margin-left: 0.25rem !important;
    }

    .m-xl-2 {
        margin: 0.5rem !important;
    }

    .mt-xl-2,
    .my-xl-2 {
        margin-top: 0.5rem !important;
    }

    .mr-xl-2,
    .mx-xl-2 {
        margin-right: 0.5rem !important;
    }

    .mb-xl-2,
    .my-xl-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-xl-2,
    .mx-xl-2 {
        margin-left: 0.5rem !important;
    }

    .m-xl-3 {
        margin: 1rem !important;
    }

    .mt-xl-3,
    .my-xl-3 {
        margin-top: 1rem !important;
    }

    .mr-xl-3,
    .mx-xl-3 {
        margin-right: 1rem !important;
    }

    .mb-xl-3,
    .my-xl-3 {
        margin-bottom: 1rem !important;
    }

    .ml-xl-3,
    .mx-xl-3 {
        margin-left: 1rem !important;
    }

    .m-xl-4 {
        margin: 1.5rem !important;
    }

    .mt-xl-4,
    .my-xl-4 {
        margin-top: 1.5rem !important;
    }

    .mr-xl-4,
    .mx-xl-4 {
        margin-right: 1.5rem !important;
    }

    .mb-xl-4,
    .my-xl-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-xl-4,
    .mx-xl-4 {
        margin-left: 1.5rem !important;
    }

    .m-xl-5 {
        margin: 3rem !important;
    }

    .mt-xl-5,
    .my-xl-5 {
        margin-top: 3rem !important;
    }

    .mr-xl-5,
    .mx-xl-5 {
        margin-right: 3rem !important;
    }

    .mb-xl-5,
    .my-xl-5 {
        margin-bottom: 3rem !important;
    }

    .ml-xl-5,
    .mx-xl-5 {
        margin-left: 3rem !important;
    }

    .p-xl-0 {
        padding: 0 !important;
    }

    .pt-xl-0,
    .py-xl-0 {
        padding-top: 0 !important;
    }

    .pr-xl-0,
    .px-xl-0 {
        padding-right: 0 !important;
    }

    .pb-xl-0,
    .py-xl-0 {
        padding-bottom: 0 !important;
    }

    .pl-xl-0,
    .px-xl-0 {
        padding-left: 0 !important;
    }

    .p-xl-1 {
        padding: 0.25rem !important;
    }

    .pt-xl-1,
    .py-xl-1 {
        padding-top: 0.25rem !important;
    }

    .pr-xl-1,
    .px-xl-1 {
        padding-right: 0.25rem !important;
    }

    .pb-xl-1,
    .py-xl-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-xl-1,
    .px-xl-1 {
        padding-left: 0.25rem !important;
    }

    .p-xl-2 {
        padding: 0.5rem !important;
    }

    .pt-xl-2,
    .py-xl-2 {
        padding-top: 0.5rem !important;
    }

    .pr-xl-2,
    .px-xl-2 {
        padding-right: 0.5rem !important;
    }

    .pb-xl-2,
    .py-xl-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-xl-2,
    .px-xl-2 {
        padding-left: 0.5rem !important;
    }

    .p-xl-3 {
        padding: 1rem !important;
    }

    .pt-xl-3,
    .py-xl-3 {
        padding-top: 1rem !important;
    }

    .pr-xl-3,
    .px-xl-3 {
        padding-right: 1rem !important;
    }

    .pb-xl-3,
    .py-xl-3 {
        padding-bottom: 1rem !important;
    }

    .pl-xl-3,
    .px-xl-3 {
        padding-left: 1rem !important;
    }

    .p-xl-4 {
        padding: 1.5rem !important;
    }

    .pt-xl-4,
    .py-xl-4 {
        padding-top: 1.5rem !important;
    }

    .pr-xl-4,
    .px-xl-4 {
        padding-right: 1.5rem !important;
    }

    .pb-xl-4,
    .py-xl-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-xl-4,
    .px-xl-4 {
        padding-left: 1.5rem !important;
    }

    .p-xl-5 {
        padding: 3rem !important;
    }

    .pt-xl-5,
    .py-xl-5 {
        padding-top: 3rem !important;
    }

    .pr-xl-5,
    .px-xl-5 {
        padding-right: 3rem !important;
    }

    .pb-xl-5,
    .py-xl-5 {
        padding-bottom: 3rem !important;
    }

    .pl-xl-5,
    .px-xl-5 {
        padding-left: 3rem !important;
    }

    .m-xl-n1 {
        margin: -0.25rem !important;
    }

    .mt-xl-n1,
    .my-xl-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-xl-n1,
    .mx-xl-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-xl-n1,
    .my-xl-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-xl-n1,
    .mx-xl-n1 {
        margin-left: -0.25rem !important;
    }

    .m-xl-n2 {
        margin: -0.5rem !important;
    }

    .mt-xl-n2,
    .my-xl-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-xl-n2,
    .mx-xl-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-xl-n2,
    .my-xl-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-xl-n2,
    .mx-xl-n2 {
        margin-left: -0.5rem !important;
    }

    .m-xl-n3 {
        margin: -1rem !important;
    }

    .mt-xl-n3,
    .my-xl-n3 {
        margin-top: -1rem !important;
    }

    .mr-xl-n3,
    .mx-xl-n3 {
        margin-right: -1rem !important;
    }

    .mb-xl-n3,
    .my-xl-n3 {
        margin-bottom: -1rem !important;
    }

    .ml-xl-n3,
    .mx-xl-n3 {
        margin-left: -1rem !important;
    }

    .m-xl-n4 {
        margin: -1.5rem !important;
    }

    .mt-xl-n4,
    .my-xl-n4 {
        margin-top: -1.5rem !important;
    }

    .mr-xl-n4,
    .mx-xl-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-xl-n4,
    .my-xl-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ml-xl-n4,
    .mx-xl-n4 {
        margin-left: -1.5rem !important;
    }

    .m-xl-n5 {
        margin: -3rem !important;
    }

    .mt-xl-n5,
    .my-xl-n5 {
        margin-top: -3rem !important;
    }

    .mr-xl-n5,
    .mx-xl-n5 {
        margin-right: -3rem !important;
    }

    .mb-xl-n5,
    .my-xl-n5 {
        margin-bottom: -3rem !important;
    }

    .ml-xl-n5,
    .mx-xl-n5 {
        margin-left: -3rem !important;
    }

    .m-xl-auto {
        margin: auto !important;
    }

    .mt-xl-auto,
    .my-xl-auto {
        margin-top: auto !important;
    }

    .mr-xl-auto,
    .mx-xl-auto {
        margin-right: auto !important;
    }

    .mb-xl-auto,
    .my-xl-auto {
        margin-bottom: auto !important;
    }

    .ml-xl-auto,
    .mx-xl-auto {
        margin-left: auto !important;
    }
}

.text-monospace {
    font-family: "Times New Roman", Times, serif !important;
}

.text-justify {
    text-align: justify !important;
}

.text-wrap {
    white-space: normal !important;
}

.text-nowrap {
    white-space: nowrap !important;
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

@media (min-width: 600px) {
    .text-sm-left {
        text-align: left !important;
    }

    .text-sm-right {
        text-align: right !important;
    }

    .text-sm-center {
        text-align: center !important;
    }
}

@media (min-width: 960px) {
    .text-md-left {
        text-align: left !important;
    }

    .text-md-right {
        text-align: right !important;
    }

    .text-md-center {
        text-align: center !important;
    }
}

@media (min-width: 1280px) {
    .text-lg-left {
        text-align: left !important;
    }

    .text-lg-right {
        text-align: right !important;
    }

    .text-lg-center {
        text-align: center !important;
    }
}

@media (min-width: 1920px) {
    .text-xl-left {
        text-align: left !important;
    }

    .text-xl-right {
        text-align: right !important;
    }

    .text-xl-center {
        text-align: center !important;
    }
}

.text-lowercase {
    text-transform: lowercase !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}

.font-weight-light {
    font-weight: 300 !important;
}

.font-weight-lighter {
    font-weight: lighter !important;
}

.font-weight-normal {
    font-weight: 400 !important;
}

.font-weight-bold {
    font-weight: 700 !important;
}

.font-weight-bolder {
    font-weight: bolder !important;
}

.font-italic {
    font-style: italic !important;
}

.text-white {
    color: #fff !important;
}

.text-primary {
    color: #2F80ED !important;
}

a.text-primary:hover, a.text-primary:focus {
    color: rgb(16.5265486726, 90.8960176991, 190.9734513274) !important;
}

.text-secondary {
    color: #f8f9ff !important;
}

a.text-secondary:hover, a.text-secondary:focus {
    color: rgb(171.5, 183.4285714286, 255) !important;
}

.text-success {
    color: #1bc943 !important;
}

a.text-success:hover, a.text-success:focus {
    color: rgb(17.9407894737, 133.5592105263, 44.5197368421) !important;
}

.text-info {
    color: #11c5db !important;
}

a.text-info:hover, a.text-info:focus {
    color: rgb(11.4894067797, 133.1419491525, 148.0105932203) !important;
}

.text-warning {
    color: #f4772e !important;
}

a.text-warning:hover, a.text-warning:focus {
    color: rgb(202.825, 81.5181818182, 10.675) !important;
}

.text-danger {
    color: #f83245 !important;
}

a.text-danger:hover, a.text-danger:focus {
    color: rgb(214.1863207547, 7.3136792453, 27.1650943396) !important;
}

.text-light {
    color: #f4f5fd !important;
}

a.text-light:hover, a.text-light:focus {
    color: rgb(179.2692307692, 186.1538461538, 241.2307692308) !important;
}

.text-gray {
    color: #f8f9ff !important;
}

a.text-gray:hover, a.text-gray:focus {
    color: rgb(171.5, 183.4285714286, 255) !important;
}

.text-dark {
    color: #7a7b97 !important;
}

a.text-dark:hover, a.text-dark:focus {
    color: rgb(86.2278481013, 87.0569620253, 110.2721518987) !important;
}

.text-first {
    color: #2F80ED !important;
}

a.text-first:hover, a.text-first:focus {
    color: rgb(16.5265486726, 90.8960176991, 190.9734513274) !important;
}

.text-second {
    color: #070919 !important;
}

a.text-second:hover, a.text-second:focus {
    color: black !important;
}

.text-body {
    color: #3b3e66 !important;
}

.text-muted {
    color: #d1d2db !important;
}

.text-black-50 {
    color: rgba(7, 9, 25, 0.5) !important;
}

.text-white-50 {
    color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.text-decoration-none {
    text-decoration: none !important;
}

.text-break {
    word-break: break-word !important;
    overflow-wrap: break-word !important;
}

.text-reset {
    color: inherit !important;
}

.visible {
    visibility: visible !important;
}

.invisible {
    visibility: hidden !important;
}

:root {
    --blue: #2F80ED;
    --indigo: #7420ff;
    --purple: #793de6;
    --pink: #fc26a4;
    --red: #f83245;
    --orange: #f4772e;
    --yellow: #ffc926;
    --green: #1bc943;
    --teal: #18e1a5;
    --cyan: #11c5db;
    --white: #fff;
    --gray: #d1d2db;
    --gray-dark: #7a7b97;
    --primary: #2F80ED;
    --secondary: #f8f9ff;
    --success: #1bc943;
    --info: #11c5db;
    --warning: #f4772e;
    --danger: #f83245;
    --light: #f4f5fd;
    --gray: #f8f9ff;
    --dark: #7a7b97;
    --first: #2F80ED;
    --second: #070919;
    --breakpoint-xs: 0;
    --breakpoint-sm: 600px;
    --breakpoint-md: 960px;
    --breakpoint-lg: 1280px;
    --breakpoint-xl: 1920px;
    --font-family-sans-serif: "DM Sans";
    --font-family-monospace: "Times New Roman", Times, serif;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(7, 9, 25, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
    display: block;
}

body {
    margin: 0;
    font-family: "DM Sans";
    font-size: 0.95rem;
    font-weight: 400;
    line-height: 1.5;
    color: #3b3e66;
    text-align: left;
    background-color: #fafafa !important;
}

[tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
    text-decoration: underline;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    text-decoration-skip-ink: none;
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
}

ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
    margin-bottom: 0;
}

dt {
    font-weight: 700;
}

dd {
    margin-bottom: 0.5rem;
    margin-left: 0;
}

blockquote {
    margin: 0 0 1rem;
}

b,
strong {
    font-weight: bolder;
}

small {
    font-size: 80%;
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

a:not(.MuiButtonBase-root) {
    color: #3b3e66;
    text-decoration: none;
    background-color: transparent;
}

a:not(.MuiButtonBase-root):hover {
    color: #2F80ED;
    text-decoration: none;
}

a.card.card-box {
    background-color: #fff;
}

a:not([href]) {
    color: inherit;
    text-decoration: none;
}

a:not([href]):hover {
    color: inherit;
    text-decoration: none;
}

pre,
code,
kbd,
samp {
    font-family: "Times New Roman", Times, serif;
    font-size: 1em;
}

pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
}

figure {
    margin: 0 0 1rem;
}

img {
    vertical-align: middle;
    border-style: none;
}

svg {
    overflow: hidden;
    vertical-align: middle;
}

table {
    border-collapse: collapse;
}

caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #d1d2db;
    text-align: left;
    caption-side: bottom;
}

th {
    text-align: inherit;
}

label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

button {
    border-radius: 0;
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

select {
    word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
    -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
    cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

input[type=radio],
input[type=checkbox] {
    box-sizing: border-box;
    padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
    -webkit-appearance: listbox;
}

textarea {
    overflow: auto;
    resize: vertical;
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
}

progress {
    vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
    height: auto;
}

[type=search] {
    outline-offset: -2px;
    -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}

output {
    display: inline-block;
}

summary {
    display: list-item;
    cursor: pointer;
}

template {
    display: none;
}

[hidden] {
    display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}

h1, .h1 {
    font-size: 2.375rem;
}

h2, .h2 {
    font-size: 1.9rem;
}

h3, .h3 {
    font-size: 1.6625rem;
}

h4, .h4 {
    font-size: 1.425rem;
}

h5, .h5 {
    font-size: 1.1875rem;
}

h6, .h6 {
    font-size: 0.95rem;
}

.lead {
    font-size: 1.1875rem;
    font-weight: 300;
}

.display-1 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2;
}

.display-2 {
    font-size: 3rem;
    font-weight: 300;
    line-height: 1.2;
}

.display-3 {
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1.2;
}

.display-4 {
    font-size: 1.7rem;
    font-weight: 300;
    line-height: 1.2;
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(7, 9, 25, 0.1);
}

small,
.small {
    font-size: 80%;
    font-weight: 400;
}

mark,
.mark {
    padding: 0.2em;
    background-color: #fcf8e3;
}

.list-unstyled {
    padding-left: 0;
    list-style: none;
}

.list-inline {
    padding-left: 0;
    list-style: none;
}

.list-inline-item {
    display: inline-block;
}

.list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
}

.initialism {
    font-size: 90%;
    text-transform: uppercase;
}

.blockquote {
    margin-bottom: 1rem;
    font-size: 1.1875rem;
}

.blockquote-footer {
    display: block;
    font-size: 80%;
    color: #d1d2db;
}

.blockquote-footer::before {
    content: "— ";
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.img-thumbnail {
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid #eeeff8;
    border-radius: 0.65rem;
    max-width: 100%;
    height: auto;
}

.figure {
    display: inline-block;
}

.figure-img {
    margin-bottom: 0.5rem;
    line-height: 1;
}

.figure-caption {
    font-size: 90%;
    color: #d1d2db;
}

.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #3b3e66;
}

.table th,
.table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid rgb(219.9375, 222, 240.5625);
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid rgb(219.9375, 222, 240.5625);
}

.table tbody + tbody {
    border-top: 2px solid rgb(219.9375, 222, 240.5625);
}

.table-sm th,
.table-sm td {
    padding: 0.3rem;
}

.table-bordered {
    border: 1px solid rgb(219.9375, 222, 240.5625);
}

.table-bordered th,
.table-bordered td {
    border: 1px solid rgb(219.9375, 222, 240.5625);
}

.table-bordered thead th,
.table-bordered thead td {
    border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
    border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgb(249.05, 249.75, 253.95);
}

.table-hover tbody tr:hover {
    color: #3b3e66;
    background-color: rgb(255, 251.1923076923, 241.5);
}

.table-primary,
.table-primary > th,
.table-primary > td {
    background-color: rgb(196.76, 219.44, 249.96);
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
    border-color: rgb(146.84, 188.96, 245.64);
}

.table-hover .table-primary:hover {
    background-color: rgb(173.2909734513, 205.1103539823, 247.9290265487);
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
    background-color: rgb(173.2909734513, 205.1103539823, 247.9290265487);
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
    background-color: rgb(253.04, 253.32, 255);
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
    border-color: rgb(251.36, 251.88, 255);
}

.table-hover .table-secondary:hover {
    background-color: rgb(227.54, 231.4628571429, 255);
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
    background-color: rgb(227.54, 231.4628571429, 255);
}

.table-success,
.table-success > th,
.table-success > td {
    background-color: rgb(191.16, 239.88, 202.36);
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
    border-color: rgb(136.44, 226.92, 157.24);
}

.table-hover .table-success:hover {
    background-color: rgb(170.5429787234, 234.9970212766, 185.36);
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
    background-color: rgb(170.5429787234, 234.9970212766, 185.36);
}

.table-info,
.table-info > th,
.table-info > td {
    background-color: rgb(188.36, 238.76, 244.92);
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
    border-color: rgb(131.24, 224.84, 236.28);
}

.table-hover .table-info:hover {
    background-color: rgb(166.2103649635, 233.362189781, 241.5696350365);
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
    background-color: rgb(166.2103649635, 233.362189781, 241.5696350365);
}

.table-warning,
.table-warning > th,
.table-warning > td {
    background-color: rgb(251.92, 216.92, 196.48);
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
    border-color: rgb(249.28, 184.28, 146.32);
}

.table-hover .table-warning:hover {
    background-color: rgb(250.645, 201.1563636364, 172.255);
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
    background-color: rgb(250.645, 201.1563636364, 172.255);
}

.table-danger,
.table-danger > th,
.table-danger > td {
    background-color: rgb(253.04, 197.6, 202.92);
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
    border-color: rgb(251.36, 148.4, 158.28);
}

.table-hover .table-danger:hover {
    background-color: rgb(252.1980188679, 172.9419811321, 180.5473584906);
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
    background-color: rgb(252.1980188679, 172.9419811321, 180.5473584906);
}

.table-light,
.table-light > th,
.table-light > td {
    background-color: rgb(251.92, 252.2, 254.44);
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
    border-color: rgb(249.28, 249.8, 253.96);
}

.table-hover .table-light:hover {
    background-color: rgb(230.3430769231, 232.5846153846, 250.5169230769);
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
    background-color: rgb(230.3430769231, 232.5846153846, 250.5169230769);
}

.table-gray,
.table-gray > th,
.table-gray > td {
    background-color: rgb(253.04, 253.32, 255);
}

.table-gray th,
.table-gray td,
.table-gray thead th,
.table-gray tbody + tbody {
    border-color: rgb(251.36, 251.88, 255);
}

.table-hover .table-gray:hover {
    background-color: rgb(227.54, 231.4628571429, 255);
}

.table-hover .table-gray:hover > td,
.table-hover .table-gray:hover > th {
    background-color: rgb(227.54, 231.4628571429, 255);
}

.table-dark,
.table-dark > th,
.table-dark > td {
    background-color: rgb(217.76, 218.04, 225.88);
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: rgb(185.84, 186.36, 200.92);
}

.table-hover .table-dark:hover {
    background-color: rgb(203.4498734177, 203.8374683544, 214.6901265823);
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
    background-color: rgb(203.4498734177, 203.8374683544, 214.6901265823);
}

.table-first,
.table-first > th,
.table-first > td {
    background-color: rgb(196.76, 219.44, 249.96);
}

.table-first th,
.table-first td,
.table-first thead th,
.table-first tbody + tbody {
    border-color: rgb(146.84, 188.96, 245.64);
}

.table-hover .table-first:hover {
    background-color: rgb(173.2909734513, 205.1103539823, 247.9290265487);
}

.table-hover .table-first:hover > td,
.table-hover .table-first:hover > th {
    background-color: rgb(173.2909734513, 205.1103539823, 247.9290265487);
}

.table-second,
.table-second > th,
.table-second > td {
    background-color: rgb(185.56, 186.12, 190.6);
}

.table-second th,
.table-second td,
.table-second thead th,
.table-second tbody + tbody {
    border-color: rgb(126.04, 127.08, 135.4);
}

.table-hover .table-second:hover {
    background-color: rgb(172.329874477, 172.9965690377, 178.330125523);
}

.table-hover .table-second:hover > td,
.table-hover .table-second:hover > th {
    background-color: rgb(172.329874477, 172.9965690377, 178.330125523);
}

.table-active,
.table-active > th,
.table-active > td {
    background-color: rgb(255, 251.1923076923, 241.5);
}

.table-hover .table-active:hover {
    background-color: #fff4d8;
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
    background-color: #fff4d8;
}

.table .thead-dark th {
    color: #fff;
    background-color: #3b3e66;
    border-color: rgb(40.3105590062, 42.3602484472, 69.6894409938);
}

.table .thead-light th {
    color: #3b3e66;
    background-color: rgba(238.6, 239.48, 247.4, 0.7);
    border-color: rgb(219.9375, 222, 240.5625);
}

.table-dark {
    color: #fff;
    background-color: #3b3e66;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
    border-color: rgb(40.3105590062, 42.3602484472, 69.6894409938);
}

.table-dark.table-bordered {
    border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075);
}

@media (min-width: 800px) and (max-width: 1490px) {
    .table-responsive td, .table-responsive th {
        padding: 10px;
    }
}

@media (min-width: 800px) and (max-width: 1360px) {
    .table-responsive td, .table-responsive th {
        padding: 6px;
    }
}

@media (min-width: 800px) and (max-width: 990px) {
    .table-responsive td, .table-responsive th {
        padding: 8px 4px;
    }
}

@media (min-width: 800px) and (max-width: 960px) {
    .table-responsive td, .table-responsive th {
        padding: 6px 2px;
    }
}

@media (min-width: 800px) and (max-width: 900px) {
    .table-responsive td, .table-responsive th {
        padding: 6px 1px;
    }
}

@media (max-width: 599.98px) {
    .table-responsive-sm {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-sm > .table-bordered {
        border: 0;
    }
}

@media (max-width: 959.98px) {
    .table-responsive-md {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-md > .table-bordered {
        border: 0;
    }
}

@media (max-width: 1279.98px) {
    .table-responsive-lg {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-lg > .table-bordered {
        border: 0;
    }
}

@media (max-width: 1919.98px) {
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-xl > .table-bordered {
        border: 0;
    }
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
    border: 0;
}

.fade {
    transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
    .fade {
        transition: none;
    }
}

.fade:not(.show) {
    opacity: 0;
}

.collapse:not(.show) {
    display: none;
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
    .collapsing {
        transition: none;
    }
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(122, 123, 151, 0.3);
    border-radius: 0.65rem;
}

.card > hr {
    margin-right: 0;
    margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.65rem;
    border-top-right-radius: 0.65rem;
}

.card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.65rem;
    border-bottom-left-radius: 0.65rem;
}

.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
}

.card-title {
    margin-bottom: 0.75rem;
}

.card-subtitle {
    margin-top: -0.375rem;
    margin-bottom: 0;
}

.card-text:last-child {
    margin-bottom: 0;
}

.card-link:hover {
    text-decoration: none;
}

.card-link + .card-link {
    margin-left: 1.25rem;
}

.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: #fff;
    border-bottom: 0 solid rgba(122, 123, 151, 0.3);
}

.card-header:first-child {
    border-radius: 0.65rem 0.65rem 0 0;
}

.card-header + .list-group .list-group-item:first-child {
    border-top: 0;
}

.card-footer {
    padding: 0.75rem 1.25rem;
    background-color: #fff;
    border-top: 0 solid rgba(122, 123, 151, 0.3);
}

.card-footer:last-child {
    border-radius: 0 0 0.65rem 0.65rem;
}

.card-header-tabs {
    margin-right: -0.625rem;
    margin-bottom: -0.75rem;
    margin-left: -0.625rem;
    border-bottom: 0;
}

.card-header-pills {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
    flex-shrink: 0;
    width: 100%;
}

.card-img,
.card-img-top {
    border-top-left-radius: 0.65rem;
    border-top-right-radius: 0.65rem;
}

.card-img,
.card-img-bottom {
    border-bottom-right-radius: 0.65rem;
    border-bottom-left-radius: 0.65rem;
}

.card-deck .card {
    margin-bottom: 20px;
}

@media (min-width: 600px) {
    .card-deck {
        display: flex;
        flex-flow: row wrap;
        margin-right: -20px;
        margin-left: -20px;
    }

    .card-deck .card {
        flex: 1 0 0%;
        margin-right: 20px;
        margin-bottom: 0;
        margin-left: 20px;
    }
}

.card-group > .card {
    margin-bottom: 20px;
}

@media (min-width: 600px) {
    .card-group {
        display: flex;
        flex-flow: row wrap;
    }

    .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0;
    }

    .card-group > .card + .card {
        margin-left: 0;
        border-left: 0;
    }

    .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .card-group > .card:not(:last-child) .card-img-top,
    .card-group > .card:not(:last-child) .card-header {
        border-top-right-radius: 0;
    }

    .card-group > .card:not(:last-child) .card-img-bottom,
    .card-group > .card:not(:last-child) .card-footer {
        border-bottom-right-radius: 0;
    }

    .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .card-group > .card:not(:first-child) .card-img-top,
    .card-group > .card:not(:first-child) .card-header {
        border-top-left-radius: 0;
    }

    .card-group > .card:not(:first-child) .card-img-bottom,
    .card-group > .card:not(:first-child) .card-footer {
        border-bottom-left-radius: 0;
    }
}

.card-columns .card {
    margin-bottom: 0.75rem;
}

@media (min-width: 600px) {
    .card-columns {
        column-count: 3;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1;
    }

    .card-columns .card {
        display: inline-block;
        width: 100%;
    }
}

.accordion > .card {
    overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: 0;
}

.badge {
    display: inline-block;
    padding: 0 0.7em;
    font-size: 70%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.2rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .badge {
        transition: none;
    }
}

a.badge:hover, a.badge:focus {
    text-decoration: none;
}

.badge:empty {
    display: none;
}

.btn .badge {
    position: relative;
    top: -1px;
}

.badge-pill {
    padding-right: 0.85em;
    padding-left: 0.85em;
    border-radius: 10rem;
}

.badge-primary {
    color: #fff;
    background-color: #2F80ED;
}

a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: rgb(18.5575221239, 102.0663716814, 214.4424778761);
}

a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(47, 128, 237, 0.5);
}

.badge-secondary {
    color: #3b3e66;
    background-color: #f8f9ff;
}

a.badge-secondary:hover, a.badge-secondary:focus {
    color: #3b3e66;
    background-color: rgb(197, 205.2857142857, 255);
}

a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 255, 0.5);
}

.badge-success {
    color: #fff;
    background-color: #1bc943;
}

a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: rgb(20.9605263158, 156.0394736842, 52.0131578947);
}

a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(27, 201, 67, 0.5);
}

.badge-info {
    color: #fff;
    background-color: #11c5db;
}

a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: rgb(13.3262711864, 154.4279661017, 171.6737288136);
}

a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(17, 197, 219, 0.5);
}

.badge-warning {
    color: #fff;
    background-color: #f4772e;
}

a.badge-warning:hover, a.badge-warning:focus {
    color: #fff;
    background-color: rgb(227.05, 91.2545454545, 11.95);
}

a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(244, 119, 46, 0.5);
}

.badge-danger {
    color: #fff;
    background-color: #f83245;
}

a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: rgb(238.8443396226, 8.1556603774, 30.2924528302);
}

a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 50, 69, 0.5);
}

.badge-light {
    color: #3b3e66;
    background-color: #f4f5fd;
}

a.badge-light:hover, a.badge-light:focus {
    color: #3b3e66;
    background-color: rgb(200.8461538462, 205.7692307692, 245.1538461538);
}

a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(244, 245, 253, 0.5);
}

.badge-gray {
    color: #3b3e66;
    background-color: #f8f9ff;
}

a.badge-gray:hover, a.badge-gray:focus {
    color: #3b3e66;
    background-color: rgb(197, 205.2857142857, 255);
}

a.badge-gray:focus, a.badge-gray.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 255, 0.5);
}

.badge-dark {
    color: #fff;
    background-color: #7a7b97;
}

a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: rgb(97.417721519, 98.3544303797, 124.582278481);
}

a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(122, 123, 151, 0.5);
}

.badge-first {
    color: #fff;
    background-color: #2F80ED;
}

a.badge-first:hover, a.badge-first:focus {
    color: #fff;
    background-color: rgb(18.5575221239, 102.0663716814, 214.4424778761);
}

a.badge-first:focus, a.badge-first.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(47, 128, 237, 0.5);
}

.badge-second {
    color: #fff;
    background-color: #070919;
}

a.badge-second:hover, a.badge-second:focus {
    color: #fff;
    background-color: black;
}

a.badge-second:focus, a.badge-second.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(7, 9, 25, 0.5);
}

@keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0;
    }
    to {
        background-position: 0 0;
    }
}

.progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: 0.7125rem;
    background-color: #f4f5fd;
    border-radius: 0.2rem;
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #2F80ED;
    transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
    .progress-bar {
        transition: none;
    }
}

.progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem;
}

.progress-bar-animated {
    animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
        animation: none;
    }
}

.list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
}

.list-group-item-action {
    width: 100%;
    color: #7a7b97;
    text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #070919;
    text-decoration: none;
    background-color: #f4f5fd;
}

.list-group-item-action:active {
    color: black;
    background-color: #eeeff8;
}

.list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    background-color: #fff;
    border: 1px solid rgba(7, 9, 25, 0.125);
}

.list-group-item:first-child {
    border-top-left-radius: 0.65rem;
    border-top-right-radius: 0.65rem;
}

.list-group-item:last-child {
    border-bottom-right-radius: 0.65rem;
    border-bottom-left-radius: 0.65rem;
}

.list-group-item.disabled, .list-group-item:disabled {
    color: #d1d2db;
    pointer-events: none;
    background-color: #fff;
}

.list-group-item.active {
    z-index: 2;
    color: black;
    background-color: #eeeff8;
    border-color: #eeeff8;
}

.list-group-item + .list-group-item {
    border-top-width: 0;
}

.list-group-item + .list-group-item.active {
    margin-top: -1px;
    border-top-width: 1px;
}

.list-group-horizontal {
    flex-direction: row;
}

.list-group-horizontal .list-group-item:first-child {
    border-bottom-left-radius: 0.65rem;
    border-top-right-radius: 0;
}

.list-group-horizontal .list-group-item:last-child {
    border-top-right-radius: 0.65rem;
    border-bottom-left-radius: 0;
}

.list-group-horizontal .list-group-item.active {
    margin-top: 0;
}

.list-group-horizontal .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
}

.list-group-horizontal .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
}

@media (min-width: 600px) {
    .list-group-horizontal-sm {
        flex-direction: row;
    }

    .list-group-horizontal-sm .list-group-item:first-child {
        border-bottom-left-radius: 0.65rem;
        border-top-right-radius: 0;
    }

    .list-group-horizontal-sm .list-group-item:last-child {
        border-top-right-radius: 0.65rem;
        border-bottom-left-radius: 0;
    }

    .list-group-horizontal-sm .list-group-item.active {
        margin-top: 0;
    }

    .list-group-horizontal-sm .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }

    .list-group-horizontal-sm .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

@media (min-width: 960px) {
    .list-group-horizontal-md {
        flex-direction: row;
    }

    .list-group-horizontal-md .list-group-item:first-child {
        border-bottom-left-radius: 0.65rem;
        border-top-right-radius: 0;
    }

    .list-group-horizontal-md .list-group-item:last-child {
        border-top-right-radius: 0.65rem;
        border-bottom-left-radius: 0;
    }

    .list-group-horizontal-md .list-group-item.active {
        margin-top: 0;
    }

    .list-group-horizontal-md .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }

    .list-group-horizontal-md .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

@media (min-width: 1280px) {
    .list-group-horizontal-lg {
        flex-direction: row;
    }

    .list-group-horizontal-lg .list-group-item:first-child {
        border-bottom-left-radius: 0.65rem;
        border-top-right-radius: 0;
    }

    .list-group-horizontal-lg .list-group-item:last-child {
        border-top-right-radius: 0.65rem;
        border-bottom-left-radius: 0;
    }

    .list-group-horizontal-lg .list-group-item.active {
        margin-top: 0;
    }

    .list-group-horizontal-lg .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }

    .list-group-horizontal-lg .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

@media (min-width: 1920px) {
    .list-group-horizontal-xl {
        flex-direction: row;
    }

    .list-group-horizontal-xl .list-group-item:first-child {
        border-bottom-left-radius: 0.65rem;
        border-top-right-radius: 0;
    }

    .list-group-horizontal-xl .list-group-item:last-child {
        border-top-right-radius: 0.65rem;
        border-bottom-left-radius: 0;
    }

    .list-group-horizontal-xl .list-group-item.active {
        margin-top: 0;
    }

    .list-group-horizontal-xl .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }

    .list-group-horizontal-xl .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

.list-group-flush .list-group-item {
    border-right-width: 0;
    border-left-width: 0;
    border-radius: 0;
}

.list-group-flush .list-group-item:first-child {
    border-top-width: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
    border-bottom-width: 0;
}

.list-group-item-primary {
    color: rgb(27.8, 70.88, 135.24);
    background-color: rgb(196.76, 219.44, 249.96);
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: rgb(27.8, 70.88, 135.24);
    background-color: rgb(173.2909734513, 205.1103539823, 247.9290265487);
}

.list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: rgb(27.8, 70.88, 135.24);
    border-color: rgb(27.8, 70.88, 135.24);
}

.list-group-item-secondary {
    color: rgb(132.32, 133.8, 144.6);
    background-color: rgb(253.04, 253.32, 255);
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: rgb(132.32, 133.8, 144.6);
    background-color: rgb(227.54, 231.4628571429, 255);
}

.list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: rgb(132.32, 133.8, 144.6);
    border-color: rgb(132.32, 133.8, 144.6);
}

.list-group-item-success {
    color: rgb(17.4, 108.84, 46.84);
    background-color: rgb(191.16, 239.88, 202.36);
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: rgb(17.4, 108.84, 46.84);
    background-color: rgb(170.5429787234, 234.9970212766, 185.36);
}

.list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: rgb(17.4, 108.84, 46.84);
    border-color: rgb(17.4, 108.84, 46.84);
}

.list-group-item-info {
    color: rgb(12.2, 106.76, 125.88);
    background-color: rgb(188.36, 238.76, 244.92);
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: rgb(12.2, 106.76, 125.88);
    background-color: rgb(166.2103649635, 233.362189781, 241.5696350365);
}

.list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: rgb(12.2, 106.76, 125.88);
    border-color: rgb(12.2, 106.76, 125.88);
}

.list-group-item-warning {
    color: rgb(130.24, 66.2, 35.92);
    background-color: rgb(251.92, 216.92, 196.48);
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: rgb(130.24, 66.2, 35.92);
    background-color: rgb(250.645, 201.1563636364, 172.255);
}

.list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: rgb(130.24, 66.2, 35.92);
    border-color: rgb(130.24, 66.2, 35.92);
}

.list-group-item-danger {
    color: rgb(132.32, 30.32, 47.88);
    background-color: rgb(253.04, 197.6, 202.92);
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: rgb(132.32, 30.32, 47.88);
    background-color: rgb(252.1980188679, 172.9419811321, 180.5473584906);
}

.list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: rgb(132.32, 30.32, 47.88);
    border-color: rgb(132.32, 30.32, 47.88);
}

.list-group-item-light {
    color: rgb(130.24, 131.72, 143.56);
    background-color: rgb(251.92, 252.2, 254.44);
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: rgb(130.24, 131.72, 143.56);
    background-color: rgb(230.3430769231, 232.5846153846, 250.5169230769);
}

.list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: rgb(130.24, 131.72, 143.56);
    border-color: rgb(130.24, 131.72, 143.56);
}

.list-group-item-gray {
    color: rgb(132.32, 133.8, 144.6);
    background-color: rgb(253.04, 253.32, 255);
}

.list-group-item-gray.list-group-item-action:hover, .list-group-item-gray.list-group-item-action:focus {
    color: rgb(132.32, 133.8, 144.6);
    background-color: rgb(227.54, 231.4628571429, 255);
}

.list-group-item-gray.list-group-item-action.active {
    color: #fff;
    background-color: rgb(132.32, 133.8, 144.6);
    border-color: rgb(132.32, 133.8, 144.6);
}

.list-group-item-dark {
    color: rgb(66.8, 68.28, 90.52);
    background-color: rgb(217.76, 218.04, 225.88);
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: rgb(66.8, 68.28, 90.52);
    background-color: rgb(203.4498734177, 203.8374683544, 214.6901265823);
}

.list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: rgb(66.8, 68.28, 90.52);
    border-color: rgb(66.8, 68.28, 90.52);
}

.list-group-item-first {
    color: rgb(27.8, 70.88, 135.24);
    background-color: rgb(196.76, 219.44, 249.96);
}

.list-group-item-first.list-group-item-action:hover, .list-group-item-first.list-group-item-action:focus {
    color: rgb(27.8, 70.88, 135.24);
    background-color: rgb(173.2909734513, 205.1103539823, 247.9290265487);
}

.list-group-item-first.list-group-item-action.active {
    color: #fff;
    background-color: rgb(27.8, 70.88, 135.24);
    border-color: rgb(27.8, 70.88, 135.24);
}

.list-group-item-second {
    color: #070919;
    background-color: rgb(185.56, 186.12, 190.6);
}

.list-group-item-second.list-group-item-action:hover, .list-group-item-second.list-group-item-action:focus {
    color: #070919;
    background-color: rgb(172.329874477, 172.9965690377, 178.330125523);
}

.list-group-item-second.list-group-item-action.active {
    color: #fff;
    background-color: #070919;
    border-color: #070919;
}

.align-baseline {
    vertical-align: baseline !important;
}

.align-top {
    vertical-align: top !important;
}

.align-middle {
    vertical-align: middle !important;
}

.align-bottom {
    vertical-align: bottom !important;
}

.align-text-bottom {
    vertical-align: text-bottom !important;
}

.align-text-top {
    vertical-align: text-top !important;
}

.bg-primary {
    background-color: #2F80ED !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
    background-color: rgb(18.5575221239, 102.0663716814, 214.4424778761) !important;
}

.bg-secondary {
    background-color: #f8f9ff !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
    background-color: rgb(197, 205.2857142857, 255) !important;
}

.bg-success {
    background-color: #1bc943 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
    background-color: rgb(20.9605263158, 156.0394736842, 52.0131578947) !important;
}

.bg-info {
    background-color: #11c5db !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
    background-color: rgb(13.3262711864, 154.4279661017, 171.6737288136) !important;
}

.bg-warning {
    background-color: #f4772e !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
    background-color: rgb(227.05, 91.2545454545, 11.95) !important;
}

.bg-danger {
    background-color: #f83245 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
    background-color: rgb(238.8443396226, 8.1556603774, 30.2924528302) !important;
}

.bg-light {
    background-color: #f4f5fd !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
    background-color: rgb(200.8461538462, 205.7692307692, 245.1538461538) !important;
}

.bg-gray {
    background-color: #f8f9ff !important;
}

a.bg-gray:hover, a.bg-gray:focus,
button.bg-gray:hover,
button.bg-gray:focus {
    background-color: rgb(197, 205.2857142857, 255) !important;
}

.bg-dark {
    background-color: #7a7b97 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
    background-color: rgb(97.417721519, 98.3544303797, 124.582278481) !important;
}

.bg-first {
    background-color: #2F80ED !important;
}

a.bg-first:hover, a.bg-first:focus,
button.bg-first:hover,
button.bg-first:focus {
    background-color: rgb(18.5575221239, 102.0663716814, 214.4424778761) !important;
}

.bg-second {
    background-color: #070919 !important;
}

a.bg-second:hover, a.bg-second:focus,
button.bg-second:hover,
button.bg-second:focus {
    background-color: black !important;
}

.bg-white {
    background-color: #fff !important;
}

.bg-transparent {
    background-color: transparent !important;
}

.border {
    border: 1px solid #eeeff8 !important;
}

.border-top {
    border-top: 1px solid #eeeff8 !important;
}

.border-right {
    border-right: 1px solid #eeeff8 !important;
}

.border-bottom {
    border-bottom: 1px solid #eeeff8 !important;
}

.border-left {
    border-left: 1px solid #eeeff8 !important;
}

.border-0 {
    border: 0 !important;
}

.border-top-0 {
    border-top: 0 !important;
}

.border-right-0 {
    border-right: 0 !important;
}

.border-bottom-0 {
    border-bottom: 0 !important;
}

.border-left-0 {
    border-left: 0 !important;
}

.border-primary {
    border-color: #2F80ED !important;
}

.border-secondary {
    border-color: #f8f9ff !important;
}

.border-success {
    border-color: #1bc943 !important;
}

.border-info {
    border-color: #11c5db !important;
}

.border-warning {
    border-color: #f4772e !important;
}

.border-danger {
    border-color: #f83245 !important;
}

.border-light {
    border-color: #f4f5fd !important;
}

.border-gray {
    border-color: #f8f9ff !important;
}

.border-dark {
    border-color: #7a7b97 !important;
}

.border-first {
    border-color: #2F80ED !important;
}

.border-second {
    border-color: #070919 !important;
}

.border-white {
    border-color: #fff !important;
}

.rounded-sm {
    border-radius: 0.29rem !important;
}

.rounded {
    border-radius: 0.65rem !important;
}

.rounded-top {
    border-top-left-radius: 0.65rem !important;
    border-top-right-radius: 0.65rem !important;
}

.rounded-right {
    border-top-right-radius: 0.65rem !important;
    border-bottom-right-radius: 0.65rem !important;
}

.rounded-bottom {
    border-bottom-right-radius: 0.65rem !important;
    border-bottom-left-radius: 0.65rem !important;
}

.rounded-left {
    border-top-left-radius: 0.65rem !important;
    border-bottom-left-radius: 0.65rem !important;
}

.rounded-lg {
    border-radius: 0.75rem !important;
}

.rounded-circle {
    border-radius: 50% !important;
}

.rounded-pill {
    border-radius: 50rem !important;
}

.rounded-0 {
    border-radius: 0 !important;
}

.clearfix::after {
    display: block;
    clear: both;
    content: "";
}

.d-none {
    display: none !important;
}

.d-inline {
    display: inline !important;
}

.d-inline-block {
    display: inline-block !important;
}

.d-block {
    display: block !important;
}

.d-table {
    display: table !important;
}

.d-table-row {
    display: table-row !important;
}

.d-table-cell {
    display: table-cell !important;
}

.d-flex {
    display: flex !important;
}

.d-inline-flex {
    display: inline-flex !important;
}

@media (min-width: 600px) {
    .d-sm-none {
        display: none !important;
    }

    .d-sm-inline {
        display: inline !important;
    }

    .d-sm-inline-block {
        display: inline-block !important;
    }

    .d-sm-block {
        display: block !important;
    }

    .d-sm-table {
        display: table !important;
    }

    .d-sm-table-row {
        display: table-row !important;
    }

    .d-sm-table-cell {
        display: table-cell !important;
    }

    .d-sm-flex {
        display: flex !important;
    }

    .d-sm-inline-flex {
        display: inline-flex !important;
    }
}

@media (min-width: 960px) {
    .d-md-none {
        display: none !important;
    }

    .d-md-inline {
        display: inline !important;
    }

    .d-md-inline-block {
        display: inline-block !important;
    }

    .d-md-block {
        display: block !important;
    }

    .d-md-table {
        display: table !important;
    }

    .d-md-table-row {
        display: table-row !important;
    }

    .d-md-table-cell {
        display: table-cell !important;
    }

    .d-md-flex {
        display: flex !important;
    }

    .d-md-inline-flex {
        display: inline-flex !important;
    }
}

@media (min-width: 1280px) {
    .d-lg-none {
        display: none !important;
    }

    .d-lg-inline {
        display: inline !important;
    }

    .d-lg-inline-block {
        display: inline-block !important;
    }

    .d-lg-block {
        display: block !important;
    }

    .d-lg-table {
        display: table !important;
    }

    .d-lg-table-row {
        display: table-row !important;
    }

    .d-lg-table-cell {
        display: table-cell !important;
    }

    .d-lg-flex {
        display: flex !important;
    }

    .d-lg-inline-flex {
        display: inline-flex !important;
    }
}

@media (min-width: 1920px) {
    .d-xl-none {
        display: none !important;
    }

    .d-xl-inline {
        display: inline !important;
    }

    .d-xl-inline-block {
        display: inline-block !important;
    }

    .d-xl-block {
        display: block !important;
    }

    .d-xl-table {
        display: table !important;
    }

    .d-xl-table-row {
        display: table-row !important;
    }

    .d-xl-table-cell {
        display: table-cell !important;
    }

    .d-xl-flex {
        display: flex !important;
    }

    .d-xl-inline-flex {
        display: inline-flex !important;
    }
}

@media print {
    .d-print-none {
        display: none !important;
    }

    .d-print-inline {
        display: inline !important;
    }

    .d-print-inline-block {
        display: inline-block !important;
    }

    .d-print-block {
        display: block !important;
    }

    .d-print-table {
        display: table !important;
    }

    .d-print-table-row {
        display: table-row !important;
    }

    .d-print-table-cell {
        display: table-cell !important;
    }

    .d-print-flex {
        display: flex !important;
    }

    .d-print-inline-flex {
        display: inline-flex !important;
    }
}

.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
}

.embed-responsive::before {
    display: block;
    content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.embed-responsive-21by9::before {
    padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
    padding-top: 56.25%;
}

.embed-responsive-4by3::before {
    padding-top: 75%;
}

.embed-responsive-1by1::before {
    padding-top: 100%;
}

.flex-row {
    flex-direction: row !important;
}

.flex-column {
    flex-direction: column !important;
}

.flex-row-reverse {
    flex-direction: row-reverse !important;
}

.flex-column-reverse {
    flex-direction: column-reverse !important;
}

.flex-wrap {
    flex-wrap: wrap !important;
}

.flex-nowrap {
    flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
}

.flex-fill {
    flex: 1 1 auto !important;
}

.flex-grow-0 {
    flex-grow: 0 !important;
}

.flex-grow-1 {
    flex-grow: 1 !important;
}

.flex-shrink-0 {
    flex-shrink: 0 !important;
}

.flex-shrink-1 {
    flex-shrink: 1 !important;
}

.justify-content-start {
    justify-content: flex-start !important;
}

.justify-content-end {
    justify-content: flex-end !important;
}

.justify-content-center {
    justify-content: center !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.justify-content-around {
    justify-content: space-around !important;
}

.align-items-start {
    align-items: flex-start !important;
}

.align-items-end {
    align-items: flex-end !important;
}

.align-items-center {
    align-items: center !important;
}

.align-items-baseline {
    align-items: baseline !important;
}

.align-items-stretch {
    align-items: stretch !important;
}

.align-content-start {
    align-content: flex-start !important;
}

.align-content-end {
    align-content: flex-end !important;
}

.align-content-center {
    align-content: center !important;
}

.align-content-between {
    align-content: space-between !important;
}

.align-content-around {
    align-content: space-around !important;
}

.align-content-stretch {
    align-content: stretch !important;
}

.align-self-auto {
    align-self: auto !important;
}

.align-self-start {
    align-self: flex-start !important;
}

.align-self-end {
    align-self: flex-end !important;
}

.align-self-center {
    align-self: center !important;
}

.align-self-baseline {
    align-self: baseline !important;
}

.align-self-stretch {
    align-self: stretch !important;
}

@media (min-width: 600px) {
    .flex-sm-row {
        flex-direction: row !important;
    }

    .flex-sm-column {
        flex-direction: column !important;
    }

    .flex-sm-row-reverse {
        flex-direction: row-reverse !important;
    }

    .flex-sm-column-reverse {
        flex-direction: column-reverse !important;
    }

    .flex-sm-wrap {
        flex-wrap: wrap !important;
    }

    .flex-sm-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .flex-sm-fill {
        flex: 1 1 auto !important;
    }

    .flex-sm-grow-0 {
        flex-grow: 0 !important;
    }

    .flex-sm-grow-1 {
        flex-grow: 1 !important;
    }

    .flex-sm-shrink-0 {
        flex-shrink: 0 !important;
    }

    .flex-sm-shrink-1 {
        flex-shrink: 1 !important;
    }

    .justify-content-sm-start {
        justify-content: flex-start !important;
    }

    .justify-content-sm-end {
        justify-content: flex-end !important;
    }

    .justify-content-sm-center {
        justify-content: center !important;
    }

    .justify-content-sm-between {
        justify-content: space-between !important;
    }

    .justify-content-sm-around {
        justify-content: space-around !important;
    }

    .align-items-sm-start {
        align-items: flex-start !important;
    }

    .align-items-sm-end {
        align-items: flex-end !important;
    }

    .align-items-sm-center {
        align-items: center !important;
    }

    .align-items-sm-baseline {
        align-items: baseline !important;
    }

    .align-items-sm-stretch {
        align-items: stretch !important;
    }

    .align-content-sm-start {
        align-content: flex-start !important;
    }

    .align-content-sm-end {
        align-content: flex-end !important;
    }

    .align-content-sm-center {
        align-content: center !important;
    }

    .align-content-sm-between {
        align-content: space-between !important;
    }

    .align-content-sm-around {
        align-content: space-around !important;
    }

    .align-content-sm-stretch {
        align-content: stretch !important;
    }

    .align-self-sm-auto {
        align-self: auto !important;
    }

    .align-self-sm-start {
        align-self: flex-start !important;
    }

    .align-self-sm-end {
        align-self: flex-end !important;
    }

    .align-self-sm-center {
        align-self: center !important;
    }

    .align-self-sm-baseline {
        align-self: baseline !important;
    }

    .align-self-sm-stretch {
        align-self: stretch !important;
    }
}

@media (min-width: 960px) {
    .flex-md-row {
        flex-direction: row !important;
    }

    .flex-md-column {
        flex-direction: column !important;
    }

    .flex-md-row-reverse {
        flex-direction: row-reverse !important;
    }

    .flex-md-column-reverse {
        flex-direction: column-reverse !important;
    }

    .flex-md-wrap {
        flex-wrap: wrap !important;
    }

    .flex-md-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .flex-md-fill {
        flex: 1 1 auto !important;
    }

    .flex-md-grow-0 {
        flex-grow: 0 !important;
    }

    .flex-md-grow-1 {
        flex-grow: 1 !important;
    }

    .flex-md-shrink-0 {
        flex-shrink: 0 !important;
    }

    .flex-md-shrink-1 {
        flex-shrink: 1 !important;
    }

    .justify-content-md-start {
        justify-content: flex-start !important;
    }

    .justify-content-md-end {
        justify-content: flex-end !important;
    }

    .justify-content-md-center {
        justify-content: center !important;
    }

    .justify-content-md-between {
        justify-content: space-between !important;
    }

    .justify-content-md-around {
        justify-content: space-around !important;
    }

    .align-items-md-start {
        align-items: flex-start !important;
    }

    .align-items-md-end {
        align-items: flex-end !important;
    }

    .align-items-md-center {
        align-items: center !important;
    }

    .align-items-md-baseline {
        align-items: baseline !important;
    }

    .align-items-md-stretch {
        align-items: stretch !important;
    }

    .align-content-md-start {
        align-content: flex-start !important;
    }

    .align-content-md-end {
        align-content: flex-end !important;
    }

    .align-content-md-center {
        align-content: center !important;
    }

    .align-content-md-between {
        align-content: space-between !important;
    }

    .align-content-md-around {
        align-content: space-around !important;
    }

    .align-content-md-stretch {
        align-content: stretch !important;
    }

    .align-self-md-auto {
        align-self: auto !important;
    }

    .align-self-md-start {
        align-self: flex-start !important;
    }

    .align-self-md-end {
        align-self: flex-end !important;
    }

    .align-self-md-center {
        align-self: center !important;
    }

    .align-self-md-baseline {
        align-self: baseline !important;
    }

    .align-self-md-stretch {
        align-self: stretch !important;
    }
}

@media (min-width: 1280px) {
    .flex-lg-row {
        flex-direction: row !important;
    }

    .flex-lg-column {
        flex-direction: column !important;
    }

    .flex-lg-row-reverse {
        flex-direction: row-reverse !important;
    }

    .flex-lg-column-reverse {
        flex-direction: column-reverse !important;
    }

    .flex-lg-wrap {
        flex-wrap: wrap !important;
    }

    .flex-lg-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .flex-lg-fill {
        flex: 1 1 auto !important;
    }

    .flex-lg-grow-0 {
        flex-grow: 0 !important;
    }

    .flex-lg-grow-1 {
        flex-grow: 1 !important;
    }

    .flex-lg-shrink-0 {
        flex-shrink: 0 !important;
    }

    .flex-lg-shrink-1 {
        flex-shrink: 1 !important;
    }

    .justify-content-lg-start {
        justify-content: flex-start !important;
    }

    .justify-content-lg-end {
        justify-content: flex-end !important;
    }

    .justify-content-lg-center {
        justify-content: center !important;
    }

    .justify-content-lg-between {
        justify-content: space-between !important;
    }

    .justify-content-lg-around {
        justify-content: space-around !important;
    }

    .align-items-lg-start {
        align-items: flex-start !important;
    }

    .align-items-lg-end {
        align-items: flex-end !important;
    }

    .align-items-lg-center {
        align-items: center !important;
    }

    .align-items-lg-baseline {
        align-items: baseline !important;
    }

    .align-items-lg-stretch {
        align-items: stretch !important;
    }

    .align-content-lg-start {
        align-content: flex-start !important;
    }

    .align-content-lg-end {
        align-content: flex-end !important;
    }

    .align-content-lg-center {
        align-content: center !important;
    }

    .align-content-lg-between {
        align-content: space-between !important;
    }

    .align-content-lg-around {
        align-content: space-around !important;
    }

    .align-content-lg-stretch {
        align-content: stretch !important;
    }

    .align-self-lg-auto {
        align-self: auto !important;
    }

    .align-self-lg-start {
        align-self: flex-start !important;
    }

    .align-self-lg-end {
        align-self: flex-end !important;
    }

    .align-self-lg-center {
        align-self: center !important;
    }

    .align-self-lg-baseline {
        align-self: baseline !important;
    }

    .align-self-lg-stretch {
        align-self: stretch !important;
    }
}

@media (min-width: 1920px) {
    .flex-xl-row {
        flex-direction: row !important;
    }

    .flex-xl-column {
        flex-direction: column !important;
    }

    .flex-xl-row-reverse {
        flex-direction: row-reverse !important;
    }

    .flex-xl-column-reverse {
        flex-direction: column-reverse !important;
    }

    .flex-xl-wrap {
        flex-wrap: wrap !important;
    }

    .flex-xl-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .flex-xl-fill {
        flex: 1 1 auto !important;
    }

    .flex-xl-grow-0 {
        flex-grow: 0 !important;
    }

    .flex-xl-grow-1 {
        flex-grow: 1 !important;
    }

    .flex-xl-shrink-0 {
        flex-shrink: 0 !important;
    }

    .flex-xl-shrink-1 {
        flex-shrink: 1 !important;
    }

    .justify-content-xl-start {
        justify-content: flex-start !important;
    }

    .justify-content-xl-end {
        justify-content: flex-end !important;
    }

    .justify-content-xl-center {
        justify-content: center !important;
    }

    .justify-content-xl-between {
        justify-content: space-between !important;
    }

    .justify-content-xl-around {
        justify-content: space-around !important;
    }

    .align-items-xl-start {
        align-items: flex-start !important;
    }

    .align-items-xl-end {
        align-items: flex-end !important;
    }

    .align-items-xl-center {
        align-items: center !important;
    }

    .align-items-xl-baseline {
        align-items: baseline !important;
    }

    .align-items-xl-stretch {
        align-items: stretch !important;
    }

    .align-content-xl-start {
        align-content: flex-start !important;
    }

    .align-content-xl-end {
        align-content: flex-end !important;
    }

    .align-content-xl-center {
        align-content: center !important;
    }

    .align-content-xl-between {
        align-content: space-between !important;
    }

    .align-content-xl-around {
        align-content: space-around !important;
    }

    .align-content-xl-stretch {
        align-content: stretch !important;
    }

    .align-self-xl-auto {
        align-self: auto !important;
    }

    .align-self-xl-start {
        align-self: flex-start !important;
    }

    .align-self-xl-end {
        align-self: flex-end !important;
    }

    .align-self-xl-center {
        align-self: center !important;
    }

    .align-self-xl-baseline {
        align-self: baseline !important;
    }

    .align-self-xl-stretch {
        align-self: stretch !important;
    }
}

.float-left {
    float: left !important;
}

.float-right {
    float: right !important;
}

.float-none {
    float: none !important;
}

@media (min-width: 600px) {
    .float-sm-left {
        float: left !important;
    }

    .float-sm-right {
        float: right !important;
    }

    .float-sm-none {
        float: none !important;
    }
}

@media (min-width: 960px) {
    .float-md-left {
        float: left !important;
    }

    .float-md-right {
        float: right !important;
    }

    .float-md-none {
        float: none !important;
    }
}

@media (min-width: 1280px) {
    .float-lg-left {
        float: left !important;
    }

    .float-lg-right {
        float: right !important;
    }

    .float-lg-none {
        float: none !important;
    }
}

@media (min-width: 1920px) {
    .float-xl-left {
        float: left !important;
    }

    .float-xl-right {
        float: right !important;
    }

    .float-xl-none {
        float: none !important;
    }
}

.overflow-auto {
    overflow: auto !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

.position-static {
    position: static !important;
}

.position-relative {
    position: relative !important;
}

.position-absolute {
    position: absolute !important;
}

.position-fixed {
    position: fixed !important;
}

.position-sticky {
    position: sticky !important;
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1330;
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1330;
}

@supports (position: sticky) {
    .sticky-top {
        position: sticky;
        top: 0;
        z-index: 1320;
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
}

.shadow-sm {
    box-shadow: 0 0.313rem 0.8rem rgba(122, 123, 151, 0.5), 0 0.126rem 0.225rem rgba(122, 123, 151, 0.3) !important;
}

.shadow {
    box-shadow: 0 0.5rem 1rem rgba(7, 9, 25, 0.55) !important;
}

.shadow-lg {
    box-shadow: 0 1rem 3rem rgba(7, 9, 25, 0.575) !important;
}

.shadow-none {
    box-shadow: none !important;
}

.w-25 {
    width: 25% !important;
}

.w-50 {
    width: 50% !important;
}

.w-75 {
    width: 75% !important;
}

.w-100 {
    width: 100% !important;
}

.w-auto {
    width: auto !important;
}

.h-25 {
    height: 25% !important;
}

.h-50 {
    height: 50% !important;
}

.h-75 {
    height: 75% !important;
}

.h-100 {
    height: 100% !important;
}

.h-auto {
    height: auto !important;
}

.mw-100 {
    max-width: 100% !important;
}

.mh-100 {
    max-height: 100% !important;
}

.min-vw-100 {
    min-width: 100vw !important;
}

.min-vh-100 {
    min-height: 100vh !important;
}

.vw-100 {
    width: 100vw !important;
}

.vh-100 {
    height: 100vh !important;
}

.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0, 0, 0, 0);
}

.m-0 {
    margin: 0 !important;
}

.mt-0,
.my-0 {
    margin-top: 0 !important;
}

.mr-0,
.mx-0 {
    margin-right: 0 !important;
}

.mb-0,
.my-0 {
    margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
    margin-left: 0 !important;
}

.m-1 {
    margin: 0.25rem !important;
}

.mt-1,
.my-1 {
    margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
    margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
    margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
    margin-left: 0.25rem !important;
}

.m-2 {
    margin: 0.5rem !important;
}

.mt-2,
.my-2 {
    margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
    margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
    margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
    margin-left: 0.5rem !important;
}

.m-3 {
    margin: 1rem !important;
}

.mt-3,
.my-3 {
    margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
    margin-right: 1rem !important;
}

.mb-3,
.my-3 {
    margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
    margin-left: 1rem !important;
}

.m-4 {
    margin: 1.5rem !important;
}

.mt-4,
.my-4 {
    margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
    margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
    margin-left: 1.5rem !important;
}

.m-5 {
    margin: 3rem !important;
}

.mt-5,
.my-5 {
    margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
    margin-right: 3rem !important;
}

.mb-5,
.my-5 {
    margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
    margin-left: 3rem !important;
}

.p-0 {
    padding: 0 !important;
}

.pt-0,
.py-0 {
    padding-top: 0 !important;
}

.pr-0,
.px-0 {
    padding-right: 0 !important;
}

.pb-0,
.py-0 {
    padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
    padding-left: 0 !important;
}

.p-1 {
    padding: 0.25rem !important;
}

.pt-1,
.py-1 {
    padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
    padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
    padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
    padding-left: 0.25rem !important;
}

.p-2 {
    padding: 0.5rem !important;
}

.pt-2,
.py-2 {
    padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
    padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
    padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
    padding-left: 0.5rem !important;
}

.p-3 {
    padding: 1rem !important;
}

.pt-3,
.py-3 {
    padding-top: 1rem !important;
}

.pr-3,
.px-3 {
    padding-right: 1rem !important;
}

.pb-3,
.py-3 {
    padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
    padding-left: 1rem !important;
}

.p-4 {
    padding: 1.5rem !important;
}

.pt-4,
.py-4 {
    padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
    padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
    padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
    padding-left: 1.5rem !important;
}

.p-5 {
    padding: 3rem !important;
}

.pt-5,
.py-5 {
    padding-top: 3rem !important;
}

.pr-5,
.px-5 {
    padding-right: 3rem !important;
}

.pb-5,
.py-5 {
    padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
    padding-left: 3rem !important;
}

.m-n1 {
    margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
    margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
    margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
    margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
    margin-left: -0.25rem !important;
}

.m-n2 {
    margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
    margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
    margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
    margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
    margin-left: -0.5rem !important;
}

.m-n3 {
    margin: -1rem !important;
}

.mt-n3,
.my-n3 {
    margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
    margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
    margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
    margin-left: -1rem !important;
}

.m-n4 {
    margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
    margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
    margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
    margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
    margin-left: -1.5rem !important;
}

.m-n5 {
    margin: -3rem !important;
}

.mt-n5,
.my-n5 {
    margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
    margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
    margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
    margin-left: -3rem !important;
}

.m-auto {
    margin: auto !important;
}

.mt-auto,
.my-auto {
    margin-top: auto !important;
}

.mr-auto,
.mx-auto {
    margin-right: auto !important;
}

.mb-auto,
.my-auto {
    margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
    margin-left: auto !important;
}

@media (min-width: 600px) {
    .m-sm-0 {
        margin: 0 !important;
    }

    .mt-sm-0,
    .my-sm-0 {
        margin-top: 0 !important;
    }

    .mr-sm-0,
    .mx-sm-0 {
        margin-right: 0 !important;
    }

    .mb-sm-0,
    .my-sm-0 {
        margin-bottom: 0 !important;
    }

    .ml-sm-0,
    .mx-sm-0 {
        margin-left: 0 !important;
    }

    .m-sm-1 {
        margin: 0.25rem !important;
    }

    .mt-sm-1,
    .my-sm-1 {
        margin-top: 0.25rem !important;
    }

    .mr-sm-1,
    .mx-sm-1 {
        margin-right: 0.25rem !important;
    }

    .mb-sm-1,
    .my-sm-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-sm-1,
    .mx-sm-1 {
        margin-left: 0.25rem !important;
    }

    .m-sm-2 {
        margin: 0.5rem !important;
    }

    .mt-sm-2,
    .my-sm-2 {
        margin-top: 0.5rem !important;
    }

    .mr-sm-2,
    .mx-sm-2 {
        margin-right: 0.5rem !important;
    }

    .mb-sm-2,
    .my-sm-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-sm-2,
    .mx-sm-2 {
        margin-left: 0.5rem !important;
    }

    .m-sm-3 {
        margin: 1rem !important;
    }

    .mt-sm-3,
    .my-sm-3 {
        margin-top: 1rem !important;
    }

    .mr-sm-3,
    .mx-sm-3 {
        margin-right: 1rem !important;
    }

    .mb-sm-3,
    .my-sm-3 {
        margin-bottom: 1rem !important;
    }

    .ml-sm-3,
    .mx-sm-3 {
        margin-left: 1rem !important;
    }

    .m-sm-4 {
        margin: 1.5rem !important;
    }

    .mt-sm-4,
    .my-sm-4 {
        margin-top: 1.5rem !important;
    }

    .mr-sm-4,
    .mx-sm-4 {
        margin-right: 1.5rem !important;
    }

    .mb-sm-4,
    .my-sm-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-sm-4,
    .mx-sm-4 {
        margin-left: 1.5rem !important;
    }

    .m-sm-5 {
        margin: 3rem !important;
    }

    .mt-sm-5,
    .my-sm-5 {
        margin-top: 3rem !important;
    }

    .mr-sm-5,
    .mx-sm-5 {
        margin-right: 3rem !important;
    }

    .mb-sm-5,
    .my-sm-5 {
        margin-bottom: 3rem !important;
    }

    .ml-sm-5,
    .mx-sm-5 {
        margin-left: 3rem !important;
    }

    .p-sm-0 {
        padding: 0 !important;
    }

    .pt-sm-0,
    .py-sm-0 {
        padding-top: 0 !important;
    }

    .pr-sm-0,
    .px-sm-0 {
        padding-right: 0 !important;
    }

    .pb-sm-0,
    .py-sm-0 {
        padding-bottom: 0 !important;
    }

    .pl-sm-0,
    .px-sm-0 {
        padding-left: 0 !important;
    }

    .p-sm-1 {
        padding: 0.25rem !important;
    }

    .pt-sm-1,
    .py-sm-1 {
        padding-top: 0.25rem !important;
    }

    .pr-sm-1,
    .px-sm-1 {
        padding-right: 0.25rem !important;
    }

    .pb-sm-1,
    .py-sm-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-sm-1,
    .px-sm-1 {
        padding-left: 0.25rem !important;
    }

    .p-sm-2 {
        padding: 0.5rem !important;
    }

    .pt-sm-2,
    .py-sm-2 {
        padding-top: 0.5rem !important;
    }

    .pr-sm-2,
    .px-sm-2 {
        padding-right: 0.5rem !important;
    }

    .pb-sm-2,
    .py-sm-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-sm-2,
    .px-sm-2 {
        padding-left: 0.5rem !important;
    }

    .p-sm-3 {
        padding: 1rem !important;
    }

    .pt-sm-3,
    .py-sm-3 {
        padding-top: 1rem !important;
    }

    .pr-sm-3,
    .px-sm-3 {
        padding-right: 1rem !important;
    }

    .pb-sm-3,
    .py-sm-3 {
        padding-bottom: 1rem !important;
    }

    .pl-sm-3,
    .px-sm-3 {
        padding-left: 1rem !important;
    }

    .p-sm-4 {
        padding: 1.5rem !important;
    }

    .pt-sm-4,
    .py-sm-4 {
        padding-top: 1.5rem !important;
    }

    .pr-sm-4,
    .px-sm-4 {
        padding-right: 1.5rem !important;
    }

    .pb-sm-4,
    .py-sm-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-sm-4,
    .px-sm-4 {
        padding-left: 1.5rem !important;
    }

    .p-sm-5 {
        padding: 3rem !important;
    }

    .pt-sm-5,
    .py-sm-5 {
        padding-top: 3rem !important;
    }

    .pr-sm-5,
    .px-sm-5 {
        padding-right: 3rem !important;
    }

    .pb-sm-5,
    .py-sm-5 {
        padding-bottom: 3rem !important;
    }

    .pl-sm-5,
    .px-sm-5 {
        padding-left: 3rem !important;
    }

    .m-sm-n1 {
        margin: -0.25rem !important;
    }

    .mt-sm-n1,
    .my-sm-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-sm-n1,
    .mx-sm-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-sm-n1,
    .my-sm-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-sm-n1,
    .mx-sm-n1 {
        margin-left: -0.25rem !important;
    }

    .m-sm-n2 {
        margin: -0.5rem !important;
    }

    .mt-sm-n2,
    .my-sm-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-sm-n2,
    .mx-sm-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-sm-n2,
    .my-sm-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-sm-n2,
    .mx-sm-n2 {
        margin-left: -0.5rem !important;
    }

    .m-sm-n3 {
        margin: -1rem !important;
    }

    .mt-sm-n3,
    .my-sm-n3 {
        margin-top: -1rem !important;
    }

    .mr-sm-n3,
    .mx-sm-n3 {
        margin-right: -1rem !important;
    }

    .mb-sm-n3,
    .my-sm-n3 {
        margin-bottom: -1rem !important;
    }

    .ml-sm-n3,
    .mx-sm-n3 {
        margin-left: -1rem !important;
    }

    .m-sm-n4 {
        margin: -1.5rem !important;
    }

    .mt-sm-n4,
    .my-sm-n4 {
        margin-top: -1.5rem !important;
    }

    .mr-sm-n4,
    .mx-sm-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-sm-n4,
    .my-sm-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ml-sm-n4,
    .mx-sm-n4 {
        margin-left: -1.5rem !important;
    }

    .m-sm-n5 {
        margin: -3rem !important;
    }

    .mt-sm-n5,
    .my-sm-n5 {
        margin-top: -3rem !important;
    }

    .mr-sm-n5,
    .mx-sm-n5 {
        margin-right: -3rem !important;
    }

    .mb-sm-n5,
    .my-sm-n5 {
        margin-bottom: -3rem !important;
    }

    .ml-sm-n5,
    .mx-sm-n5 {
        margin-left: -3rem !important;
    }

    .m-sm-auto {
        margin: auto !important;
    }

    .mt-sm-auto,
    .my-sm-auto {
        margin-top: auto !important;
    }

    .mr-sm-auto,
    .mx-sm-auto {
        margin-right: auto !important;
    }

    .mb-sm-auto,
    .my-sm-auto {
        margin-bottom: auto !important;
    }

    .ml-sm-auto,
    .mx-sm-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 960px) {
    .m-md-0 {
        margin: 0 !important;
    }

    .mt-md-0,
    .my-md-0 {
        margin-top: 0 !important;
    }

    .mr-md-0,
    .mx-md-0 {
        margin-right: 0 !important;
    }

    .mb-md-0,
    .my-md-0 {
        margin-bottom: 0 !important;
    }

    .ml-md-0,
    .mx-md-0 {
        margin-left: 0 !important;
    }

    .m-md-1 {
        margin: 0.25rem !important;
    }

    .mt-md-1,
    .my-md-1 {
        margin-top: 0.25rem !important;
    }

    .mr-md-1,
    .mx-md-1 {
        margin-right: 0.25rem !important;
    }

    .mb-md-1,
    .my-md-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-md-1,
    .mx-md-1 {
        margin-left: 0.25rem !important;
    }

    .m-md-2 {
        margin: 0.5rem !important;
    }

    .mt-md-2,
    .my-md-2 {
        margin-top: 0.5rem !important;
    }

    .mr-md-2,
    .mx-md-2 {
        margin-right: 0.5rem !important;
    }

    .mb-md-2,
    .my-md-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-md-2,
    .mx-md-2 {
        margin-left: 0.5rem !important;
    }

    .m-md-3 {
        margin: 1rem !important;
    }

    .mt-md-3,
    .my-md-3 {
        margin-top: 1rem !important;
    }

    .mr-md-3,
    .mx-md-3 {
        margin-right: 1rem !important;
    }

    .mb-md-3,
    .my-md-3 {
        margin-bottom: 1rem !important;
    }

    .ml-md-3,
    .mx-md-3 {
        margin-left: 1rem !important;
    }

    .m-md-4 {
        margin: 1.5rem !important;
    }

    .mt-md-4,
    .my-md-4 {
        margin-top: 1.5rem !important;
    }

    .mr-md-4,
    .mx-md-4 {
        margin-right: 1.5rem !important;
    }

    .mb-md-4,
    .my-md-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-md-4,
    .mx-md-4 {
        margin-left: 1.5rem !important;
    }

    .m-md-5 {
        margin: 3rem !important;
    }

    .mt-md-5,
    .my-md-5 {
        margin-top: 3rem !important;
    }

    .mr-md-5,
    .mx-md-5 {
        margin-right: 3rem !important;
    }

    .mb-md-5,
    .my-md-5 {
        margin-bottom: 3rem !important;
    }

    .ml-md-5,
    .mx-md-5 {
        margin-left: 3rem !important;
    }

    .p-md-0 {
        padding: 0 !important;
    }

    .pt-md-0,
    .py-md-0 {
        padding-top: 0 !important;
    }

    .pr-md-0,
    .px-md-0 {
        padding-right: 0 !important;
    }

    .pb-md-0,
    .py-md-0 {
        padding-bottom: 0 !important;
    }

    .pl-md-0,
    .px-md-0 {
        padding-left: 0 !important;
    }

    .p-md-1 {
        padding: 0.25rem !important;
    }

    .pt-md-1,
    .py-md-1 {
        padding-top: 0.25rem !important;
    }

    .pr-md-1,
    .px-md-1 {
        padding-right: 0.25rem !important;
    }

    .pb-md-1,
    .py-md-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-md-1,
    .px-md-1 {
        padding-left: 0.25rem !important;
    }

    .p-md-2 {
        padding: 0.5rem !important;
    }

    .pt-md-2,
    .py-md-2 {
        padding-top: 0.5rem !important;
    }

    .pr-md-2,
    .px-md-2 {
        padding-right: 0.5rem !important;
    }

    .pb-md-2,
    .py-md-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-md-2,
    .px-md-2 {
        padding-left: 0.5rem !important;
    }

    .p-md-3 {
        padding: 1rem !important;
    }

    .pt-md-3,
    .py-md-3 {
        padding-top: 1rem !important;
    }

    .pr-md-3,
    .px-md-3 {
        padding-right: 1rem !important;
    }

    .pb-md-3,
    .py-md-3 {
        padding-bottom: 1rem !important;
    }

    .pl-md-3,
    .px-md-3 {
        padding-left: 1rem !important;
    }

    .p-md-4 {
        padding: 1.5rem !important;
    }

    .pt-md-4,
    .py-md-4 {
        padding-top: 1.5rem !important;
    }

    .pr-md-4,
    .px-md-4 {
        padding-right: 1.5rem !important;
    }

    .pb-md-4,
    .py-md-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-md-4,
    .px-md-4 {
        padding-left: 1.5rem !important;
    }

    .p-md-5 {
        padding: 3rem !important;
    }

    .pt-md-5,
    .py-md-5 {
        padding-top: 3rem !important;
    }

    .pr-md-5,
    .px-md-5 {
        padding-right: 3rem !important;
    }

    .pb-md-5,
    .py-md-5 {
        padding-bottom: 3rem !important;
    }

    .pl-md-5,
    .px-md-5 {
        padding-left: 3rem !important;
    }

    .m-md-n1 {
        margin: -0.25rem !important;
    }

    .mt-md-n1,
    .my-md-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-md-n1,
    .mx-md-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-md-n1,
    .my-md-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-md-n1,
    .mx-md-n1 {
        margin-left: -0.25rem !important;
    }

    .m-md-n2 {
        margin: -0.5rem !important;
    }

    .mt-md-n2,
    .my-md-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-md-n2,
    .mx-md-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-md-n2,
    .my-md-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-md-n2,
    .mx-md-n2 {
        margin-left: -0.5rem !important;
    }

    .m-md-n3 {
        margin: -1rem !important;
    }

    .mt-md-n3,
    .my-md-n3 {
        margin-top: -1rem !important;
    }

    .mr-md-n3,
    .mx-md-n3 {
        margin-right: -1rem !important;
    }

    .mb-md-n3,
    .my-md-n3 {
        margin-bottom: -1rem !important;
    }

    .ml-md-n3,
    .mx-md-n3 {
        margin-left: -1rem !important;
    }

    .m-md-n4 {
        margin: -1.5rem !important;
    }

    .mt-md-n4,
    .my-md-n4 {
        margin-top: -1.5rem !important;
    }

    .mr-md-n4,
    .mx-md-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-md-n4,
    .my-md-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ml-md-n4,
    .mx-md-n4 {
        margin-left: -1.5rem !important;
    }

    .m-md-n5 {
        margin: -3rem !important;
    }

    .mt-md-n5,
    .my-md-n5 {
        margin-top: -3rem !important;
    }

    .mr-md-n5,
    .mx-md-n5 {
        margin-right: -3rem !important;
    }

    .mb-md-n5,
    .my-md-n5 {
        margin-bottom: -3rem !important;
    }

    .ml-md-n5,
    .mx-md-n5 {
        margin-left: -3rem !important;
    }

    .m-md-auto {
        margin: auto !important;
    }

    .mt-md-auto,
    .my-md-auto {
        margin-top: auto !important;
    }

    .mr-md-auto,
    .mx-md-auto {
        margin-right: auto !important;
    }

    .mb-md-auto,
    .my-md-auto {
        margin-bottom: auto !important;
    }

    .ml-md-auto,
    .mx-md-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 1280px) {
    .m-lg-0 {
        margin: 0 !important;
    }

    .mt-lg-0,
    .my-lg-0 {
        margin-top: 0 !important;
    }

    .mr-lg-0,
    .mx-lg-0 {
        margin-right: 0 !important;
    }

    .mb-lg-0,
    .my-lg-0 {
        margin-bottom: 0 !important;
    }

    .ml-lg-0,
    .mx-lg-0 {
        margin-left: 0 !important;
    }

    .m-lg-1 {
        margin: 0.25rem !important;
    }

    .mt-lg-1,
    .my-lg-1 {
        margin-top: 0.25rem !important;
    }

    .mr-lg-1,
    .mx-lg-1 {
        margin-right: 0.25rem !important;
    }

    .mb-lg-1,
    .my-lg-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-lg-1,
    .mx-lg-1 {
        margin-left: 0.25rem !important;
    }

    .m-lg-2 {
        margin: 0.5rem !important;
    }

    .mt-lg-2,
    .my-lg-2 {
        margin-top: 0.5rem !important;
    }

    .mr-lg-2,
    .mx-lg-2 {
        margin-right: 0.5rem !important;
    }

    .mb-lg-2,
    .my-lg-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-lg-2,
    .mx-lg-2 {
        margin-left: 0.5rem !important;
    }

    .m-lg-3 {
        margin: 1rem !important;
    }

    .mt-lg-3,
    .my-lg-3 {
        margin-top: 1rem !important;
    }

    .mr-lg-3,
    .mx-lg-3 {
        margin-right: 1rem !important;
    }

    .mb-lg-3,
    .my-lg-3 {
        margin-bottom: 1rem !important;
    }

    .ml-lg-3,
    .mx-lg-3 {
        margin-left: 1rem !important;
    }

    .m-lg-4 {
        margin: 1.5rem !important;
    }

    .mt-lg-4,
    .my-lg-4 {
        margin-top: 1.5rem !important;
    }

    .mr-lg-4,
    .mx-lg-4 {
        margin-right: 1.5rem !important;
    }

    .mb-lg-4,
    .my-lg-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-lg-4,
    .mx-lg-4 {
        margin-left: 1.5rem !important;
    }

    .m-lg-5 {
        margin: 3rem !important;
    }

    .mt-lg-5,
    .my-lg-5 {
        margin-top: 3rem !important;
    }

    .mr-lg-5,
    .mx-lg-5 {
        margin-right: 3rem !important;
    }

    .mb-lg-5,
    .my-lg-5 {
        margin-bottom: 3rem !important;
    }

    .ml-lg-5,
    .mx-lg-5 {
        margin-left: 3rem !important;
    }

    .p-lg-0 {
        padding: 0 !important;
    }

    .pt-lg-0,
    .py-lg-0 {
        padding-top: 0 !important;
    }

    .pr-lg-0,
    .px-lg-0 {
        padding-right: 0 !important;
    }

    .pb-lg-0,
    .py-lg-0 {
        padding-bottom: 0 !important;
    }

    .pl-lg-0,
    .px-lg-0 {
        padding-left: 0 !important;
    }

    .p-lg-1 {
        padding: 0.25rem !important;
    }

    .pt-lg-1,
    .py-lg-1 {
        padding-top: 0.25rem !important;
    }

    .pr-lg-1,
    .px-lg-1 {
        padding-right: 0.25rem !important;
    }

    .pb-lg-1,
    .py-lg-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-lg-1,
    .px-lg-1 {
        padding-left: 0.25rem !important;
    }

    .p-lg-2 {
        padding: 0.5rem !important;
    }

    .pt-lg-2,
    .py-lg-2 {
        padding-top: 0.5rem !important;
    }

    .pr-lg-2,
    .px-lg-2 {
        padding-right: 0.5rem !important;
    }

    .pb-lg-2,
    .py-lg-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-lg-2,
    .px-lg-2 {
        padding-left: 0.5rem !important;
    }

    .p-lg-3 {
        padding: 1rem !important;
    }

    .pt-lg-3,
    .py-lg-3 {
        padding-top: 1rem !important;
    }

    .pr-lg-3,
    .px-lg-3 {
        padding-right: 1rem !important;
    }

    .pb-lg-3,
    .py-lg-3 {
        padding-bottom: 1rem !important;
    }

    .pl-lg-3,
    .px-lg-3 {
        padding-left: 1rem !important;
    }

    .p-lg-4 {
        padding: 1.5rem !important;
    }

    .pt-lg-4,
    .py-lg-4 {
        padding-top: 1.5rem !important;
    }

    .pr-lg-4,
    .px-lg-4 {
        padding-right: 1.5rem !important;
    }

    .pb-lg-4,
    .py-lg-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-lg-4,
    .px-lg-4 {
        padding-left: 1.5rem !important;
    }

    .p-lg-5 {
        padding: 3rem !important;
    }

    .pt-lg-5,
    .py-lg-5 {
        padding-top: 3rem !important;
    }

    .pr-lg-5,
    .px-lg-5 {
        padding-right: 3rem !important;
    }

    .pb-lg-5,
    .py-lg-5 {
        padding-bottom: 3rem !important;
    }

    .pl-lg-5,
    .px-lg-5 {
        padding-left: 3rem !important;
    }

    .m-lg-n1 {
        margin: -0.25rem !important;
    }

    .mt-lg-n1,
    .my-lg-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-lg-n1,
    .mx-lg-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-lg-n1,
    .my-lg-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-lg-n1,
    .mx-lg-n1 {
        margin-left: -0.25rem !important;
    }

    .m-lg-n2 {
        margin: -0.5rem !important;
    }

    .mt-lg-n2,
    .my-lg-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-lg-n2,
    .mx-lg-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-lg-n2,
    .my-lg-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-lg-n2,
    .mx-lg-n2 {
        margin-left: -0.5rem !important;
    }

    .m-lg-n3 {
        margin: -1rem !important;
    }

    .mt-lg-n3,
    .my-lg-n3 {
        margin-top: -1rem !important;
    }

    .mr-lg-n3,
    .mx-lg-n3 {
        margin-right: -1rem !important;
    }

    .mb-lg-n3,
    .my-lg-n3 {
        margin-bottom: -1rem !important;
    }

    .ml-lg-n3,
    .mx-lg-n3 {
        margin-left: -1rem !important;
    }

    .m-lg-n4 {
        margin: -1.5rem !important;
    }

    .mt-lg-n4,
    .my-lg-n4 {
        margin-top: -1.5rem !important;
    }

    .mr-lg-n4,
    .mx-lg-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-lg-n4,
    .my-lg-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ml-lg-n4,
    .mx-lg-n4 {
        margin-left: -1.5rem !important;
    }

    .m-lg-n5 {
        margin: -3rem !important;
    }

    .mt-lg-n5,
    .my-lg-n5 {
        margin-top: -3rem !important;
    }

    .mr-lg-n5,
    .mx-lg-n5 {
        margin-right: -3rem !important;
    }

    .mb-lg-n5,
    .my-lg-n5 {
        margin-bottom: -3rem !important;
    }

    .ml-lg-n5,
    .mx-lg-n5 {
        margin-left: -3rem !important;
    }

    .m-lg-auto {
        margin: auto !important;
    }

    .mt-lg-auto,
    .my-lg-auto {
        margin-top: auto !important;
    }

    .mr-lg-auto,
    .mx-lg-auto {
        margin-right: auto !important;
    }

    .mb-lg-auto,
    .my-lg-auto {
        margin-bottom: auto !important;
    }

    .ml-lg-auto,
    .mx-lg-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 1920px) {
    .m-xl-0 {
        margin: 0 !important;
    }

    .mt-xl-0,
    .my-xl-0 {
        margin-top: 0 !important;
    }

    .mr-xl-0,
    .mx-xl-0 {
        margin-right: 0 !important;
    }

    .mb-xl-0,
    .my-xl-0 {
        margin-bottom: 0 !important;
    }

    .ml-xl-0,
    .mx-xl-0 {
        margin-left: 0 !important;
    }

    .m-xl-1 {
        margin: 0.25rem !important;
    }

    .mt-xl-1,
    .my-xl-1 {
        margin-top: 0.25rem !important;
    }

    .mr-xl-1,
    .mx-xl-1 {
        margin-right: 0.25rem !important;
    }

    .mb-xl-1,
    .my-xl-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-xl-1,
    .mx-xl-1 {
        margin-left: 0.25rem !important;
    }

    .m-xl-2 {
        margin: 0.5rem !important;
    }

    .mt-xl-2,
    .my-xl-2 {
        margin-top: 0.5rem !important;
    }

    .mr-xl-2,
    .mx-xl-2 {
        margin-right: 0.5rem !important;
    }

    .mb-xl-2,
    .my-xl-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-xl-2,
    .mx-xl-2 {
        margin-left: 0.5rem !important;
    }

    .m-xl-3 {
        margin: 1rem !important;
    }

    .mt-xl-3,
    .my-xl-3 {
        margin-top: 1rem !important;
    }

    .mr-xl-3,
    .mx-xl-3 {
        margin-right: 1rem !important;
    }

    .mb-xl-3,
    .my-xl-3 {
        margin-bottom: 1rem !important;
    }

    .ml-xl-3,
    .mx-xl-3 {
        margin-left: 1rem !important;
    }

    .m-xl-4 {
        margin: 1.5rem !important;
    }

    .mt-xl-4,
    .my-xl-4 {
        margin-top: 1.5rem !important;
    }

    .mr-xl-4,
    .mx-xl-4 {
        margin-right: 1.5rem !important;
    }

    .mb-xl-4,
    .my-xl-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-xl-4,
    .mx-xl-4 {
        margin-left: 1.5rem !important;
    }

    .m-xl-5 {
        margin: 3rem !important;
    }

    .mt-xl-5,
    .my-xl-5 {
        margin-top: 3rem !important;
    }

    .mr-xl-5,
    .mx-xl-5 {
        margin-right: 3rem !important;
    }

    .mb-xl-5,
    .my-xl-5 {
        margin-bottom: 3rem !important;
    }

    .ml-xl-5,
    .mx-xl-5 {
        margin-left: 3rem !important;
    }

    .p-xl-0 {
        padding: 0 !important;
    }

    .pt-xl-0,
    .py-xl-0 {
        padding-top: 0 !important;
    }

    .pr-xl-0,
    .px-xl-0 {
        padding-right: 0 !important;
    }

    .pb-xl-0,
    .py-xl-0 {
        padding-bottom: 0 !important;
    }

    .pl-xl-0,
    .px-xl-0 {
        padding-left: 0 !important;
    }

    .p-xl-1 {
        padding: 0.25rem !important;
    }

    .pt-xl-1,
    .py-xl-1 {
        padding-top: 0.25rem !important;
    }

    .pr-xl-1,
    .px-xl-1 {
        padding-right: 0.25rem !important;
    }

    .pb-xl-1,
    .py-xl-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-xl-1,
    .px-xl-1 {
        padding-left: 0.25rem !important;
    }

    .p-xl-2 {
        padding: 0.5rem !important;
    }

    .pt-xl-2,
    .py-xl-2 {
        padding-top: 0.5rem !important;
    }

    .pr-xl-2,
    .px-xl-2 {
        padding-right: 0.5rem !important;
    }

    .pb-xl-2,
    .py-xl-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-xl-2,
    .px-xl-2 {
        padding-left: 0.5rem !important;
    }

    .p-xl-3 {
        padding: 1rem !important;
    }

    .pt-xl-3,
    .py-xl-3 {
        padding-top: 1rem !important;
    }

    .pr-xl-3,
    .px-xl-3 {
        padding-right: 1rem !important;
    }

    .pb-xl-3,
    .py-xl-3 {
        padding-bottom: 1rem !important;
    }

    .pl-xl-3,
    .px-xl-3 {
        padding-left: 1rem !important;
    }

    .p-xl-4 {
        padding: 1.5rem !important;
    }

    .pt-xl-4,
    .py-xl-4 {
        padding-top: 1.5rem !important;
    }

    .pr-xl-4,
    .px-xl-4 {
        padding-right: 1.5rem !important;
    }

    .pb-xl-4,
    .py-xl-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-xl-4,
    .px-xl-4 {
        padding-left: 1.5rem !important;
    }

    .p-xl-5 {
        padding: 3rem !important;
    }

    .pt-xl-5,
    .py-xl-5 {
        padding-top: 3rem !important;
    }

    .pr-xl-5,
    .px-xl-5 {
        padding-right: 3rem !important;
    }

    .pb-xl-5,
    .py-xl-5 {
        padding-bottom: 3rem !important;
    }

    .pl-xl-5,
    .px-xl-5 {
        padding-left: 3rem !important;
    }

    .m-xl-n1 {
        margin: -0.25rem !important;
    }

    .mt-xl-n1,
    .my-xl-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-xl-n1,
    .mx-xl-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-xl-n1,
    .my-xl-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-xl-n1,
    .mx-xl-n1 {
        margin-left: -0.25rem !important;
    }

    .m-xl-n2 {
        margin: -0.5rem !important;
    }

    .mt-xl-n2,
    .my-xl-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-xl-n2,
    .mx-xl-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-xl-n2,
    .my-xl-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-xl-n2,
    .mx-xl-n2 {
        margin-left: -0.5rem !important;
    }

    .m-xl-n3 {
        margin: -1rem !important;
    }

    .mt-xl-n3,
    .my-xl-n3 {
        margin-top: -1rem !important;
    }

    .mr-xl-n3,
    .mx-xl-n3 {
        margin-right: -1rem !important;
    }

    .mb-xl-n3,
    .my-xl-n3 {
        margin-bottom: -1rem !important;
    }

    .ml-xl-n3,
    .mx-xl-n3 {
        margin-left: -1rem !important;
    }

    .m-xl-n4 {
        margin: -1.5rem !important;
    }

    .mt-xl-n4,
    .my-xl-n4 {
        margin-top: -1.5rem !important;
    }

    .mr-xl-n4,
    .mx-xl-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-xl-n4,
    .my-xl-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ml-xl-n4,
    .mx-xl-n4 {
        margin-left: -1.5rem !important;
    }

    .m-xl-n5 {
        margin: -3rem !important;
    }

    .mt-xl-n5,
    .my-xl-n5 {
        margin-top: -3rem !important;
    }

    .mr-xl-n5,
    .mx-xl-n5 {
        margin-right: -3rem !important;
    }

    .mb-xl-n5,
    .my-xl-n5 {
        margin-bottom: -3rem !important;
    }

    .ml-xl-n5,
    .mx-xl-n5 {
        margin-left: -3rem !important;
    }

    .m-xl-auto {
        margin: auto !important;
    }

    .mt-xl-auto,
    .my-xl-auto {
        margin-top: auto !important;
    }

    .mr-xl-auto,
    .mx-xl-auto {
        margin-right: auto !important;
    }

    .mb-xl-auto,
    .my-xl-auto {
        margin-bottom: auto !important;
    }

    .ml-xl-auto,
    .mx-xl-auto {
        margin-left: auto !important;
    }
}

.text-monospace {
    font-family: "Times New Roman", Times, serif !important;
}

.text-justify {
    text-align: justify !important;
}

.text-wrap {
    white-space: normal !important;
}

.text-nowrap {
    white-space: nowrap !important;
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

@media (min-width: 600px) {
    .text-sm-left {
        text-align: left !important;
    }

    .text-sm-right {
        text-align: right !important;
    }

    .text-sm-center {
        text-align: center !important;
    }
}

@media (min-width: 960px) {
    .text-md-left {
        text-align: left !important;
    }

    .text-md-right {
        text-align: right !important;
    }

    .text-md-center {
        text-align: center !important;
    }
}

@media (min-width: 1280px) {
    .text-lg-left {
        text-align: left !important;
    }

    .text-lg-right {
        text-align: right !important;
    }

    .text-lg-center {
        text-align: center !important;
    }
}

@media (min-width: 1920px) {
    .text-xl-left {
        text-align: left !important;
    }

    .text-xl-right {
        text-align: right !important;
    }

    .text-xl-center {
        text-align: center !important;
    }
}

.text-lowercase {
    text-transform: lowercase !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}

.font-weight-light {
    font-weight: 300 !important;
}

.font-weight-lighter {
    font-weight: lighter !important;
}

.font-weight-normal {
    font-weight: 400 !important;
}

.font-weight-bold {
    font-weight: 700 !important;
}

.font-weight-bolder {
    font-weight: bolder !important;
}

.font-italic {
    font-style: italic !important;
}

.text-white {
    color: #fff !important;
}

.text-primary {
    color: #2F80ED !important;
}

a.text-primary:hover, a.text-primary:focus {
    color: rgb(16.5265486726, 90.8960176991, 190.9734513274) !important;
}

.text-secondary {
    color: #f8f9ff !important;
}

a.text-secondary:hover, a.text-secondary:focus {
    color: rgb(171.5, 183.4285714286, 255) !important;
}

.text-success {
    color: #1bc943 !important;
}

a.text-success:hover, a.text-success:focus {
    color: rgb(17.9407894737, 133.5592105263, 44.5197368421) !important;
}

.text-info {
    color: #11c5db !important;
}

a.text-info:hover, a.text-info:focus {
    color: rgb(11.4894067797, 133.1419491525, 148.0105932203) !important;
}

.text-warning {
    color: #f4772e !important;
}

a.text-warning:hover, a.text-warning:focus {
    color: rgb(202.825, 81.5181818182, 10.675) !important;
}

.text-danger {
    color: #f83245 !important;
}

a.text-danger:hover, a.text-danger:focus {
    color: rgb(214.1863207547, 7.3136792453, 27.1650943396) !important;
}

.text-light {
    color: #f4f5fd !important;
}

a.text-light:hover, a.text-light:focus {
    color: rgb(179.2692307692, 186.1538461538, 241.2307692308) !important;
}

.text-gray {
    color: #f8f9ff !important;
}

a.text-gray:hover, a.text-gray:focus {
    color: rgb(171.5, 183.4285714286, 255) !important;
}

.text-dark {
    color: #7a7b97 !important;
}

a.text-dark:hover, a.text-dark:focus {
    color: rgb(86.2278481013, 87.0569620253, 110.2721518987) !important;
}

.text-first {
    color: #2F80ED !important;
}

a.text-first:hover, a.text-first:focus {
    color: rgb(16.5265486726, 90.8960176991, 190.9734513274) !important;
}

.text-second {
    color: #070919 !important;
}

a.text-second:hover, a.text-second:focus {
    color: black !important;
}

.text-body {
    color: #3b3e66 !important;
}

.text-muted {
    color: #d1d2db !important;
}

.text-black-50 {
    color: rgba(7, 9, 25, 0.5) !important;
}

.text-white-50 {
    color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.text-decoration-none {
    text-decoration: none !important;
}

.text-break {
    word-break: break-word !important;
    overflow-wrap: break-word !important;
}

.text-reset {
    color: inherit !important;
}

.visible {
    visibility: visible !important;
}

.invisible {
    visibility: hidden !important;
}

.dropzone {
    position: relative;
    display: flex;
    flex-direction: column;
    outline: none !important;
}

.dropzone * {
    outline: none !important;
}

.dz-preview-single {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0.65rem;
}

.dz-preview-cover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0.65rem;
}

.dz-preview-img {
    width: 100%;
    height: 100%;
    border-radius: 0.65rem;
    object-fit: cover;
}

.dz-message {
    z-index: 5;
    padding: 3rem 1rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-align: center;
    color: #d1d2db;
    border: 1px dashed #e6e7f1;
    border-radius: 0.65rem;
    background-color: #f8f9ff;
    font-size: 1.1875rem;
    order: -1;
}

.dz-message:hover {
    border-color: #2F80ED;
    color: #7a7b97;
}

.dropzone-single.dz-max-files-reached .dz-message {
    background: rgba(7, 9, 25, 0.9);
}

.dropzone-single.dz-max-files-reached .dz-message:hover {
    opacity: 1;
}

.rdw-option-wrapper {
    border: 1px solid #d1d2db;
    padding: 8px;
    min-width: 25px;
    border-radius: 0.29rem;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: #fff;
    text-transform: capitalize;
}

.rdw-option-wrapper:hover {
    box-shadow: 1px 1px 0px #e6e7f1;
}

.rdw-option-wrapper:active {
    box-shadow: 1px 1px 0px #e6e7f1 inset;
}

.rdw-option-active {
    box-shadow: 1px 1px 0px #e6e7f1 inset;
}

.rdw-option-disabled {
    opacity: 0.3;
    cursor: default;
}

.rdw-dropdown-wrapper {
    height: 30px;
    background: #fff;
    cursor: pointer;
    border: 1px solid #d1d2db;
    border-radius: 0.29rem;
    margin: 0 3px;
    text-transform: capitalize;
    background: #fff;
}

.rdw-dropdown-wrapper:focus {
    outline: none;
}

.rdw-dropdown-wrapper:hover {
    box-shadow: 1px 1px 0px #e6e7f1;
    background-color: #FFFFFF;
}

.rdw-dropdown-wrapper:active {
    box-shadow: 1px 1px 0px #e6e7f1 inset;
}

.rdw-dropdown-carettoopen {
    height: 0px;
    width: 0px;
    position: absolute;
    top: 35%;
    right: 10%;
    border-top: 6px solid #070919;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
}

.rdw-dropdown-carettoclose {
    height: 0px;
    width: 0px;
    position: absolute;
    top: 35%;
    right: 10%;
    border-bottom: 6px solid #070919;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
}

.rdw-dropdown-selectedtext {
    display: flex;
    position: relative;
    height: 100%;
    align-items: center;
    padding: 0 5px;
}

.rdw-dropdown-optionwrapper {
    z-index: 100;
    position: relative;
    border: 1px solid #d1d2db;
    width: 98%;
    background: #fff;
    border-radius: 0.29rem;
    margin: 0;
    padding: 0;
    max-height: 250px;
    overflow-y: scroll;
}

.rdw-dropdown-optionwrapper:hover {
    box-shadow: 1px 1px 0px #e6e7f1;
    background-color: #FFFFFF;
}

.rdw-dropdownoption-default {
    min-height: 25px;
    display: flex;
    align-items: center;
    padding: 0 5px;
}

.rdw-dropdownoption-highlighted {
    background: #d1d2db;
}

.rdw-dropdownoption-active {
    background: #f5f5f5;
}

.rdw-dropdownoption-disabled {
    opacity: 0.3;
    cursor: default;
}

.rdw-inline-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    flex-wrap: wrap;
}

.rdw-inline-dropdown {
    width: 50px;
}

.rdw-inline-dropdownoption {
    height: 40px;
    display: flex;
    justify-content: center;
}

.rdw-block-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    flex-wrap: wrap;
}

.rdw-block-dropdown {
    width: 110px;
}

.rdw-fontsize-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    flex-wrap: wrap;
}

.rdw-fontsize-dropdown {
    min-width: 40px;
}

.rdw-fontsize-option {
    display: flex;
    justify-content: center;
}

.rdw-fontfamily-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    flex-wrap: wrap;
}

.rdw-fontfamily-dropdown {
    width: 115px;
}

.rdw-fontfamily-placeholder {
    white-space: nowrap;
    max-width: 90px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rdw-fontfamily-optionwrapper {
    width: 140px;
}

.rdw-list-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    flex-wrap: wrap;
}

.rdw-list-dropdown {
    width: 50px;
    z-index: 90;
}

.rdw-list-dropdownOption {
    height: 40px;
    display: flex;
    justify-content: center;
}

.rdw-text-align-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    flex-wrap: wrap;
}

.rdw-text-align-dropdown {
    width: 50px;
    z-index: 90;
}

.rdw-text-align-dropdownOption {
    height: 40px;
    display: flex;
    justify-content: center;
}

.rdw-right-aligned-block {
    text-align: right;
}

.rdw-left-aligned-block {
    text-align: left !important;
}

.rdw-center-aligned-block {
    text-align: center !important;
}

.rdw-justify-aligned-block {
    text-align: justify !important;
}

.rdw-right-aligned-block > div {
    display: inline-block;
}

.rdw-left-aligned-block > div {
    display: inline-block;
}

.rdw-center-aligned-block > div {
    display: inline-block;
}

.rdw-justify-aligned-block > div {
    display: inline-block;
}

.rdw-colorpicker-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    position: relative;
    flex-wrap: wrap;
}

.rdw-colorpicker-modal {
    position: absolute;
    top: 35px;
    left: 5px;
    display: flex;
    flex-direction: column;
    width: 175px;
    height: 175px;
    border: 1px solid #d1d2db;
    padding: 15px;
    border-radius: 0.29rem;
    z-index: 100;
    background: #fff;
    box-shadow: 3px 3px 5px #e6e7f1;
}

.rdw-colorpicker-modal-header {
    display: flex;
    padding-bottom: 5px;
}

.rdw-colorpicker-modal-style-label {
    font-size: 15px;
    width: 50%;
    text-align: center;
    cursor: pointer;
    padding: 0 10px 5px;
}

.rdw-colorpicker-modal-style-label-active {
    border-bottom: 2px solid #2F80ED;
}

.rdw-colorpicker-modal-options {
    margin: 5px auto;
    display: flex;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    overflow: scroll;
}

.rdw-colorpicker-cube {
    width: 22px;
    height: 22px;
    border: 1px solid #d1d2db;
}

.rdw-colorpicker-option {
    margin: 3px;
    padding: 0;
    min-height: 20px;
    border: none;
    width: 22px;
    height: 22px;
    min-width: 22px;
    box-shadow: 1px 2px 1px #e6e7f1 inset;
}

.rdw-colorpicker-option:hover {
    box-shadow: 1px 2px 1px #e6e7f1;
}

.rdw-colorpicker-option:active {
    box-shadow: -1px -2px 1px #e6e7f1;
}

.rdw-colorpicker-option-active {
    box-shadow: 0px 0px 2px 2px #e6e7f1;
}

.rdw-link-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    position: relative;
    flex-wrap: wrap;
}

.rdw-link-dropdown {
    width: 50px;
}

.rdw-link-dropdownOption {
    height: 40px;
    display: flex;
    justify-content: center;
}

.rdw-link-dropdownPlaceholder {
    margin-left: 8px;
}

.rdw-link-modal {
    position: absolute;
    top: 35px;
    left: 5px;
    display: flex;
    flex-direction: column;
    width: 235px;
    height: 205px;
    border: 1px solid #d1d2db;
    padding: 15px;
    border-radius: 0.29rem;
    z-index: 100;
    background: #fff;
    box-shadow: 3px 3px 5px #e6e7f1;
}

.rdw-link-modal-label {
    font-size: 15px;
}

.rdw-link-modal-input {
    margin-top: 5px;
    border-radius: 0.29rem;
    border: 1px solid #d1d2db;
    height: 25px;
    margin-bottom: 15px;
    padding: 0 5px;
}

.rdw-link-modal-input:focus {
    outline: none;
}

.rdw-link-modal-buttonsection {
    margin: 0 auto;
}

.rdw-link-modal-target-option {
    margin-bottom: 20px;
}

.rdw-link-modal-target-option > span {
    margin-left: 5px;
}

.rdw-link-modal-btn {
    margin-left: 10px;
    width: 75px;
    height: 30px;
    border: 1px solid #d1d2db;
    border-radius: 0.29rem;
    cursor: pointer;
    background: #fff;
    text-transform: capitalize;
}

.rdw-link-modal-btn:hover {
    box-shadow: 1px 1px 0px #e6e7f1;
}

.rdw-link-modal-btn:active {
    box-shadow: 1px 1px 0px #e6e7f1 inset;
}

.rdw-link-modal-btn:focus {
    outline: none !important;
}

.rdw-link-modal-btn:disabled {
    background: #eeeff8;
}

.rdw-link-dropdownoption {
    height: 40px;
    display: flex;
    justify-content: center;
}

.rdw-history-dropdown {
    width: 50px;
}

.rdw-embedded-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    position: relative;
    flex-wrap: wrap;
}

.rdw-embedded-modal {
    position: absolute;
    top: 35px;
    left: 5px;
    display: flex;
    flex-direction: column;
    width: 235px;
    height: 180px;
    border: 1px solid #d1d2db;
    padding: 15px;
    border-radius: 0.29rem;
    z-index: 100;
    background: #fff;
    justify-content: space-between;
    box-shadow: 3px 3px 5px #e6e7f1;
}

.rdw-embedded-modal-header {
    font-size: 15px;
    display: flex;
}

.rdw-embedded-modal-header-option {
    width: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.rdw-embedded-modal-header-label {
    width: 95px;
    border: 1px solid #e6e7f1;
    margin-top: 5px;
    background: rgb(235.6310344828, 237.5206896552, 246.9689655172);
    border-bottom: 2px solid #2F80ED;
}

.rdw-embedded-modal-link-section {
    display: flex;
    flex-direction: column;
}

.rdw-embedded-modal-link-input {
    width: 88%;
    height: 35px;
    margin: 10px 0;
    border: 1px solid #d1d2db;
    border-radius: 0.29rem;
    font-size: 15px;
    padding: 0 5px;
}

.rdw-embedded-modal-link-input-wrapper {
    display: flex;
    align-items: center;
}

.rdw-embedded-modal-link-input:focus {
    outline: none;
}

.rdw-embedded-modal-btn-section {
    display: flex;
    justify-content: center;
}

.rdw-embedded-modal-btn {
    margin: 0 3px;
    width: 75px;
    height: 30px;
    border: 1px solid #d1d2db;
    border-radius: 0.29rem;
    cursor: pointer;
    background: #fff;
    text-transform: capitalize;
}

.rdw-embedded-modal-btn:hover {
    box-shadow: 1px 1px 0px #e6e7f1;
}

.rdw-embedded-modal-btn:active {
    box-shadow: 1px 1px 0px #e6e7f1 inset;
}

.rdw-embedded-modal-btn:focus {
    outline: none !important;
}

.rdw-embedded-modal-btn:disabled {
    background: #eeeff8;
}

.rdw-embedded-modal-size {
    align-items: center;
    display: flex;
    margin: 8px 0;
    justify-content: space-between;
}

.rdw-embedded-modal-size-input {
    width: 80%;
    height: 20px;
    border: 1px solid #d1d2db;
    border-radius: 0.29rem;
    font-size: 12px;
}

.rdw-embedded-modal-size-input:focus {
    outline: none;
}

.rdw-emoji-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    position: relative;
    flex-wrap: wrap;
}

.rdw-emoji-modal {
    overflow: auto;
    position: absolute;
    top: 35px;
    left: 5px;
    display: flex;
    flex-wrap: wrap;
    width: 235px;
    height: 180px;
    border: 1px solid #d1d2db;
    padding: 15px;
    border-radius: 0.29rem;
    z-index: 100;
    background: #fff;
    box-shadow: 3px 3px 5px #e6e7f1;
}

.rdw-emoji-icon {
    margin: 2.5px;
    height: 24px;
    width: 24px;
    cursor: pointer;
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rdw-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.rdw-spinner > div {
    width: 12px;
    height: 12px;
    background-color: #333;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.rdw-spinner .rdw-bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.rdw-spinner .rdw-bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
    }
}

@keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.rdw-image-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    position: relative;
    flex-wrap: wrap;
}

.rdw-image-modal {
    position: absolute;
    top: 35px;
    left: 5px;
    display: flex;
    flex-direction: column;
    width: 235px;
    border: 1px solid #d1d2db;
    padding: 15px;
    border-radius: 0.29rem;
    z-index: 100;
    background: #fff;
    box-shadow: 3px 3px 5px #e6e7f1;
}

.rdw-image-modal-header {
    font-size: 15px;
    margin: 10px 0;
    display: flex;
}

.rdw-image-modal-header-option {
    width: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.rdw-image-modal-header-label {
    width: 80px;
    background: #e6e7f1;
    border: 1px solid #e6e7f1;
    margin-top: 5px;
}

.rdw-image-modal-header-label-highlighted {
    background: rgb(235.6310344828, 237.5206896552, 246.9689655172);
    border-bottom: 2px solid #2F80ED;
}

.rdw-image-modal-upload-option {
    width: 100%;
    color: gray;
    cursor: pointer;
    display: flex;
    border: none;
    font-size: 15px;
    align-items: center;
    justify-content: center;
    background-color: #e6e7f1;
    outline: 2px dashed gray;
    outline-offset: -10px;
    margin: 10px 0;
    padding: 9px 0;
}

.rdw-image-modal-upload-option-highlighted {
    outline: 2px dashed #2F80ED;
}

.rdw-image-modal-upload-option-label {
    cursor: pointer;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
}

.rdw-image-modal-upload-option-label span {
    padding: 0 20px;
}

.rdw-image-modal-upload-option-image-preview {
    max-width: 100%;
    max-height: 200px;
}

.rdw-image-modal-upload-option-input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.rdw-image-modal-url-section {
    display: flex;
    align-items: center;
}

.rdw-image-modal-url-input {
    width: 90%;
    height: 35px;
    margin: 15px 0 12px;
    border: 1px solid #d1d2db;
    border-radius: 0.29rem;
    font-size: 15px;
    padding: 0 5px;
}

.rdw-image-modal-btn-section {
    margin: 10px auto 0;
}

.rdw-image-modal-url-input:focus {
    outline: none;
}

.rdw-image-modal-btn {
    margin: 0 5px;
    width: 75px;
    height: 30px;
    border: 1px solid #d1d2db;
    border-radius: 0.29rem;
    cursor: pointer;
    background: #fff;
    text-transform: capitalize;
}

.rdw-image-modal-btn:hover {
    box-shadow: 1px 1px 0px #e6e7f1;
}

.rdw-image-modal-btn:active {
    box-shadow: 1px 1px 0px #e6e7f1 inset;
}

.rdw-image-modal-btn:focus {
    outline: none !important;
}

.rdw-image-modal-btn:disabled {
    background: #eeeff8;
}

.rdw-image-modal-spinner {
    position: absolute;
    top: -3px;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
}

.rdw-image-modal-alt-input {
    width: 70%;
    height: 20px;
    border: 1px solid #d1d2db;
    border-radius: 0.29rem;
    font-size: 12px;
    margin-left: 5px;
}

.rdw-image-modal-alt-input:focus {
    outline: none;
}

.rdw-image-modal-alt-lbl {
    font-size: 12px;
}

.rdw-image-modal-size {
    align-items: center;
    display: flex;
    margin: 8px 0;
    justify-content: space-between;
}

.rdw-image-modal-size-input {
    width: 40%;
    height: 20px;
    border: 1px solid #d1d2db;
    border-radius: 0.29rem;
    font-size: 12px;
}

.rdw-image-modal-size-input:focus {
    outline: none;
}

.rdw-image-mandatory-sign {
    color: #f83245;
    margin-left: 3px;
    margin-right: 3px;
}

.rdw-remove-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    position: relative;
    flex-wrap: wrap;
}

.rdw-history-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    flex-wrap: wrap;
}

.rdw-history-dropdownoption {
    height: 40px;
    display: flex;
    justify-content: center;
}

.rdw-history-dropdown {
    width: 50px;
}

.rdw-link-decorator-wrapper {
    position: relative;
}

.rdw-link-decorator-icon {
    position: absolute;
    left: 40%;
    top: 0;
    cursor: pointer;
    background-color: #fff;
}

.rdw-mention-link {
    text-decoration: none;
    color: #1236ff;
    background-color: #f0fbff;
    padding: 1px 2px;
    border-radius: 0.29rem;
}

.rdw-suggestion-wrapper {
    position: relative;
}

.rdw-suggestion-dropdown {
    position: absolute;
    display: flex;
    flex-direction: column;
    border: 1px solid #d1d2db;
    min-width: 100px;
    max-height: 150px;
    overflow: auto;
    background: #fff;
    z-index: 100;
}

.rdw-suggestion-option {
    padding: 7px 5px;
    border-bottom: 1px solid #e6e7f1;
}

.rdw-suggestion-option-active {
    background-color: #d1d2db;
}

.rdw-hashtag-link {
    text-decoration: none;
    color: #1236ff;
    background-color: #f0fbff;
    padding: 1px 2px;
    border-radius: 0.29rem;
}

.rdw-image-alignment-options-popup {
    position: absolute;
    background: #fff;
    display: flex;
    padding: 5px 2px;
    border-radius: 0.29rem;
    border: 1px solid #d1d2db;
    width: 105px;
    cursor: pointer;
    z-index: 100;
}

.rdw-alignment-option-left {
    justify-content: flex-start;
}

.rdw-image-alignment-option {
    height: 15px;
    width: 15px;
    min-width: 15px;
}

.rdw-image-alignment {
    position: relative;
}

.rdw-image-imagewrapper {
    position: relative;
}

.rdw-image-center {
    display: flex;
    justify-content: center;
}

.rdw-image-left {
    display: flex;
}

.rdw-image-right {
    display: flex;
    justify-content: flex-end;
}

.rdw-image-alignment-options-popup-right {
    right: 0;
}

.rdw-editor-main {
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
}

.rdw-editor-toolbar {
    padding: 6px 5px 0;
    border-radius: 0.29rem;
    border: 1px solid #d1d2db;
    display: flex;
    justify-content: flex-start;
    background: #fff;
    flex-wrap: wrap;
    font-size: 15px;
    margin-bottom: 5px;
    user-select: none;
}

.public-DraftStyleDefault-block {
    margin: 1em 0;
}

.rdw-editor-wrapper:focus {
    outline: none;
}

.rdw-editor-wrapper {
    box-sizing: content-box;
}

.rdw-editor-main blockquote {
    border-left: 5px solid #e6e7f1;
    padding-left: 5px;
}

.rdw-editor-main pre {
    background: #e6e7f1;
    border-radius: 3px;
    padding: 1px 10px;
}

/**
 * Draft
 */
.DraftEditor-editorContainer, .DraftEditor-root, .public-DraftEditor-content {
    height: inherit;
    text-align: initial;
}

.public-DraftEditor-content[contenteditable=true] {
    -webkit-user-modify: read-write-plaintext-only;
}

.DraftEditor-root {
    position: relative;
}

.DraftEditor-editorContainer {
    background-color: rgba(255, 255, 255, 0);
    border-left: 0.1px solid transparent;
    position: relative;
    z-index: 1;
}

.public-DraftEditor-block {
    position: relative;
}

.DraftEditor-alignLeft .public-DraftStyleDefault-block {
    text-align: left;
}

.DraftEditor-alignLeft .public-DraftEditorPlaceholder-root {
    left: 0;
    text-align: left;
}

.DraftEditor-alignCenter .public-DraftStyleDefault-block {
    text-align: center;
}

.DraftEditor-alignCenter .public-DraftEditorPlaceholder-root {
    margin: 0 auto;
    text-align: center;
    width: 100%;
}

.DraftEditor-alignRight .public-DraftStyleDefault-block {
    text-align: right;
}

.DraftEditor-alignRight .public-DraftEditorPlaceholder-root {
    right: 0;
    text-align: right;
}

.public-DraftEditorPlaceholder-root {
    color: #9197a3;
    position: absolute;
    z-index: 0;
}

.public-DraftEditorPlaceholder-hasFocus {
    color: #bdc1c9;
}

.DraftEditorPlaceholder-hidden {
    display: none;
}

.public-DraftStyleDefault-block {
    position: relative;
    white-space: pre-wrap;
}

.public-DraftStyleDefault-ltr {
    direction: ltr;
    text-align: left;
}

.public-DraftStyleDefault-rtl {
    direction: rtl;
    text-align: right;
}

.public-DraftStyleDefault-listLTR {
    direction: ltr;
}

.public-DraftStyleDefault-listRTL {
    direction: rtl;
}

.public-DraftStyleDefault-ol, .public-DraftStyleDefault-ul {
    margin: 16px 0;
    padding: 0;
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR {
    margin-left: 1.5em;
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listRTL {
    margin-right: 1.5em;
}

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listLTR {
    margin-left: 3em;
}

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listRTL {
    margin-right: 3em;
}

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listLTR {
    margin-left: 4.5em;
}

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listRTL {
    margin-right: 4.5em;
}

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listLTR {
    margin-left: 6em;
}

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listRTL {
    margin-right: 6em;
}

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listLTR {
    margin-left: 7.5em;
}

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listRTL {
    margin-right: 7.5em;
}

.public-DraftStyleDefault-unorderedListItem {
    list-style-type: square;
    position: relative;
}

.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth0 {
    list-style-type: disc;
}

.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth1 {
    list-style-type: circle;
}

.public-DraftStyleDefault-orderedListItem {
    list-style-type: none;
    position: relative;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
    left: -36px;
    position: absolute;
    text-align: right;
    width: 30px;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listRTL:before {
    position: absolute;
    right: -36px;
    text-align: left;
    width: 30px;
}

.public-DraftStyleDefault-orderedListItem:before {
    content: counter(ol0) ". ";
    counter-increment: ol0;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth1:before {
    content: counter(ol1) ". ";
    counter-increment: ol1;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth2:before {
    content: counter(ol2) ". ";
    counter-increment: ol2;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth3:before {
    content: counter(ol3) ". ";
    counter-increment: ol3;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth4:before {
    content: counter(ol4) ". ";
    counter-increment: ol4;
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-reset {
    counter-reset: ol0;
}

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-reset {
    counter-reset: ol1;
}

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-reset {
    counter-reset: ol2;
}

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-reset {
    counter-reset: ol3;
}

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-reset {
    counter-reset: ol4;
}

.rbc-btn {
    color: inherit;
    font: inherit;
    margin: 0;
}

button.rbc-btn {
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    cursor: pointer;
}

button[disabled].rbc-btn {
    cursor: not-allowed;
}

button.rbc-input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

.rbc-calendar {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
    box-sizing: inherit;
}

.rbc-abs-full, .rbc-row-bg {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.rbc-ellipsis, .rbc-show-more, .rbc-row-segment .rbc-event-content, .rbc-event-label {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.rbc-rtl {
    direction: rtl;
}

.rbc-off-range {
    color: #e6e7f1;
}

.rbc-off-range-bg {
    background: #f4f5fd;
}

.rbc-header {
    overflow: hidden;
    flex: 1 0 0%;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0.5rem 0;
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
    font-size: 90%;
    min-height: 0;
    border-bottom: 1px solid rgb(219.9375, 222, 240.5625);
}

.rbc-header + .rbc-header {
    border-left: 1px solid rgb(219.9375, 222, 240.5625);
}

.rbc-rtl .rbc-header + .rbc-header {
    border-left-width: 0;
    border-right: 1px solid rgb(219.9375, 222, 240.5625);
}

.rbc-header > a, .rbc-header > a:active, .rbc-header > a:visited {
    color: inherit;
    text-decoration: none;
}

.rbc-row-content {
    position: relative;
    user-select: none;
    outline: none;
    z-index: 4;
}

.rbc-today {
    background-color: #2F80ED;
}

.rbc-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background: #f8f9ff;
    border-radius: 0.65rem;
    padding: 1rem;
    margin-bottom: 1rem;
    flex-direction: column;
}

@media (min-width: 1920px) {
    .rbc-toolbar {
        flex-direction: row;
    }
}

.rbc-toolbar .rbc-toolbar-label {
    flex-grow: 1;
    padding: 1rem 0;
    font-weight: bold;
    text-align: center;
    font-size: 1.1875rem;
}

@media (min-width: 1920px) {
    .rbc-toolbar .rbc-toolbar-label {
        padding: 0;
    }
}

.rbc-toolbar button {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    outline: none !important;
    cursor: pointer;
    user-select: none;
    color: #fff;
    background-color: #2F80ED;
    border-color: #2F80ED;
    color: #2F80ED;
    background: transparent;
    border-width: 2px;
    border-style: solid;
    padding: 0.25rem 1.1rem;
    font-size: 0.83125rem;
    line-height: 1.5;
    border-radius: 0.29rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.rbc-toolbar button:hover {
    color: #fff;
    background-color: #2F80ED;
    border-color: #2F80ED;
}

.rbc-toolbar button:focus, .rbc-toolbar button.focus {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.rbc-toolbar button.disabled, .rbc-toolbar button:disabled {
    color: #fff;
    background-color: #2F80ED;
    border-color: #2F80ED;
}

.rbc-toolbar button:not(:disabled):not(.disabled):active, .rbc-toolbar button:not(:disabled):not(.disabled).active, .show > .rbc-toolbar button.dropdown-toggle {
    color: #fff;
    background-color: rgb(18.5575221239, 102.0663716814, 214.4424778761);
    border-color: rgb(17.5420353982, 96.4811946903, 202.7079646018);
}

.rbc-toolbar button:not(:disabled):not(.disabled):active:focus, .rbc-toolbar button:not(:disabled):not(.disabled).active:focus, .show > .rbc-toolbar button.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

@media (prefers-reduced-motion: reduce) {
    .rbc-toolbar button {
        transition: none;
    }
}

.rbc-toolbar button.rbc-active, .rbc-toolbar button:not(:disabled):active, .rbc-toolbar button:not(:disabled):focus, .rbc-toolbar button:not(:disabled):hover {
    background: #2F80ED;
    box-shadow: 0 0.25rem 0.55rem rgba(47, 128, 237, 0.35);
    color: #fff;
}

.rbc-btn-group {
    display: inline-block;
    white-space: nowrap;
}

.rbc-btn-group > button:first-child:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.rbc-btn-group > button:last-child:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rbc-rtl .rbc-btn-group > button:first-child:not(:last-child) {
    border-radius: 0.29rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rbc-rtl .rbc-btn-group > button:last-child:not(:first-child) {
    border-radius: 0.29rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.rbc-btn-group > button:not(:first-child):not(:last-child) {
    border-radius: 0;
}

.rbc-btn-group button + button {
    margin-left: -2px;
}

.rbc-rtl .rbc-btn-group button + button {
    margin-left: 0;
    margin-right: -2px;
}

.rbc-btn-group + .rbc-btn-group, .rbc-btn-group + button {
    margin-left: 10px;
}

.rbc-event {
    border: none;
    box-shadow: none;
    margin: 0;
    padding: 5px 10px;
    background-color: #2F80ED;
    border-radius: 0.29rem;
    color: #fff;
    cursor: pointer;
    width: 100%;
    text-align: left;
}

.rbc-slot-selecting .rbc-event {
    cursor: inherit;
    pointer-events: none;
}

.rbc-event.rbc-selected {
    background-color: rgb(18.5575221239, 102.0663716814, 214.4424778761);
}

.rbc-event:focus {
    outline: 5px auto transparent;
}

.rbc-event-label {
    font-size: 80%;
}

.rbc-event-overlaps {
    box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5);
}

.rbc-event-continues-prior {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rbc-event-continues-after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.rbc-event-continues-earlier {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.rbc-event-continues-later {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.rbc-row {
    display: flex;
    flex-direction: row;
}

.rbc-row-segment {
    padding: 0 1px 1px 1px;
}

.rbc-selected-cell {
    background-color: rgba(7, 9, 25, 0.1);
}

.rbc-show-more {
    background-color: rgba(255, 255, 255, 0.3);
    z-index: 4;
    font-weight: bold;
    font-size: 85%;
    height: auto;
    line-height: normal;
}

.rbc-month-view {
    position: relative;
    border: 1px solid rgb(219.9375, 222, 240.5625);
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    width: 100%;
    user-select: none;
    -webkit-user-select: none;
    height: 100%;
}

.rbc-month-header {
    display: flex;
    flex-direction: row;
}

.rbc-month-row {
    display: flex;
    position: relative;
    flex-direction: column;
    flex: 1 0 0;
    flex-basis: 0px;
    overflow: hidden;
    height: 100%;
}

.rbc-month-row + .rbc-month-row {
    border-top: 1px solid rgb(219.9375, 222, 240.5625);
}

.rbc-month-row:hover > div > .rbc-day-bg {
    background: #f8f9ff;
}

.rbc-month-row:hover > div > .rbc-day-bg:hover {
    background: #eeeff8;
}

.rbc-date-cell {
    flex: 1 1 0;
    min-width: 0;
    padding-right: 0.5rem;
    padding-top: 0.3333333333rem;
    text-align: right;
    font-size: 1.045rem;
}

.rbc-date-cell.rbc-now {
    font-weight: bold;
}

.rbc-date-cell > a, .rbc-date-cell > a:active, .rbc-date-cell > a:visited {
    color: inherit;
    text-decoration: none;
}

.rbc-row-bg {
    display: flex;
    flex-direction: row;
    flex: 1 0 0;
    overflow: hidden;
}

.rbc-day-bg {
    flex: 1 0 0%;
}

.rbc-day-bg + .rbc-day-bg {
    border-left: 1px solid rgb(219.9375, 222, 240.5625);
}

.rbc-rtl .rbc-day-bg + .rbc-day-bg {
    border-left-width: 0;
    border-right: 1px solid rgb(219.9375, 222, 240.5625);
}

.rbc-overlay {
    position: absolute;
    z-index: 5;
    border: 1px solid #eeeff8;
    background-color: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
    padding: 10px;
}

.rbc-overlay > * + * {
    margin-top: 1px;
}

.rbc-overlay-header {
    border-bottom: 1px solid #eeeff8;
    margin: -10px -10px 5px -10px;
    padding: 2px 10px;
}

.rbc-agenda-view {
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    overflow: auto;
}

.rbc-agenda-view table.rbc-agenda-table {
    width: 100%;
    border: 1px solid rgb(219.9375, 222, 240.5625);
    border-spacing: 0;
    border-collapse: collapse;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
    padding: 5px 10px;
    vertical-align: top;
}

.rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
    padding-left: 15px;
    padding-right: 15px;
    text-transform: lowercase;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
    border-left: 1px solid rgb(219.9375, 222, 240.5625);
}

.rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
    border-left-width: 0;
    border-right: 1px solid rgb(219.9375, 222, 240.5625);
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
    border-top: 1px solid rgb(219.9375, 222, 240.5625);
}

.rbc-agenda-view table.rbc-agenda-table thead > tr > th {
    padding: 3px 5px;
    text-align: left;
    border-bottom: 1px solid rgb(219.9375, 222, 240.5625);
}

.rbc-rtl .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
    text-align: right;
}

.rbc-agenda-time-cell {
    text-transform: lowercase;
}

.rbc-agenda-time-cell .rbc-continues-after:after {
    content: " »";
}

.rbc-agenda-time-cell .rbc-continues-prior:before {
    content: "« ";
}

.rbc-agenda-date-cell,
.rbc-agenda-time-cell {
    white-space: nowrap;
}

.rbc-agenda-event-cell {
    width: 100%;
}

.rbc-time-column {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.rbc-time-column .rbc-timeslot-group {
    flex: 1;
}

.rbc-timeslot-group {
    border-bottom: 1px solid rgb(219.9375, 222, 240.5625);
    min-height: 40px;
    display: flex;
    flex-flow: column nowrap;
}

.rbc-time-gutter,
.rbc-header-gutter {
    flex: none;
}

.rbc-label {
    padding: 0 5px;
}

.rbc-day-slot {
    position: relative;
}

.rbc-day-slot .rbc-events-container {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    margin-right: 10px;
    top: 0;
}

.rbc-day-slot .rbc-events-container.rbc-rtl {
    left: 10px;
    right: 0;
}

.rbc-day-slot .rbc-event {
    border: 1px solid rgb(37.7432432432, 89.3513513514, 133.2567567568);
    display: flex;
    max-height: 100%;
    min-height: 20px;
    flex-flow: column wrap;
    align-items: flex-start;
    overflow: hidden;
    position: absolute;
}

.rbc-day-slot .rbc-event-label {
    flex: none;
    padding-right: 5px;
    width: auto;
}

.rbc-day-slot .rbc-event-content {
    width: 100%;
    flex: 1 1 0;
    word-wrap: break-word;
    line-height: 1;
    height: 100%;
    min-height: 1em;
}

.rbc-day-slot .rbc-time-slot {
    border-top: 1px solid #eeeff8;
}

.rbc-time-view-resources .rbc-time-gutter,
.rbc-time-view-resources .rbc-time-header-gutter {
    position: sticky;
    left: 0;
    background-color: white;
    border-right: 1px solid rgb(219.9375, 222, 240.5625);
    z-index: 10;
    margin-right: -1px;
}

.rbc-time-view-resources .rbc-time-header {
    overflow: hidden;
}

.rbc-time-view-resources .rbc-time-header-content {
    min-width: auto;
    flex: 1 0 0;
    flex-basis: 0px;
}

.rbc-time-view-resources .rbc-time-header-cell-single-day {
    display: none;
}

.rbc-time-view-resources .rbc-day-slot {
    min-width: 140px;
}

.rbc-time-view-resources .rbc-header,
.rbc-time-view-resources .rbc-day-bg {
    width: 140px;
    flex: 1 1 0;
    flex-basis: 0px;
}

.rbc-time-header-content + .rbc-time-header-content {
    margin-left: -1px;
}

.rbc-time-slot {
    flex: 1 0 0;
}

.rbc-time-slot.rbc-now {
    font-weight: bold;
}

.rbc-day-header {
    text-align: center;
}

.rbc-slot-selection {
    z-index: 10;
    position: absolute;
    background-color: rgba(7, 9, 25, 0.5);
    color: #fff;
    font-size: 75%;
    width: 100%;
    padding: 3px;
}

.rbc-slot-selecting {
    cursor: move;
}

.rbc-time-view {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    border: 1px solid rgb(219.9375, 222, 240.5625);
    min-height: 0;
}

.rbc-time-view .rbc-time-gutter {
    white-space: nowrap;
}

.rbc-time-view .rbc-allday-cell {
    box-sizing: content-box;
    width: 100%;
    height: 100%;
    position: relative;
}

.rbc-time-view .rbc-allday-cell + .rbc-allday-cell {
    border-left: 1px solid rgb(219.9375, 222, 240.5625);
}

.rbc-time-view .rbc-allday-events {
    position: relative;
    z-index: 4;
}

.rbc-time-view .rbc-row {
    min-height: 20px;
}

.rbc-time-header {
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
}

.rbc-time-header.rbc-overflowing {
    border-right: 1px solid rgb(219.9375, 222, 240.5625);
}

.rbc-rtl .rbc-time-header.rbc-overflowing {
    border-right-width: 0;
    border-left: 1px solid rgb(219.9375, 222, 240.5625);
}

.rbc-time-header > .rbc-row:first-child {
    border-bottom: 1px solid rgb(219.9375, 222, 240.5625);
}

.rbc-time-header > .rbc-row.rbc-row-resource {
    border-bottom: 1px solid rgb(219.9375, 222, 240.5625);
}

.rbc-time-header-cell-single-day {
    display: none;
}

.rbc-time-header-content {
    flex: 1;
    display: flex;
    min-width: 0;
    flex-direction: column;
    border-left: 1px solid rgb(219.9375, 222, 240.5625);
}

.rbc-rtl .rbc-time-header-content {
    border-left-width: 0;
    border-right: 1px solid rgb(219.9375, 222, 240.5625);
}

.rbc-time-header-content > .rbc-row.rbc-row-resource {
    border-bottom: 1px solid rgb(219.9375, 222, 240.5625);
    flex-shrink: 0;
}

.rbc-time-content {
    display: flex;
    flex: 1 0 0%;
    align-items: flex-start;
    width: 100%;
    border-top: 2px solid rgb(219.9375, 222, 240.5625);
    overflow-y: auto;
    position: relative;
}

.rbc-time-content > .rbc-time-gutter {
    flex: none;
}

.rbc-time-content > * + * > * {
    border-left: 1px solid rgb(219.9375, 222, 240.5625);
}

.rbc-rtl .rbc-time-content > * + * > * {
    border-left-width: 0;
    border-right: 1px solid rgb(219.9375, 222, 240.5625);
}

.rbc-time-content > .rbc-day-slot {
    width: 100%;
    user-select: none;
    -webkit-user-select: none;
}

.rbc-current-time-indicator {
    position: absolute;
    z-index: 3;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #1bc943;
    pointer-events: none;
}

.slick-slider {
    position: relative;
    display: block;
    user-select: none;
    touch-action: pan-y;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
}

.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;
}

.slick-list:focus {
    outline: none;
}

.slick-list.dragging {
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.slick-track:before, .slick-track:after {
    content: "";
    display: table;
}

.slick-track:after {
    clear: both;
}

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    outline: none;
    display: none;
}

.slick-slide > div {
    outline: none;
}

.slick-slide > div > div {
    outline: none;
}

[dir=rtl] .slick-slide {
    float: right;
}

.slick-slide img {
    display: block;
}

.slick-slide.slick-loading img {
    display: none;
}

.slick-slide.dragging img {
    pointer-events: none;
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-loading .slick-slide {
    visibility: hidden;
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
    display: none;
}

.slick-slider-right .slick-arrow,
.slick-slider-left .slick-arrow {
    display: none !important;
}

.slick-slider-right .slick-list.draggable,
.slick-slider-left .slick-list.draggable {
    cursor: all-scroll;
}

.slick-slider-left .slick-dots {
    text-align: left;
}

.slick-slider-left .slick-dots li {
    margin: 0 0 0 0.6666666667rem;
}

.slick-slider-left .slick-dots {
    text-align: left;
}

.slick-slider-left .slick-dots li {
    margin: 0 0.6666666667rem 0 0;
}

/* Arrows */
.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    height: 38px;
    width: 38px;
    cursor: pointer;
    color: #2F80ED;
    top: 50%;
    padding: 0;
    border: none;
    outline: none;
    box-shadow: 0 0.313rem 0.8rem rgba(122, 123, 151, 0.5), 0 0.126rem 0.225rem rgba(122, 123, 151, 0.3);
    transition: all 0.2s ease-in-out;
    border-radius: 50px;
    background: #fff;
    z-index: 6;
    overflow: hidden;
    opacity: 0;
    font-family: "Pe-icon-7-stroke";
    margin-top: -19px;
}

.slick-prev::before,
.slick-next::before {
    font-size: 35px;
    height: 40px;
    line-height: 40px;
    width: 40px;
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    outline: none;
    color: #fff;
    background: #2F80ED;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: 0.25;
}

.slick-arrow i {
    position: absolute;
    left: 0;
    top: 0;
    width: 36px;
    height: 38px;
    line-height: 40px;
}

.slick-prev {
    left: -38px;
}

.slick-prev:before {
    content: "\e686";
}

.slick-next {
    right: -38px;
}

.slick-next:before {
    content: "\e684";
}

.slick-slider:hover .slick-next,
.slick-slider:hover .slick-prev {
    opacity: 1;
}

.slick-slider:hover .slick-next {
    right: -3px;
}

.slick-slider:hover .slick-prev {
    left: -3px;
}

/* Dots */
.slick-dots {
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 8px;
}

.slick-dots li {
    position: relative;
    display: inline-block;
    padding: 0;
    margin: 0 0.3333333333rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.slick-dots li button {
    border: 0;
    background: transparent;
    display: block;
    height: 8px;
    width: 8px;
    padding: 0;
    background: #d1d2db;
    cursor: pointer;
    position: relative;
    border-radius: 0.65rem;
    color: transparent;
    transition: all 0.2s ease-in-out;
    outline: none !important;
}

.slick-dots li.slick-active button {
    width: 26px;
    background: #2F80ED;
}

.slick-center {
    transform: scale(1.5);
}

.slick-slider .slide-img-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    opacity: 0.4;
    z-index: 10;
}

.slick-slider .slider-content {
    position: relative;
    z-index: 15;
    text-align: center;
    margin: 0 2rem;
}

.slick-slider .slider-content h3 {
    font-size: 1.6625rem;
    font-weight: normal;
    margin-bottom: 1rem;
}

.slick-slider .slider-content p {
    font-size: 0.95rem;
    opacity: 0.7;
}

.slider-light .slick-dots {
    position: absolute;
    bottom: 10px;
}

.slider-light .slick-dots li button {
    background: rgba(255, 255, 255, 0.25);
}

.slider-light .slick-prev,
.slider-light .slick-next {
    background: transparent;
    color: #fff;
    box-shadow: 0 0 0 0 transparent;
}

.slider-light .slick-prev:hover,
.slider-light .slick-next:hover {
    background: rgba(255, 255, 255, 0.15);
}

.slider-light .slick-next {
    right: 15px;
}

.slider-light .slick-prev {
    left: 15px;
}

.slider-light .slider-content {
    color: #fff;
}

.react-contextmenu {
    outline: none;
    opacity: 0;
    pointer-events: none;
    transition: opacity 250ms ease !important;
    margin-top: 0;
}

.react-contextmenu.rel-container {
    position: absolute !important;
    top: 15% !important;
    left: 50% !important;
}

.react-contextmenu-item {
    outline: none !important;
}

.react-contextmenu.react-contextmenu--visible {
    opacity: 1;
    pointer-events: auto;
    z-index: 9999;
    visibility: visible;
}

.react-contextmenu-item--divider {
    border-bottom: 1px solid #eeeff8;
    cursor: inherit;
    margin-bottom: 3px;
    padding: 2px 0;
}

.react-contextmenu-item.react-contextmenu-submenu {
    padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item:after {
    content: "▶";
    display: inline-block;
    position: absolute;
    right: 7px;
}

.example-multiple-targets::after {
    content: attr(data-count);
    display: block;
}

.cropper-container {
    direction: ltr;
    font-size: 0;
    line-height: 0;
    position: relative;
    touch-action: none;
    user-select: none;
}

.cropper-container img {
    display: block;
    height: 100%;
    image-orientation: 0deg;
    max-height: none !important;
    max-width: none !important;
    min-height: 0 !important;
    min-width: 0 !important;
    width: 100%;
}

.cropper-wrap-box, .cropper-canvas, .cropper-drag-box, .cropper-crop-box, .cropper-modal {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.cropper-wrap-box, .cropper-canvas {
    overflow: hidden;
}

.cropper-drag-box {
    background-color: #fff;
    opacity: 0;
}

.cropper-modal {
    background-color: #000;
    opacity: 0.5;
}

.cropper-view-box {
    display: block;
    height: 100%;
    outline: 1px solid #39f;
    outline-color: rgba(51, 153, 255, 0.75);
    overflow: hidden;
    width: 100%;
}

.cropper-dashed {
    border: 0 dashed #eee;
    display: block;
    opacity: 0.5;
    position: absolute;
}

.cropper-dashed.dashed-h {
    border-bottom-width: 1px;
    border-top-width: 1px;
    height: 33.3333333333%;
    left: 0;
    top: 33.3333333333%;
    width: 100%;
}

.cropper-dashed.dashed-v {
    border-left-width: 1px;
    border-right-width: 1px;
    height: 100%;
    left: 33.3333333333%;
    top: 0;
    width: 33.3333333333%;
}

.cropper-center {
    display: block;
    height: 0;
    left: 50%;
    opacity: 0.75;
    position: absolute;
    top: 50%;
    width: 0;
}

.cropper-center::before, .cropper-center::after {
    background-color: #eee;
    content: " ";
    display: block;
    position: absolute;
}

.cropper-center::before {
    height: 1px;
    left: -3px;
    top: 0;
    width: 7px;
}

.cropper-center::after {
    height: 7px;
    left: 0;
    top: -3px;
    width: 1px;
}

.cropper-face, .cropper-line, .cropper-point {
    display: block;
    height: 100%;
    opacity: 0.1;
    position: absolute;
    width: 100%;
}

.cropper-face {
    background-color: #fff;
    left: 0;
    top: 0;
}

.cropper-line {
    background-color: #39f;
}

.cropper-line.line-e {
    cursor: ew-resize;
    right: -3px;
    top: 0;
    width: 5px;
}

.cropper-line.line-n {
    cursor: ns-resize;
    height: 5px;
    left: 0;
    top: -3px;
}

.cropper-line.line-w {
    cursor: ew-resize;
    left: -3px;
    top: 0;
    width: 5px;
}

.cropper-line.line-s {
    bottom: -3px;
    cursor: ns-resize;
    height: 5px;
    left: 0;
}

.cropper-point {
    background-color: #39f;
    height: 5px;
    opacity: 0.75;
    width: 5px;
}

.cropper-point.point-e {
    cursor: ew-resize;
    margin-top: -3px;
    right: -3px;
    top: 50%;
}

.cropper-point.point-n {
    cursor: ns-resize;
    left: 50%;
    margin-left: -3px;
    top: -3px;
}

.cropper-point.point-w {
    cursor: ew-resize;
    left: -3px;
    margin-top: -3px;
    top: 50%;
}

.cropper-point.point-s {
    bottom: -3px;
    cursor: s-resize;
    left: 50%;
    margin-left: -3px;
}

.cropper-point.point-ne {
    cursor: nesw-resize;
    right: -3px;
    top: -3px;
}

.cropper-point.point-nw {
    cursor: nwse-resize;
    left: -3px;
    top: -3px;
}

.cropper-point.point-sw {
    bottom: -3px;
    cursor: nesw-resize;
    left: -3px;
}

.cropper-point.point-se {
    bottom: -3px;
    cursor: nwse-resize;
    height: 20px;
    opacity: 1;
    right: -3px;
    width: 20px;
}

@media (min-width: 768px) {
    .cropper-point.point-se {
        height: 15px;
        width: 15px;
    }
}

@media (min-width: 992px) {
    .cropper-point.point-se {
        height: 10px;
        width: 10px;
    }
}

@media (min-width: 1200px) {
    .cropper-point.point-se {
        height: 5px;
        opacity: 0.75;
        width: 5px;
    }
}

.cropper-point.point-se::before {
    background-color: #39f;
    bottom: -50%;
    content: " ";
    display: block;
    height: 200%;
    opacity: 0;
    position: absolute;
    right: -50%;
    width: 200%;
}

.cropper-invisible {
    opacity: 0;
}

.cropper-bg {
    background-image: url("widgets/react-cropper/cropper-bg.png");
}

.cropper-hide {
    display: block;
    height: 0;
    position: absolute;
    width: 0;
}

.cropper-hidden {
    display: none !important;
}

.cropper-move {
    cursor: move;
}

.cropper-crop {
    cursor: crosshair;
}

.cropper-disabled .cropper-drag-box, .cropper-disabled .cropper-face, .cropper-disabled .cropper-line, .cropper-disabled .cropper-point {
    cursor: not-allowed;
}

/* required styles */
.leaflet-pane,
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-tile-container,
.leaflet-pane > svg,
.leaflet-pane > canvas,
.leaflet-zoom-box,
.leaflet-image-layer,
.leaflet-layer {
    position: absolute;
    left: 0;
    top: 0;
}

.leaflet-container {
    overflow: hidden;
    min-height: 350px;
}

.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow {
    user-select: none;
}

/* Prevents IE11 from highlighting tiles in blue */
.leaflet-tile::selection {
    background: transparent;
}

/* Safari renders non-retina tile on retina better with this, but Chrome is worse */
.leaflet-safari .leaflet-tile {
    image-rendering: -webkit-optimize-contrast;
}

/* hack that prevents hw layers "stretching" when loading new tiles */
.leaflet-safari .leaflet-tile-container {
    width: 1600px;
    height: 1600px;
    -webkit-transform-origin: 0 0;
}

.leaflet-marker-icon,
.leaflet-marker-shadow {
    display: block;
}

/* .leaflet-container svg: reset svg max-width decleration shipped in Joomla! (joomla.org) 3.x */
/* .leaflet-container img: map is broken in FF if you have max-width: 100% on tiles */
.leaflet-container .leaflet-overlay-pane svg,
.leaflet-container .leaflet-marker-pane img,
.leaflet-container .leaflet-shadow-pane img,
.leaflet-container .leaflet-tile-pane img,
.leaflet-container img.leaflet-image-layer,
.leaflet-container .leaflet-tile {
    max-width: none !important;
    max-height: none !important;
}

.leaflet-container.leaflet-touch-zoom {
    -ms-touch-action: pan-x pan-y;
    touch-action: pan-x pan-y;
}

.leaflet-container.leaflet-touch-drag {
    -ms-touch-action: pinch-zoom;
    /* Fallback for FF which doesn't support pinch-zoom */
    touch-action: none;
    touch-action: pinch-zoom;
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
    -ms-touch-action: none;
    touch-action: none;
}

.leaflet-container {
    -webkit-tap-highlight-color: transparent;
}

.leaflet-container a {
    -webkit-tap-highlight-color: rgba(51, 181, 229, 0.4);
}

.leaflet-tile {
    filter: inherit;
    visibility: hidden;
}

.leaflet-tile-loaded {
    visibility: inherit;
}

.leaflet-zoom-box {
    width: 0;
    height: 0;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    z-index: 800;
}

/* workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=888319 */
.leaflet-overlay-pane svg {
    -moz-user-select: none;
}

.leaflet-pane {
    z-index: 400;
}

.leaflet-tile-pane {
    z-index: 200;
}

.leaflet-overlay-pane {
    z-index: 400;
}

.leaflet-shadow-pane {
    z-index: 500;
}

.leaflet-marker-pane {
    z-index: 600;
}

.leaflet-tooltip-pane {
    z-index: 650;
}

.leaflet-popup-pane {
    z-index: 700;
}

.leaflet-map-pane canvas {
    z-index: 100;
}

.leaflet-map-pane svg {
    z-index: 200;
}

.leaflet-vml-shape {
    width: 1px;
    height: 1px;
}

.lvml {
    behavior: url(#default#VML);
    display: inline-block;
    position: absolute;
}

/* control positioning */
.leaflet-control {
    position: relative;
    z-index: 800;
    pointer-events: visiblePainted; /* IE 9-10 doesn't have auto */
    pointer-events: auto;
}

.leaflet-top,
.leaflet-bottom {
    position: absolute;
    z-index: 1000;
    pointer-events: none;
}

.leaflet-top {
    top: 0;
}

.leaflet-right {
    right: 0;
}

.leaflet-bottom {
    bottom: 0;
}

.leaflet-left {
    left: 0;
}

.leaflet-control {
    float: left;
    clear: both;
}

.leaflet-right .leaflet-control {
    float: right;
}

.leaflet-top .leaflet-control {
    margin-top: 10px;
}

.leaflet-bottom .leaflet-control {
    margin-bottom: 10px;
}

.leaflet-left .leaflet-control {
    margin-left: 10px;
}

.leaflet-right .leaflet-control {
    margin-right: 10px;
}

/* zoom and fade animations */
.leaflet-fade-anim .leaflet-tile {
    will-change: opacity;
}

.leaflet-fade-anim .leaflet-popup {
    opacity: 0;
    -webkit-transition: opacity 0.2s linear;
    -moz-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear;
}

.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
    opacity: 1;
}

.leaflet-zoom-animated {
    -webkit-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
    will-change: transform;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
    -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
    -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
    transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1);
}

.leaflet-zoom-anim .leaflet-tile,
.leaflet-pan-anim .leaflet-tile {
    -webkit-transition: none;
    -moz-transition: none;
    transition: none;
}

.leaflet-zoom-anim .leaflet-zoom-hide {
    visibility: hidden;
}

/* cursors */
.leaflet-interactive {
    cursor: pointer;
}

.leaflet-grab {
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: grab;
}

.leaflet-crosshair,
.leaflet-crosshair .leaflet-interactive {
    cursor: crosshair;
}

.leaflet-popup-pane,
.leaflet-control {
    cursor: auto;
}

.leaflet-dragging .leaflet-grab,
.leaflet-dragging .leaflet-grab .leaflet-interactive,
.leaflet-dragging .leaflet-marker-draggable {
    cursor: move;
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: grabbing;
}

/* marker & overlays interactivity */
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-image-layer,
.leaflet-pane > svg path,
.leaflet-tile-container {
    pointer-events: none;
}

.leaflet-marker-icon.leaflet-interactive,
.leaflet-image-layer.leaflet-interactive,
.leaflet-pane > svg path.leaflet-interactive,
svg.leaflet-image-layer.leaflet-interactive path {
    pointer-events: visiblePainted; /* IE 9-10 doesn't have auto */
    pointer-events: auto;
}

/* visual tweaks */
.leaflet-container {
    background: #e6e7f1;
    outline: 0;
}

.leaflet-container a {
    color: #0078A8;
}

.leaflet-container a.leaflet-active {
    outline: 2px solid orange;
}

.leaflet-zoom-box {
    border: 2px dotted #38f;
    background: rgba(255, 255, 255, 0.5);
}

/* general typography */
.leaflet-container {
    font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
}

/* general toolbar styles */
.leaflet-bar {
    box-shadow: 0 1px 5px rgba(7, 9, 25, 0.35);
    border-radius: 4px;
}

.leaflet-bar a,
.leaflet-bar a:hover {
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    width: 26px;
    height: 26px;
    line-height: 26px;
    display: block;
    text-align: center;
    text-decoration: none;
    color: black;
}

.leaflet-bar a,
.leaflet-control-layers-toggle {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    display: block;
}

.leaflet-bar a:hover {
    background-color: #f4f4f4;
}

.leaflet-bar a:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.leaflet-bar a:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom: none;
}

.leaflet-bar a.leaflet-disabled {
    cursor: default;
    background-color: #f4f4f4;
    color: #bbb;
}

.leaflet-touch .leaflet-bar a {
    width: 30px;
    height: 30px;
    line-height: 30px;
}

.leaflet-touch .leaflet-bar a:first-child {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}

.leaflet-touch .leaflet-bar a:last-child {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
}

/* zoom control */
.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
    font: bold 18px "Lucida Console", Monaco, monospace;
    text-indent: 1px;
}

.leaflet-touch .leaflet-control-zoom-in, .leaflet-touch .leaflet-control-zoom-out {
    font-size: 22px;
}

/* layers control */
.leaflet-control-layers {
    box-shadow: 0 1px 5px rgba(7, 9, 25, 0.6);
    background: #fff;
    border-radius: 5px;
}

.leaflet-control-layers-toggle {
    width: 36px;
    height: 36px;
}

.leaflet-retina .leaflet-control-layers-toggle {
    background-size: 26px 26px;
}

.leaflet-touch .leaflet-control-layers-toggle {
    width: 44px;
    height: 44px;
}

.leaflet-control-layers .leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-toggle {
    display: none;
}

.leaflet-control-layers-expanded .leaflet-control-layers-list {
    display: block;
    position: relative;
}

.leaflet-control-layers-expanded {
    padding: 6px 10px 6px 6px;
    color: #3b3e66;
    background: #fff;
}

.leaflet-control-layers-scrollbar {
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 5px;
}

.leaflet-control-layers-selector {
    margin-top: 2px;
    position: relative;
    top: 1px;
}

.leaflet-control-layers label {
    display: block;
}

.leaflet-control-layers-separator {
    height: 0;
    border-top: 1px solid #e6e7f1;
    margin: 5px -10px 5px -6px;
}

/* attribution and scale controls */
.leaflet-container .leaflet-control-attribution {
    background: #fff;
    background: rgba(255, 255, 255, 0.3);
    margin: 0;
}

.leaflet-control-attribution,
.leaflet-control-scale-line {
    padding: 0 5px;
    color: #3b3e66;
}

.leaflet-control-attribution a {
    text-decoration: none;
}

.leaflet-control-attribution a:hover {
    text-decoration: underline;
}

.leaflet-container .leaflet-control-attribution,
.leaflet-container .leaflet-control-scale {
    font-size: 11px;
}

.leaflet-left .leaflet-control-scale {
    margin-left: 5px;
}

.leaflet-bottom .leaflet-control-scale {
    margin-bottom: 5px;
}

.leaflet-control-scale-line {
    border: 2px solid #777;
    border-top: none;
    line-height: 1.1;
    padding: 2px 5px 1px;
    font-size: 11px;
    white-space: nowrap;
    overflow: hidden;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: #fff;
    background: rgba(255, 255, 255, 0.5);
}

.leaflet-control-scale-line:not(:first-child) {
    border-top: 2px solid #777;
    border-bottom: none;
    margin-top: -2px;
}

.leaflet-control-scale-line:not(:first-child):not(:last-child) {
    border-bottom: 2px solid #777;
}

.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
    box-shadow: none;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
    border: 2px solid rgba(7, 9, 25, 0.8);
    background-clip: padding-box;
}

/* popup */
.leaflet-popup {
    position: absolute;
    text-align: center;
    margin-bottom: 20px;
}

.leaflet-popup-content-wrapper {
    padding: 1px;
    text-align: left;
    border-radius: 12px;
}

.leaflet-popup-content {
    margin: 13px 19px;
    line-height: 1.4;
}

.leaflet-popup-content p {
    margin: 18px 0;
}

.leaflet-popup-tip-container {
    width: 40px;
    height: 20px;
    position: absolute;
    left: 50%;
    margin-left: -20px;
    overflow: hidden;
    pointer-events: none;
}

.leaflet-popup-tip {
    width: 17px;
    height: 17px;
    padding: 1px;
    margin: -10px auto 0;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
    background: white;
    color: #3b3e66;
    box-shadow: 0 3px 14px rgba(7, 9, 25, 0.6);
}

.leaflet-container a.leaflet-popup-close-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 4px 4px 0 0;
    border: none;
    text-align: center;
    width: 18px;
    height: 14px;
    color: #d1d2db;
    text-decoration: none;
    font-weight: bold;
    background: transparent;
}

.leaflet-container a.leaflet-popup-close-button:hover {
    color: #7a7b97;
}

.leaflet-popup-scrolled {
    overflow: auto;
    border-bottom: 1px solid #e6e7f1;
    border-top: 1px solid #e6e7f1;
}

.leaflet-oldie .leaflet-popup-content-wrapper {
    -ms-zoom: 1;
}

.leaflet-oldie .leaflet-popup-tip {
    width: 24px;
    margin: 0 auto;
    -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
    filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678);
}

.leaflet-oldie .leaflet-popup-tip-container {
    margin-top: -1px;
}

.leaflet-oldie .leaflet-control-zoom,
.leaflet-oldie .leaflet-control-layers,
.leaflet-oldie .leaflet-popup-content-wrapper,
.leaflet-oldie .leaflet-popup-tip {
    border: 1px solid #7a7b97;
}

/* div icon */
.leaflet-div-icon {
    background: #fff;
    border: 1px solid #d1d2db;
}

/* Tooltip */
/* Base styles for the element that has a tooltip */
.leaflet-tooltip {
    position: absolute;
    padding: 6px;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 3px;
    color: #222;
    white-space: nowrap;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    box-shadow: 0 1px 3px rgba(7, 9, 25, 0.6);
}

.leaflet-tooltip.leaflet-clickable {
    cursor: pointer;
    pointer-events: auto;
}

.leaflet-tooltip-top:before,
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
    position: absolute;
    pointer-events: none;
    border: 6px solid transparent;
    background: transparent;
    content: "";
}

/* Directions */
.leaflet-tooltip-bottom {
    margin-top: 6px;
}

.leaflet-tooltip-top {
    margin-top: -6px;
}

.leaflet-tooltip-bottom:before,
.leaflet-tooltip-top:before {
    left: 50%;
    margin-left: -6px;
}

.leaflet-tooltip-top:before {
    bottom: 0;
    margin-bottom: -12px;
    border-top-color: #fff;
}

.leaflet-tooltip-bottom:before {
    top: 0;
    margin-top: -12px;
    margin-left: -6px;
    border-bottom-color: #fff;
}

.leaflet-tooltip-left {
    margin-left: -6px;
}

.leaflet-tooltip-right {
    margin-left: 6px;
}

.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
    top: 50%;
    margin-top: -6px;
}

.leaflet-tooltip-left:before {
    right: 0;
    margin-right: -12px;
    border-left-color: #fff;
}

.leaflet-tooltip-right:before {
    left: 0;
    margin-left: -12px;
    border-right-color: #fff;
}

/*
 * Container style
 */
.ps {
    overflow: hidden !important;
    overflow-anchor: none;
    touch-action: auto;
}

/*
 * Scrollbar rail styles
 */
.ps__rail-x {
    display: none !important;
    opacity: 0;
    transition: background-color 0.2s linear, opacity 0.2s linear;
    height: 15px;
    /* there must be 'bottom' or 'top' for ps__rail-x */
    bottom: 0;
    /* please don't change 'position' */
    position: absolute;
    z-index: 7;
}

.ps__rail-y {
    display: none;
    opacity: 0;
    transition: background-color 0.2s linear, opacity 0.2s linear;
    width: 15px;
    /* there must be 'right' or 'left' for ps__rail-y */
    right: 0;
    /* please don't change 'position' */
    position: absolute;
    border-radius: 50px;
    z-index: 7;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
    display: block;
    background-color: transparent;
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
    opacity: 0.6;
}

.ps__rail-x:hover,
.ps__rail-y:hover,
.ps__rail-x:focus,
.ps__rail-y:focus {
    background-color: rgba(0, 0, 0, 0.1);
    opacity: 0.9;
}

/*
 * Scrollbar thumb styles
 */
.ps__thumb-x {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    transition: background-color 0.2s linear, height 0.2s ease-in-out;
    height: 6px;
    /* there must be 'bottom' for ps__thumb-x */
    bottom: 2px;
    /* please don't change 'position' */
    position: absolute;
}

.ps__thumb-y {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    transition: background-color 0.2s linear, width 0.2s ease-in-out;
    width: 6px;
    /* there must be 'right' for ps__thumb-y */
    right: 2px;
    /* please don't change 'position' */
    position: absolute;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x {
    background-color: rgba(0, 0, 0, 0.12);
    height: 11px;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y {
    background-color: rgba(0, 0, 0, 0.12);
    width: 11px;
}

/* MS supports */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .ps {
        overflow: auto !important;
    }
}

.scrollbar-container {
    position: relative;
    height: 100%;
}

.scroll-area {
    overflow-x: hidden;
    height: 400px;
}

.scroll-area-xs {
    height: 150px;
    overflow-x: hidden;
}

.scroll-area-sm {
    height: 200px;
    overflow-x: hidden;
}

.scroll-area-md {
    height: 300px;
    overflow-x: hidden;
}

.scroll-area-lg {
    height: 400px;
    overflow-x: hidden;
}

.scroll-area-x {
    overflow-x: auto;
    width: 100%;
    max-width: 100%;
}

.shadow-overflow {
    position: relative;
}

.shadow-overflow::after, .shadow-overflow::before {
    width: 100%;
    bottom: auto;
    top: 0;
    left: 0;
    height: 1rem;
    position: absolute;
    z-index: 10;
    content: "";
    background: linear-gradient(to bottom, rgb(255, 255, 255) 20%, rgba(255, 255, 255, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#00ffffff", GradientType=0);
}

.shadow-overflow::after {
    bottom: 0;
    top: auto;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 80%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00ffffff", endColorstr="#ffffff", GradientType=0);
}

.hamburger {
    padding: 0px 0px;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
}

.hamburger:hover {
    opacity: 0.7;
}

.hamburger.is-active:hover {
    opacity: 0.7;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
    background-color: #070919;
}

.hamburger-box {
    width: 24px;
    height: 14px;
    display: inline-block;
    position: relative;
}

.hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -1px;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 24px;
    height: 2px;
    background-color: #070919;
    border-radius: 10px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
}

.hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block;
}

.hamburger-inner::before {
    top: -6px;
}

.hamburger-inner::after {
    bottom: -6px;
}

/*
 * Elastic
 */
.hamburger--elastic.collapsed.is-active .hamburger-inner,
.hamburger--elastic .hamburger-inner {
    top: 1px;
    transition-duration: 0.275s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic.collapsed.is-active .hamburger-inner::before,
.hamburger--elastic .hamburger-inner::before {
    top: 6px;
    transition: opacity 0.125s 0.275s ease;
}

.hamburger--elastic.collapsed.is-active .hamburger-inner::after,
.hamburger--elastic .hamburger-inner::after {
    top: 12px;
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic.is-active:not(.collapsed) .hamburger-inner,
.hamburger--elastic.active .hamburger-inner {
    transform: translate3d(0, 6px, 0) rotate(135deg);
    transition-delay: 0.075s;
}

.hamburger--elastic.is-active:not(.collapsed) .hamburger-inner::before,
.hamburger--elastic.active .hamburger-inner::before {
    transition-delay: 0s;
    opacity: 0;
}

.hamburger--elastic.is-active:not(.collapsed) .hamburger-inner::after,
.hamburger--elastic.active .hamburger-inner::after {
    transform: translate3d(0, -12px, 0) rotate(-270deg);
    transition-delay: 0.075s;
}

.navbar-toggler {
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar-toggler:focus, .navbar-toggler:active {
    outline: none;
}

.avatar-icon-wrapper {
    display: inline-block;
    margin-right: 0.25rem;
    position: relative;
    font-size: 1.2rem;
}

.avatar-icon-wrapper .badge {
    position: absolute;
    right: 0;
    bottom: 0;
}

.avatar-icon {
    display: block;
    width: 44px;
    height: 44px;
    line-height: 44px;
    transition: all 0.2s ease-in-out;
    opacity: 1;
    border-radius: 50px;
}

.avatar-icon.rounded {
    border-radius: 0.65rem !important;
}

.avatar-icon-xl {
    font-size: 1.9rem;
}

.avatar-icon-xl .avatar-icon {
    width: 64px;
    height: 64px;
    line-height: 62px;
}

.avatar-icon-lg {
    font-size: 1.6rem;
}

.avatar-icon-lg .avatar-icon {
    width: 54px;
    height: 54px;
    line-height: 52px;
}

.avatar-icon-sm {
    font-size: 0.95rem;
}

.avatar-icon-sm .avatar-icon {
    width: 34px;
    height: 34px;
    line-height: 32px;
}

.avatar-icon-xs {
    font-size: 0.75rem;
    font-weight: bold;
}

.avatar-icon-xs .avatar-icon {
    width: 26px;
    height: 26px;
    line-height: 26px;
    transition: transform 0.2s;
}

.avatar-icon {
    box-shadow: 0 0 0 3px #fff;
    overflow: hidden;
}

.avatar-icon img {
    width: 100%;
    height: 100%;
    display: block;
}

.avatar-wrapper-overlap {
    display: flex;
}

.avatar-wrapper-overlap .avatar-icon-wrapper {
    z-index: 5;
    margin-left: -18px;
}

.avatar-wrapper-overlap .avatar-icon-wrapper:hover {
    z-index: 7;
}

.avatar-wrapper-overlap .avatar-icon-wrapper.avatar-icon-xl {
    margin-left: -30px;
}

.avatar-wrapper-overlap .avatar-icon-wrapper.avatar-icon-lg {
    margin-left: -24px;
}

.avatar-wrapper-overlap .avatar-icon-wrapper.avatar-icon-sm {
    margin-left: -14px;
}

.avatar-wrapper-overlap .avatar-icon-wrapper.avatar-icon-xs {
    margin-left: -10px;
}

.avatar-wrapper-overlap .avatar-icon-wrapper:first-child {
    margin-left: 0 !important;
}

.avatar-initials {
    text-align: center;
    text-transform: uppercase;
}

.badge {
    text-transform: uppercase;
    height: 20px;
    line-height: 21px;
}

.badge-neutral-primary {
    background-color: rgb(235.6310344828, 237.5206896552, 246.9689655172) !important;
}

.badge-neutral-secondary {
    background-color: white !important;
}

.badge-neutral-success {
    background-color: rgb(229.1709677419, 249.4290322581, 237.2741935484) !important;
}

.badge-neutral-info {
    background-color: rgb(231.8678571429, 251.3714285714, 252.7321428571) !important;
}

.badge-neutral-warning {
    background-color: rgb(255, 251.1923076923, 241.5) !important;
}

.badge-neutral-danger {
    background-color: rgb(255, 244.6, 245.8682926829) !important;
}

.badge-neutral-dark {
    background-color: rgb(245.2125, 245.2125, 247.3875) !important;
}

.badge-neutral-first {
    background-color: rgb(234.0115384615, 245.9461538462, 254.5884615385) !important;
}

.badge-neutral-second {
    background-color: rgb(225.1214285714, 226.3928571429, 231.4785714286) !important;
}

.btn-default {
    color: #fff;
    background-color: #2F80ED;
    border-color: #2F80ED;
    box-shadow: 0 0.25rem 0.55rem rgba(47, 128, 237, 0.35);
}

.btn-default:hover {
    color: #fff;
    background-color: #2F80ED;
    border-color: #2F80ED;
}

.btn-default:focus, .btn-default.focus {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.btn-default.disabled, .btn-default:disabled {
    color: #fff;
    background-color: #2F80ED;
    border-color: #2F80ED;
}

.btn-default:not(:disabled):not(.disabled):active, .btn-default:not(:disabled):not(.disabled).active, .show > .btn-default.dropdown-toggle {
    color: #fff;
    background-color: rgb(18.5575221239, 102.0663716814, 214.4424778761);
    border-color: rgb(17.5420353982, 96.4811946903, 202.7079646018);
}

.btn-default:not(:disabled):not(.disabled):active:focus, .btn-default:not(:disabled):not(.disabled).active:focus, .show > .btn-default.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.btn-default:not(:disabled):active, .btn-default:not(:disabled):focus, .btn-default:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(47, 128, 237, 0.4), 0 0.0625rem 0.385rem rgba(47, 128, 237, 0.54);
}

.btn-wrapper--icon {
    display: inline-flex;
}

.btn-wrapper--label:not(:first-child) {
    margin-left: 0.5rem;
}

.btn-wrapper--label {
    display: inline-block;
}

.btn-wrapper--label + .btn-wrapper--icon {
    margin-left: 0.5rem;
}

.btn-pill {
    border-radius: 4.5rem;
}

.btn-square {
    border-radius: 1px;
}

.btn-icon-transition {
    transition: all 0.2s ease-in-out;
}

.btn:hover .btn-icon-transition {
    transform: scale(1.1);
}

.btn-primary {
    box-shadow: 0 0.25rem 0.55rem rgba(47, 128, 237, 0.35);
}

.btn-primary:not(:disabled):active, .btn-primary:not(:disabled):focus, .btn-primary:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(47, 128, 237, 0.4), 0 0.0625rem 0.385rem rgba(47, 128, 237, 0.54);
}

.btn-primary:focus .btn-wrapper--icon svg path, .btn-primary.active .btn-wrapper--icon svg path, .btn-primary:hover .btn-wrapper--icon svg path {
    fill: #fff;
}

.btn-secondary {
    box-shadow: 0 0.25rem 0.55rem rgba(248, 249, 255, 0.35);
}

.btn-secondary:not(:disabled):active, .btn-secondary:not(:disabled):focus, .btn-secondary:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(248, 249, 255, 0.4), 0 0.0625rem 0.385rem rgba(248, 249, 255, 0.54);
}

.btn-secondary:focus .btn-wrapper--icon svg path, .btn-secondary.active .btn-wrapper--icon svg path, .btn-secondary:hover .btn-wrapper--icon svg path {
    fill: #3b3e66;
}

.btn-success {
    box-shadow: 0 0.25rem 0.55rem rgba(27, 201, 67, 0.35);
}

.btn-success:not(:disabled):active, .btn-success:not(:disabled):focus, .btn-success:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(27, 201, 67, 0.4), 0 0.0625rem 0.385rem rgba(27, 201, 67, 0.54);
}

.btn-success:focus .btn-wrapper--icon svg path, .btn-success.active .btn-wrapper--icon svg path, .btn-success:hover .btn-wrapper--icon svg path {
    fill: #fff;
}

.btn-info {
    box-shadow: 0 0.25rem 0.55rem rgba(17, 197, 219, 0.35);
}

.btn-info:not(:disabled):active, .btn-info:not(:disabled):focus, .btn-info:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(17, 197, 219, 0.4), 0 0.0625rem 0.385rem rgba(17, 197, 219, 0.54);
}

.btn-info:focus .btn-wrapper--icon svg path, .btn-info.active .btn-wrapper--icon svg path, .btn-info:hover .btn-wrapper--icon svg path {
    fill: #fff;
}

.btn-warning {
    box-shadow: 0 0.25rem 0.55rem rgba(244, 119, 46, 0.35);
}

.btn-warning:not(:disabled):active, .btn-warning:not(:disabled):focus, .btn-warning:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(244, 119, 46, 0.4), 0 0.0625rem 0.385rem rgba(244, 119, 46, 0.54);
}

.btn-warning:focus .btn-wrapper--icon svg path, .btn-warning.active .btn-wrapper--icon svg path, .btn-warning:hover .btn-wrapper--icon svg path {
    fill: #fff;
}

.btn-danger {
    box-shadow: 0 0.25rem 0.55rem rgba(248, 50, 69, 0.35);
}

.btn-danger:not(:disabled):active, .btn-danger:not(:disabled):focus, .btn-danger:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(248, 50, 69, 0.4), 0 0.0625rem 0.385rem rgba(248, 50, 69, 0.54);
}

.btn-danger:focus .btn-wrapper--icon svg path, .btn-danger.active .btn-wrapper--icon svg path, .btn-danger:hover .btn-wrapper--icon svg path {
    fill: #fff;
}

.btn-light {
    box-shadow: 0 0.25rem 0.55rem rgba(244, 245, 253, 0.35);
}

.btn-light:not(:disabled):active, .btn-light:not(:disabled):focus, .btn-light:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(244, 245, 253, 0.4), 0 0.0625rem 0.385rem rgba(244, 245, 253, 0.54);
}

.btn-light:focus .btn-wrapper--icon svg path, .btn-light.active .btn-wrapper--icon svg path, .btn-light:hover .btn-wrapper--icon svg path {
    fill: #3b3e66;
}

.btn-gray {
    box-shadow: 0 0.25rem 0.55rem rgba(248, 249, 255, 0.35);
}

.btn-gray:not(:disabled):active, .btn-gray:not(:disabled):focus, .btn-gray:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(248, 249, 255, 0.4), 0 0.0625rem 0.385rem rgba(248, 249, 255, 0.54);
}

.btn-gray:focus .btn-wrapper--icon svg path, .btn-gray.active .btn-wrapper--icon svg path, .btn-gray:hover .btn-wrapper--icon svg path {
    fill: #3b3e66;
}

.btn-dark {
    box-shadow: 0 0.25rem 0.55rem rgba(122, 123, 151, 0.35);
}

.btn-dark:not(:disabled):active, .btn-dark:not(:disabled):focus, .btn-dark:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(122, 123, 151, 0.4), 0 0.0625rem 0.385rem rgba(122, 123, 151, 0.54);
}

.btn-dark:focus .btn-wrapper--icon svg path, .btn-dark.active .btn-wrapper--icon svg path, .btn-dark:hover .btn-wrapper--icon svg path {
    fill: #fff;
}

.btn-first {
    box-shadow: 0 0.25rem 0.55rem rgba(47, 128, 237, 0.35);
}

.btn-first:not(:disabled):active, .btn-first:not(:disabled):focus, .btn-first:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(47, 128, 237, 0.4), 0 0.0625rem 0.385rem rgba(47, 128, 237, 0.54);
}

.btn-first:focus .btn-wrapper--icon svg path, .btn-first.active .btn-wrapper--icon svg path, .btn-first:hover .btn-wrapper--icon svg path {
    fill: #fff;
}

.btn-second {
    box-shadow: 0 0.25rem 0.55rem rgba(7, 9, 25, 0.35);
}

.btn-second:not(:disabled):active, .btn-second:not(:disabled):focus, .btn-second:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(7, 9, 25, 0.4), 0 0.0625rem 0.385rem rgba(7, 9, 25, 0.54);
}

.btn-second:focus .btn-wrapper--icon svg path, .btn-second.active .btn-wrapper--icon svg path, .btn-second:hover .btn-wrapper--icon svg path {
    fill: #fff;
}

.btn-neutral-primary {
    background: rgba(47, 128, 237, 0.15);
    color: #2F80ED;
    border-color: transparent;
}

.btn-neutral-primary.active, .btn-neutral-primary:focus, .btn-neutral-primary:hover {
    background: #2F80ED;
    color: #fff;
}

.btn-neutral-primary.active .btn-wrapper--icon svg path, .btn-neutral-primary:focus .btn-wrapper--icon svg path, .btn-neutral-primary:hover .btn-wrapper--icon svg path {
    fill: #fff;
}

.btn-neutral-secondary {
    background: rgba(248, 249, 255, 0.15);
    color: #f8f9ff;
    border-color: transparent;
    color: #3b3e66;
}

.btn-neutral-secondary.active, .btn-neutral-secondary:focus, .btn-neutral-secondary:hover {
    background: #f8f9ff;
    color: #3b3e66;
}

.btn-neutral-secondary.active .btn-wrapper--icon svg path, .btn-neutral-secondary:focus .btn-wrapper--icon svg path, .btn-neutral-secondary:hover .btn-wrapper--icon svg path {
    fill: #3b3e66;
}

.btn-neutral-success {
    background: rgba(27, 201, 67, 0.15);
    color: #1bc943;
    border-color: transparent;
}

.btn-neutral-success.active, .btn-neutral-success:focus, .btn-neutral-success:hover {
    background: #1bc943;
    color: #fff;
}

.btn-neutral-success.active .btn-wrapper--icon svg path, .btn-neutral-success:focus .btn-wrapper--icon svg path, .btn-neutral-success:hover .btn-wrapper--icon svg path {
    fill: #fff;
}

.btn-neutral-info {
    background: rgba(17, 197, 219, 0.15);
    color: #11c5db;
    border-color: transparent;
}

.btn-neutral-info.active, .btn-neutral-info:focus, .btn-neutral-info:hover {
    background: #11c5db;
    color: #fff;
}

.btn-neutral-info.active .btn-wrapper--icon svg path, .btn-neutral-info:focus .btn-wrapper--icon svg path, .btn-neutral-info:hover .btn-wrapper--icon svg path {
    fill: #fff;
}

.btn-neutral-warning {
    background: rgba(244, 119, 46, 0.15);
    color: #f4772e;
    border-color: transparent;
}

.btn-neutral-warning.active, .btn-neutral-warning:focus, .btn-neutral-warning:hover {
    background: #f4772e;
    color: #fff;
}

.btn-neutral-warning.active .btn-wrapper--icon svg path, .btn-neutral-warning:focus .btn-wrapper--icon svg path, .btn-neutral-warning:hover .btn-wrapper--icon svg path {
    fill: #fff;
}

.btn-neutral-danger {
    background: rgba(248, 50, 69, 0.15);
    color: #f83245;
    border-color: transparent;
}

.btn-neutral-danger.active, .btn-neutral-danger:focus, .btn-neutral-danger:hover {
    background: #f83245;
    color: #fff;
}

.btn-neutral-danger.active .btn-wrapper--icon svg path, .btn-neutral-danger:focus .btn-wrapper--icon svg path, .btn-neutral-danger:hover .btn-wrapper--icon svg path {
    fill: #fff;
}

.btn-neutral-light {
    background: rgba(244, 245, 253, 0.15);
    color: #f4f5fd;
    border-color: transparent;
}

.btn-neutral-light.active, .btn-neutral-light:focus, .btn-neutral-light:hover {
    background: #f4f5fd;
    color: #3b3e66;
}

.btn-neutral-light.active .btn-wrapper--icon svg path, .btn-neutral-light:focus .btn-wrapper--icon svg path, .btn-neutral-light:hover .btn-wrapper--icon svg path {
    fill: #3b3e66;
}

.btn-neutral-gray {
    background: rgba(248, 249, 255, 0.15);
    color: #f8f9ff;
    border-color: transparent;
}

.btn-neutral-gray.active, .btn-neutral-gray:focus, .btn-neutral-gray:hover {
    background: #f8f9ff;
    color: #3b3e66;
}

.btn-neutral-gray.active .btn-wrapper--icon svg path, .btn-neutral-gray:focus .btn-wrapper--icon svg path, .btn-neutral-gray:hover .btn-wrapper--icon svg path {
    fill: #3b3e66;
}

.btn-neutral-dark {
    background: rgba(122, 123, 151, 0.15);
    color: #7a7b97;
    border-color: transparent;
}

.btn-neutral-dark.active, .btn-neutral-dark:focus, .btn-neutral-dark:hover {
    background: #7a7b97;
    color: #fff;
}

.btn-neutral-dark.active .btn-wrapper--icon svg path, .btn-neutral-dark:focus .btn-wrapper--icon svg path, .btn-neutral-dark:hover .btn-wrapper--icon svg path {
    fill: #fff;
}

.btn-neutral-first {
    background: rgba(47, 128, 237, 0.15);
    color: #2F80ED;
    border-color: transparent;
}

.btn-neutral-first.active, .btn-neutral-first:focus, .btn-neutral-first:hover {
    background: #2F80ED;
    color: #fff;
}

.btn-neutral-first.active .btn-wrapper--icon svg path, .btn-neutral-first:focus .btn-wrapper--icon svg path, .btn-neutral-first:hover .btn-wrapper--icon svg path {
    fill: #fff;
}

.btn-neutral-second {
    background: rgba(7, 9, 25, 0.15);
    color: #070919;
    border-color: transparent;
}

.btn-neutral-second.active, .btn-neutral-second:focus, .btn-neutral-second:hover {
    background: #070919;
    color: #fff;
}

.btn-neutral-second.active .btn-wrapper--icon svg path, .btn-neutral-second:focus .btn-wrapper--icon svg path, .btn-neutral-second:hover .btn-wrapper--icon svg path {
    fill: #fff;
}

.btn-facebook {
    box-shadow: 0 0.25rem 0.55rem rgba(59, 89, 153, 0.35);
    color: #fff;
    background-color: #3b5999;
    border-color: #3b5999;
}

.btn-facebook:hover {
    color: #fff;
    background-color: #3b5999;
    border-color: #3b5999;
}

.btn-facebook:focus, .btn-facebook.focus {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.btn-facebook.disabled, .btn-facebook:disabled {
    color: #fff;
    background-color: #3b5999;
    border-color: #3b5999;
}

.btn-facebook:not(:disabled):not(.disabled):active, .btn-facebook:not(:disabled):not(.disabled).active, .show > .btn-facebook.dropdown-toggle {
    color: #fff;
    background-color: rgb(44.8066037736, 67.5896226415, 116.1933962264);
    border-color: rgb(41.258254717, 62.2370283019, 106.991745283);
}

.btn-facebook:not(:disabled):not(.disabled):active:focus, .btn-facebook:not(:disabled):not(.disabled).active:focus, .show > .btn-facebook.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.btn-facebook:not(:disabled):active, .btn-facebook:not(:disabled):focus, .btn-facebook:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(59, 89, 153, 0.4), 0 0.0625rem 0.385rem rgba(59, 89, 153, 0.54);
}

.btn-twitter {
    box-shadow: 0 0.25rem 0.55rem rgba(29, 161, 242, 0.35);
    color: #fff;
    background-color: #1da1f2;
    border-color: #1da1f2;
}

.btn-twitter:hover {
    color: #fff;
    background-color: #1da1f2;
    border-color: #1da1f2;
}

.btn-twitter:focus, .btn-twitter.focus {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.btn-twitter.disabled, .btn-twitter:disabled {
    color: #fff;
    background-color: #1da1f2;
    border-color: #1da1f2;
}

.btn-twitter:not(:disabled):not(.disabled):active, .btn-twitter:not(:disabled):not(.disabled).active, .show > .btn-twitter.dropdown-toggle {
    color: #fff;
    background-color: rgb(11.9665271967, 133.4728033473, 208.0334728033);
    border-color: rgb(11.2730125523, 125.7374476987, 195.9769874477);
}

.btn-twitter:not(:disabled):not(.disabled):active:focus, .btn-twitter:not(:disabled):not(.disabled).active:focus, .show > .btn-twitter.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.btn-twitter:not(:disabled):active, .btn-twitter:not(:disabled):focus, .btn-twitter:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(29, 161, 242, 0.4), 0 0.0625rem 0.385rem rgba(29, 161, 242, 0.54);
}

.btn-google {
    box-shadow: 0 0.25rem 0.55rem rgba(221, 75, 57, 0.35);
    color: #fff;
    background-color: #dd4b39;
    border-color: #dd4b39;
}

.btn-google:hover {
    color: #fff;
    background-color: #dd4b39;
    border-color: #dd4b39;
}

.btn-google:focus, .btn-google.focus {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.btn-google.disabled, .btn-google:disabled {
    color: #fff;
    background-color: #dd4b39;
    border-color: #dd4b39;
}

.btn-google:not(:disabled):not(.disabled):active, .btn-google:not(:disabled):not(.disabled).active, .show > .btn-google.dropdown-toggle {
    color: #fff;
    background-color: rgb(193.7327586207, 50.8793103448, 33.2672413793);
    border-color: rgb(182.8512931034, 48.0215517241, 31.3987068966);
}

.btn-google:not(:disabled):not(.disabled):active:focus, .btn-google:not(:disabled):not(.disabled).active:focus, .show > .btn-google.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.btn-google:not(:disabled):active, .btn-google:not(:disabled):focus, .btn-google:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(221, 75, 57, 0.4), 0 0.0625rem 0.385rem rgba(221, 75, 57, 0.54);
}

.btn-instagram {
    box-shadow: 0 0.25rem 0.55rem rgba(228, 64, 95, 0.35);
    color: #fff;
    background-color: #e4405f;
    border-color: #e4405f;
}

.btn-instagram:hover {
    color: #fff;
    background-color: #e4405f;
    border-color: #e4405f;
}

.btn-instagram:focus, .btn-instagram.focus {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.btn-instagram.disabled, .btn-instagram:disabled {
    color: #fff;
    background-color: #e4405f;
    border-color: #e4405f;
}

.btn-instagram:not(:disabled):not(.disabled):active, .btn-instagram:not(:disabled):not(.disabled).active, .show > .btn-instagram.dropdown-toggle {
    color: #fff;
    background-color: rgb(211.1513761468, 29.8486238532, 64.119266055);
    border-color: rgb(199.9805045872, 28.2694954128, 60.7270642202);
}

.btn-instagram:not(:disabled):not(.disabled):active:focus, .btn-instagram:not(:disabled):not(.disabled).active:focus, .show > .btn-instagram.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.btn-instagram:not(:disabled):active, .btn-instagram:not(:disabled):focus, .btn-instagram:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(228, 64, 95, 0.4), 0 0.0625rem 0.385rem rgba(228, 64, 95, 0.54);
}

.btn-pinterest {
    box-shadow: 0 0.25rem 0.55rem rgba(189, 8, 28, 0.35);
    color: #fff;
    background-color: #bd081c;
    border-color: #bd081c;
}

.btn-pinterest:hover {
    color: #fff;
    background-color: #bd081c;
    border-color: #bd081c;
}

.btn-pinterest:focus, .btn-pinterest.focus {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.btn-pinterest.disabled, .btn-pinterest:disabled {
    color: #fff;
    background-color: #bd081c;
    border-color: #bd081c;
}

.btn-pinterest:not(:disabled):not(.disabled):active, .btn-pinterest:not(:disabled):not(.disabled).active, .show > .btn-pinterest.dropdown-toggle {
    color: #fff;
    background-color: rgb(140.0710659898, 5.9289340102, 20.7512690355);
    border-color: rgb(127.8388324873, 5.4111675127, 18.9390862944);
}

.btn-pinterest:not(:disabled):not(.disabled):active:focus, .btn-pinterest:not(:disabled):not(.disabled).active:focus, .show > .btn-pinterest.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.btn-pinterest:not(:disabled):active, .btn-pinterest:not(:disabled):focus, .btn-pinterest:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(189, 8, 28, 0.4), 0 0.0625rem 0.385rem rgba(189, 8, 28, 0.54);
}

.btn-youtube {
    box-shadow: 0 0.25rem 0.55rem rgba(205, 32, 31, 0.35);
    color: #fff;
    background-color: #cd201f;
    border-color: #cd201f;
}

.btn-youtube:hover {
    color: #fff;
    background-color: #cd201f;
    border-color: #cd201f;
}

.btn-youtube:focus, .btn-youtube.focus {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.btn-youtube.disabled, .btn-youtube:disabled {
    color: #fff;
    background-color: #cd201f;
    border-color: #cd201f;
}

.btn-youtube:not(:disabled):not(.disabled):active, .btn-youtube:not(:disabled):not(.disabled).active, .show > .btn-youtube.dropdown-toggle {
    color: #fff;
    background-color: rgb(160.6991525424, 25.0847457627, 24.3008474576);
    border-color: rgb(149.623940678, 23.3559322034, 22.626059322);
}

.btn-youtube:not(:disabled):not(.disabled):active:focus, .btn-youtube:not(:disabled):not(.disabled).active:focus, .show > .btn-youtube.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.btn-youtube:not(:disabled):active, .btn-youtube:not(:disabled):focus, .btn-youtube:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(205, 32, 31, 0.4), 0 0.0625rem 0.385rem rgba(205, 32, 31, 0.54);
}

.btn-slack {
    box-shadow: 0 0.25rem 0.55rem rgba(58, 175, 133, 0.35);
    color: #fff;
    background-color: #3aaf85;
    border-color: #3aaf85;
}

.btn-slack:hover {
    color: #fff;
    background-color: #3aaf85;
    border-color: #3aaf85;
}

.btn-slack:focus, .btn-slack.focus {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.btn-slack.disabled, .btn-slack:disabled {
    color: #fff;
    background-color: #3aaf85;
    border-color: #3aaf85;
}

.btn-slack:not(:disabled):not(.disabled):active, .btn-slack:not(:disabled):not(.disabled).active, .show > .btn-slack.dropdown-toggle {
    color: #fff;
    background-color: rgb(45.30472103, 136.69527897, 103.8884120172);
    border-color: rgb(42.1309012876, 127.1190987124, 96.6105150215);
}

.btn-slack:not(:disabled):not(.disabled):active:focus, .btn-slack:not(:disabled):not(.disabled).active:focus, .show > .btn-slack.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.btn-slack:not(:disabled):active, .btn-slack:not(:disabled):focus, .btn-slack:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(58, 175, 133, 0.4), 0 0.0625rem 0.385rem rgba(58, 175, 133, 0.54);
}

.btn-dribbble {
    box-shadow: 0 0.25rem 0.55rem rgba(234, 76, 137, 0.35);
    color: #fff;
    background-color: #ea4c89;
    border-color: #ea4c89;
}

.btn-dribbble:hover {
    color: #fff;
    background-color: #ea4c89;
    border-color: #ea4c89;
}

.btn-dribbble:focus, .btn-dribbble.focus {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.btn-dribbble.disabled, .btn-dribbble:disabled {
    color: #fff;
    background-color: #ea4c89;
    border-color: #ea4c89;
}

.btn-dribbble:not(:disabled):not(.disabled):active, .btn-dribbble:not(:disabled):not(.disabled).active, .show > .btn-dribbble.dropdown-toggle {
    color: #fff;
    background-color: rgb(228.645, 30.355, 106.91);
    border-color: rgb(220.39375, 25.85625, 100.9625);
}

.btn-dribbble:not(:disabled):not(.disabled):active:focus, .btn-dribbble:not(:disabled):not(.disabled).active:focus, .show > .btn-dribbble.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.btn-dribbble:not(:disabled):active, .btn-dribbble:not(:disabled):focus, .btn-dribbble:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(234, 76, 137, 0.4), 0 0.0625rem 0.385rem rgba(234, 76, 137, 0.54);
}

.btn-github {
    box-shadow: 0 0.25rem 0.55rem rgba(34, 34, 34, 0.35);
    color: #fff;
    background-color: #222222;
    border-color: #222222;
}

.btn-github:hover {
    color: #fff;
    background-color: #222222;
    border-color: #222222;
}

.btn-github:focus, .btn-github.focus {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.btn-github.disabled, .btn-github:disabled {
    color: #fff;
    background-color: #222222;
    border-color: #222222;
}

.btn-github:not(:disabled):not(.disabled):active, .btn-github:not(:disabled):not(.disabled).active, .show > .btn-github.dropdown-toggle {
    color: #fff;
    background-color: rgb(8.5, 8.5, 8.5);
    border-color: rgb(2.125, 2.125, 2.125);
}

.btn-github:not(:disabled):not(.disabled):active:focus, .btn-github:not(:disabled):not(.disabled).active:focus, .show > .btn-github.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.btn-github:not(:disabled):active, .btn-github:not(:disabled):focus, .btn-github:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(34, 34, 34, 0.4), 0 0.0625rem 0.385rem rgba(34, 34, 34, 0.54);
}

.btn-outline-primary:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(47, 128, 237, 0.4), 0 0.0625rem 0.385rem rgba(47, 128, 237, 0.54);
}

.btn-outline-secondary:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(248, 249, 255, 0.4), 0 0.0625rem 0.385rem rgba(248, 249, 255, 0.54);
}

.btn-outline-success:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(27, 201, 67, 0.4), 0 0.0625rem 0.385rem rgba(27, 201, 67, 0.54);
}

.btn-outline-info:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(17, 197, 219, 0.4), 0 0.0625rem 0.385rem rgba(17, 197, 219, 0.54);
}

.btn-outline-warning:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(244, 119, 46, 0.4), 0 0.0625rem 0.385rem rgba(244, 119, 46, 0.54);
}

.btn-outline-danger:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(248, 50, 69, 0.4), 0 0.0625rem 0.385rem rgba(248, 50, 69, 0.54);
}

.btn-outline-light:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(244, 245, 253, 0.4), 0 0.0625rem 0.385rem rgba(244, 245, 253, 0.54);
}

.btn-outline-gray:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(248, 249, 255, 0.4), 0 0.0625rem 0.385rem rgba(248, 249, 255, 0.54);
}

.btn-outline-dark:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(122, 123, 151, 0.4), 0 0.0625rem 0.385rem rgba(122, 123, 151, 0.54);
}

.btn-outline-first:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(47, 128, 237, 0.4), 0 0.0625rem 0.385rem rgba(47, 128, 237, 0.54);
}

.btn-outline-second:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(7, 9, 25, 0.4), 0 0.0625rem 0.385rem rgba(7, 9, 25, 0.54);
}

.btn-spinner {
    display: inline-flex;
    align-items: center;
}

.btn-secondary {
    border-color: #f8f9ff;
    box-shadow: 0 0.25rem 0.55rem rgba(166, 166, 185, 0.55);
    color: #2F80ED;
}

.btn-secondary:not(:disabled):active, .btn-secondary:not(:disabled):focus, .btn-secondary:not(:disabled):hover {
    border-color: #f8f9ff;
    color: rgb(12.4646017699, 68.5553097345, 144.0353982301);
    box-shadow: 0 0.22rem 0.525rem rgba(166, 166, 185, 0.4), 0 0.0625rem 0.385rem rgba(166, 166, 185, 0.54);
}

.btn-secondary:not(:disabled):active {
    border-color: transparent !important;
    background: #eeeff8 !important;
}

.btn-link span {
    position: relative;
    transition: all 0.2s ease-in-out;
}

.btn-link span:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
}

.btn-link:hover span:before {
    visibility: visible;
    transform: scaleX(1);
}

.btn-link-primary span {
    color: #2F80ED;
}

.btn-link-primary span:before {
    background-color: #2F80ED;
}

.btn-link-primary:hover span {
    color: rgb(18.5575221239, 102.0663716814, 214.4424778761);
}

.btn-link-secondary span {
    color: #f8f9ff;
}

.btn-link-secondary span:before {
    background-color: #f8f9ff;
}

.btn-link-secondary:hover span {
    color: rgb(197, 205.2857142857, 255);
}

.btn-link-success span {
    color: #1bc943;
}

.btn-link-success span:before {
    background-color: #1bc943;
}

.btn-link-success:hover span {
    color: rgb(20.9605263158, 156.0394736842, 52.0131578947);
}

.btn-link-info span {
    color: #11c5db;
}

.btn-link-info span:before {
    background-color: #11c5db;
}

.btn-link-info:hover span {
    color: rgb(13.3262711864, 154.4279661017, 171.6737288136);
}

.btn-link-warning span {
    color: #f4772e;
}

.btn-link-warning span:before {
    background-color: #f4772e;
}

.btn-link-warning:hover span {
    color: rgb(227.05, 91.2545454545, 11.95);
}

.btn-link-danger span {
    color: #f83245;
}

.btn-link-danger span:before {
    background-color: #f83245;
}

.btn-link-danger:hover span {
    color: rgb(238.8443396226, 8.1556603774, 30.2924528302);
}

.btn-link-light span {
    color: #f4f5fd;
}

.btn-link-light span:before {
    background-color: #f4f5fd;
}

.btn-link-light:hover span {
    color: rgb(200.8461538462, 205.7692307692, 245.1538461538);
}

.btn-link-gray span {
    color: #f8f9ff;
}

.btn-link-gray span:before {
    background-color: #f8f9ff;
}

.btn-link-gray:hover span {
    color: rgb(197, 205.2857142857, 255);
}

.btn-link-dark span {
    color: #7a7b97;
}

.btn-link-dark span:before {
    background-color: #7a7b97;
}

.btn-link-dark:hover span {
    color: rgb(97.417721519, 98.3544303797, 124.582278481);
}

.btn-link-first span {
    color: #2F80ED;
}

.btn-link-first span:before {
    background-color: #2F80ED;
}

.btn-link-first:hover span {
    color: rgb(18.5575221239, 102.0663716814, 214.4424778761);
}

.btn-link-second span {
    color: #070919;
}

.btn-link-second span:before {
    background-color: #070919;
}

.btn-link-second:hover span {
    color: black;
}

.btn-link {
    font-weight: 400;
    color: #3b3e66;
    text-decoration: none;
}

.btn-link:hover {
    color: #2F80ED;
    text-decoration: none;
    transform: none;
}

.btn-link:focus, .btn-link.focus {
    text-decoration: none;
    box-shadow: none;
}

.btn-link:disabled, .btn-link.disabled {
    color: #d1d2db;
    pointer-events: none;
}

.card {
    box-shadow: 0 0.46875rem 2.1875rem rgba(59, 62, 102, 0.03), 0 0.9375rem 1.40625rem rgba(59, 62, 102, 0.03), 0 0.25rem 0.53125rem rgba(59, 62, 102, 0.05), 0 0.125rem 0.1875rem rgba(59, 62, 102, 0.03);
}

.card .card-title {
    font-size: 1.1875rem;
}

button.card {
    outline: none;
}

.card-text {
    color: #7a7b97;
}

.card-box-alt {
    text-align: center;
}

.card-box-alt .card-icon-wrapper {
    height: 76px !important;
    line-height: 58px !important;
    width: 76px !important;
    margin: -44px auto 24px;
    border: #fff solid 6px;
}

.card-border-top {
    border-top-width: 3px;
    border-top-style: solid;
}

.card-badges {
    position: absolute;
    right: 0.75rem;
    top: 0.75rem;
    z-index: 2;
}

.card-badges > * {
    box-shadow: 0 0.46875rem 2.1875rem rgba(59, 62, 102, 0.03), 0 0.9375rem 1.40625rem rgba(59, 62, 102, 0.03), 0 0.25rem 0.53125rem rgba(59, 62, 102, 0.05), 0 0.125rem 0.1875rem rgba(59, 62, 102, 0.03);
}

.card-badges-bottom {
    top: auto;
    bottom: 0.75rem;
}

.card-transparent {
    background: none;
    box-shadow: 0 0 0 0 transparent !important;
}

.card-box {
    border-width: 1px;
}

.card-box .card-footer,
.card-box .card-header {
    border-width: 1px;
    border-color: rgba(7, 9, 25, 0.125);
}

.card-box .card-header {
    display: flex;
    align-items: center;
}

.card-box .card-header--title {
    flex-grow: 1;
}

.card-box .card-header--title > b {
    display: block;
}

.card-box .card-header--title > small {
    text-transform: uppercase;
    display: block;
    opacity: 0.3;
    font-weight: bold;
}

.card-box .card-header--title > small + b {
    padding-top: 0.25rem;
}

.card-box.card-box-border-bottom {
    border-top-color: rgba(7, 9, 25, 0.125) !important;
    border-right-color: rgba(7, 9, 25, 0.125) !important;
    border-left-color: rgba(7, 9, 25, 0.125) !important;
    border-bottom-width: 4px;
    border-style: solid;
}

.card-hover-indicator {
    opacity: 0.2;
    transition: all 0.2s ease-in-out;
}

.card.card-box:hover .card-hover-indicator {
    opacity: 0.8;
    transform: scale(1.3);
}

.card > .bg-composed-wrapper {
    top: -1px;
    margin-left: -1px;
    margin-right: -1px;
    margin-bottom: -2px;
    z-index: 6;
}

.task-wrapper .task-item {
    position: relative;
    padding: 0 0 2rem;
}

.task-wrapper .task-item:last-child {
    padding-bottom: 0;
}

.align-box-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.chat-wrapper .chat-item .chat-box {
    position: relative;
    opacity: 1;
    border: 0;
    padding: 0.8333333333rem 2rem;
    border-radius: 1.5rem;
    border-top-left-radius: 0.29rem;
    max-width: 50%;
    min-width: 100%;
    font-size: 0.83125rem;
}

.chat-wrapper .chat-item .chat-box p {
    margin-bottom: 0.3333333333rem;
}

.chat-wrapper .chat-item .chat-box p:last-child {
    margin-bottom: 0;
}

.chat-wrapper .chat-item .avatar-icon-wrapper {
    margin-right: 0.5rem;
}

.chat-wrapper .chat-item-reverse .chat-box {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 0.29rem;
}

.chat-wrapper .chat-item-reverse .avatar-icon-wrapper {
    margin-left: 0.5rem;
    margin-right: 0;
}

.file-manager-wrapper .file-manager-item {
    background: transparent;
}

.card-indicator {
    position: absolute;
    height: 60%;
    top: 20%;
    width: 6px;
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
    left: 0;
}

.card-tr-actions {
    position: absolute;
    right: 1rem;
    top: 1rem;
}

.card-tr-actions.card-tr-actions-sm {
    right: 0.25rem;
    top: 0.25rem;
}

.card-shadow-primary {
    box-shadow: 0 0.46875rem 2.1875rem rgba(47, 128, 237, 0.03), 0 0.9375rem 1.40625rem rgba(47, 128, 237, 0.03), 0 0.25rem 0.53125rem rgba(47, 128, 237, 0.05), 0 0.125rem 0.1875rem rgba(47, 128, 237, 0.03);
}

.card-shadow-secondary {
    box-shadow: 0 0.46875rem 2.1875rem rgba(248, 249, 255, 0.03), 0 0.9375rem 1.40625rem rgba(248, 249, 255, 0.03), 0 0.25rem 0.53125rem rgba(248, 249, 255, 0.05), 0 0.125rem 0.1875rem rgba(248, 249, 255, 0.03);
}

.card-shadow-success {
    box-shadow: 0 0.46875rem 2.1875rem rgba(27, 201, 67, 0.03), 0 0.9375rem 1.40625rem rgba(27, 201, 67, 0.03), 0 0.25rem 0.53125rem rgba(27, 201, 67, 0.05), 0 0.125rem 0.1875rem rgba(27, 201, 67, 0.03);
}

.card-shadow-info {
    box-shadow: 0 0.46875rem 2.1875rem rgba(17, 197, 219, 0.03), 0 0.9375rem 1.40625rem rgba(17, 197, 219, 0.03), 0 0.25rem 0.53125rem rgba(17, 197, 219, 0.05), 0 0.125rem 0.1875rem rgba(17, 197, 219, 0.03);
}

.card-shadow-warning {
    box-shadow: 0 0.46875rem 2.1875rem rgba(244, 119, 46, 0.03), 0 0.9375rem 1.40625rem rgba(244, 119, 46, 0.03), 0 0.25rem 0.53125rem rgba(244, 119, 46, 0.05), 0 0.125rem 0.1875rem rgba(244, 119, 46, 0.03);
}

.card-shadow-danger {
    box-shadow: 0 0.46875rem 2.1875rem rgba(248, 50, 69, 0.03), 0 0.9375rem 1.40625rem rgba(248, 50, 69, 0.03), 0 0.25rem 0.53125rem rgba(248, 50, 69, 0.05), 0 0.125rem 0.1875rem rgba(248, 50, 69, 0.03);
}

.card-shadow-light {
    box-shadow: 0 0.46875rem 2.1875rem rgba(244, 245, 253, 0.03), 0 0.9375rem 1.40625rem rgba(244, 245, 253, 0.03), 0 0.25rem 0.53125rem rgba(244, 245, 253, 0.05), 0 0.125rem 0.1875rem rgba(244, 245, 253, 0.03);
}

.card-shadow-gray {
    box-shadow: 0 0.46875rem 2.1875rem rgba(248, 249, 255, 0.03), 0 0.9375rem 1.40625rem rgba(248, 249, 255, 0.03), 0 0.25rem 0.53125rem rgba(248, 249, 255, 0.05), 0 0.125rem 0.1875rem rgba(248, 249, 255, 0.03);
}

.card-shadow-dark {
    box-shadow: 0 0.46875rem 2.1875rem rgba(122, 123, 151, 0.03), 0 0.9375rem 1.40625rem rgba(122, 123, 151, 0.03), 0 0.25rem 0.53125rem rgba(122, 123, 151, 0.05), 0 0.125rem 0.1875rem rgba(122, 123, 151, 0.03);
}

.card-shadow-first {
    box-shadow: 0 0.46875rem 2.1875rem rgba(47, 128, 237, 0.03), 0 0.9375rem 1.40625rem rgba(47, 128, 237, 0.03), 0 0.25rem 0.53125rem rgba(47, 128, 237, 0.05), 0 0.125rem 0.1875rem rgba(47, 128, 237, 0.03);
}

.card-shadow-second {
    box-shadow: 0 0.46875rem 2.1875rem rgba(7, 9, 25, 0.03), 0 0.9375rem 1.40625rem rgba(7, 9, 25, 0.03), 0 0.25rem 0.53125rem rgba(7, 9, 25, 0.05), 0 0.125rem 0.1875rem rgba(7, 9, 25, 0.03);
}

.hover-show-hide-container .hover-show-wrapper {
    display: none;
}

.hover-show-hide-container .hover-hide-wrapper {
    display: block;
}

.hover-show-hide-container:hover .hover-show-wrapper {
    display: block;
}

.hover-show-hide-container:hover .hover-hide-wrapper {
    display: none;
}

.sparkline-full-wrapper {
    overflow: hidden;
    height: 140px;
    position: relative;
}

.sparkline-full-wrapper:last-child {
    margin: 0 -1px -1px;
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
}

.sparkline-full-wrapper--sm {
    height: 100px;
}

.sparkline-full-wrapper--lg {
    height: 160px;
}

.sparkline-full-wrapper--xl {
    height: 221px;
}

.sparkline-full-wrapper--xxl {
    height: 337px;
}

.card-chart-overlay {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 0;
    right: 0;
    opacity: 0.25;
    z-index: 5;
}

.card-content-overlay {
    position: relative;
    z-index: 6;
}

.card-body-avatar {
    position: relative;
    padding-top: calc(1.25rem + 60px);
}

.card-body-avatar > .avatar-icon-wrapper {
    margin: 0;
    position: absolute;
    height: 120px;
    width: 120px;
    top: -60px;
    z-index: 3;
}

.card-body-avatar > .avatar-icon-wrapper .avatar-icon {
    height: 100%;
    width: 100%;
    box-shadow: 0 0.46875rem 2.1875rem rgba(59, 62, 102, 0.03), 0 0.9375rem 1.40625rem rgba(59, 62, 102, 0.03), 0 0.25rem 0.53125rem rgba(59, 62, 102, 0.05), 0 0.125rem 0.1875rem rgba(59, 62, 102, 0.03);
}

.card-body-avatar > .avatar-icon-wrapper img {
    max-width: 100%;
    height: auto;
    width: 100%;
}

.card-body-avatar > .avatar-icon-wrapper--sm {
    height: 60px;
    width: 60px;
    top: -30px;
}

.card-body-avatar.text-center > .avatar-icon-wrapper {
    left: 50%;
    margin-left: -60px;
}

.card-body-avatar.text-center > .avatar-icon-wrapper .avatar-icon {
    margin: 0;
}

.card-body-avatar.text-center > .avatar-icon-wrapper--sm {
    margin-left: -30px;
}

.btn-group {
    transition: all 0.2s ease-in-out;
}

.btn-group > .btn {
    transform: translateY(0px);
}

.btn-group:hover > .btn {
    transform: translateY(-2px);
}

.dropdown-item {
    transition: all 0.2s ease-in-out;
}

.dropdown-menu {
    box-shadow: 0 0.313rem 0.8rem rgba(122, 123, 151, 0.5), 0 0.126rem 0.225rem rgba(122, 123, 151, 0.3);
    display: block;
    transition: visibility 0.25s, opacity 0.25s;
    opacity: 0;
    visibility: hidden;
    outline: none !important;
}

.dropdown-menu.dropdown {
    opacity: 1;
    width: 80%;
}

.dropdown-menu.show {
    display: block;
    visibility: visible;
    opacity: 1;
    z-index: 10;
}

.dropdown-toggle.no-caret::after {
    display: none;
}

.table {
    font-size: 0.95rem;
}

.table thead th {
    text-transform: uppercase;
    background: #f4f5fd;
    font-size: 0.83125rem;
}

.table tr {
    transition: all 0.2s ease-in-out;
}

.table td, .table th {
    vertical-align: middle;
}

.table.table-alternate thead th {
    text-transform: none;
    background: transparent;
    font-size: 1.045rem;
    font-weight: normal;
    color: #3b3e66;
}

.wizard .steps-indicator {
    padding: 0.75rem 1.25rem;
    border-bottom: rgba(122, 123, 151, 0.3) solid 1px;
    background: #f8f9ff;
    display: flex;
    list-style: none;
    margin: 0;
}

.wizard .steps-indicator li {
    display: block;
    width: auto;
    position: relative;
    padding: 0 2rem 0 0;
}

.wizard .steps-indicator li a {
    justify-content: flex-start;
    align-items: center;
    display: flex;
    flex-direction: row-reverse;
    cursor: pointer;
    position: relative;
}

.wizard .steps-indicator li a .step-indicator {
    width: 38px;
    height: 38px;
    background: transparent;
    border: #a6a6b9 solid 2px;
    color: #a6a6b9;
    display: flex;
    align-items: center;
    font-weight: bold;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 100%;
    transition: all 0.2s ease-in-out;
    position: relative;
}

.wizard .steps-indicator li a .label {
    padding: 0 0.6666666667rem;
    color: #a6a6b9;
    transition: all 0.2s ease-in-out;
    font-size: 0.95rem;
}

.wizard .steps-indicator li a:hover .step-indicator {
    color: #7a7b97;
    border-color: #7a7b97;
}

.wizard .steps-indicator li a:hover .label {
    color: #7a7b97;
}

.wizard .steps-indicator li:after {
    font-family: "Pe-icon-7-stroke";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 2.3rem;
    padding: 0;
    text-align: center;
    text-indent: initial;
    display: block;
    content: "\e684";
    position: absolute;
    right: 24px;
    width: 16px;
    top: 50%;
    margin-top: -8px;
    height: 16px;
    line-height: 16px;
}

.wizard .steps-indicator li.current a .step-indicator {
    background: #2F80ED;
    border-color: #2F80ED;
    color: #fff;
}

.wizard .steps-indicator li.current a .label {
    color: #2F80ED;
}

.wizard .steps-indicator li.done a .step-indicator {
    background: #1bc943;
    color: #fff;
    border-color: #1bc943;
}

.wizard .steps-indicator li.done a .label {
    color: #1bc943;
}

.wizard .steps-indicator li:last-child:after {
    display: none;
}

.wizard .wizard-steps {
    padding: 0;
}

.wizard .actions {
    display: flex;
    justify-content: space-between;
    border-top: rgba(122, 123, 151, 0.3) solid 1px;
}

@media (max-width: 959.98px) {
    .wizard .steps ul {
        display: block;
    }

    .wizard .steps ul li {
        padding: 0 0 2rem 0;
    }

    .wizard .steps ul li:after {
        transform: rotate(90deg);
        right: auto;
        left: 11px;
        bottom: 0.9090909091rem;
        top: auto;
    }

    .wizard .steps ul li:last-child {
        padding-bottom: 0;
    }
}

.react-joyride__overlay {
    z-index: 1200 !important;
    display: none !important;
}

.react-joyride__overlay .react-joyride__spotlight {
    z-index: 1300 !important;
    display: none !important;
}

.__floater {
    z-index: 1400 !important;
}

.joyride-button {
    border-radius: 0.29rem !important;
}

.joyride-step__container {
    border-radius: 0.65rem !important;
    box-shadow: 0rem 5rem 14rem 0 rgba(255, 255, 255, 0.3), 0 0.8rem 2.3rem rgba(0, 0, 0, 0.6), 0 0.2rem 0.3rem rgba(0, 0, 0, 0.45) !important;
}

.list-group-item {
    font-size: 0.95rem;
    transition: all 0.2s ease-in-out;
}

.list-group-item .nav-icon-wrapper {
    width: 32px;
    text-align: center;
}

.list-group-item + .list-group-item > .card-header {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.feature-box-img {
    position: relative;
    border: #070919 solid 5px;
    background: #fff;
    border-radius: 0.75rem;
}

@media (min-width: 1920px) {
    .feature-box-img {
        margin-left: -5rem;
    }
}

.gauge-wrapper {
    max-width: 160px;
    margin: 0 auto;
    padding: 0.5rem;
}

.scroll-area {
    overflow-x: hidden;
    height: 300px;
}

.scroll-area-xs {
    height: 150px;
    overflow-x: hidden;
}

.scroll-area-sm {
    height: 215px;
    overflow-x: hidden;
}

.scroll-area-lg {
    height: 420px;
    overflow-x: hidden;
}

.scroll-area-xl {
    height: 600px;
    overflow-x: hidden;
}

.scroll-area-x {
    overflow-x: auto;
    width: 100%;
    max-width: 100%;
}

.shadow-overflow {
    position: relative;
}

.shadow-overflow::after, .shadow-overflow::before {
    width: 100%;
    bottom: auto;
    top: 0;
    left: 0;
    height: 1rem;
    position: absolute;
    z-index: 4;
    content: "";
    background: linear-gradient(to bottom, white 20%, rgba(255, 255, 255, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="$white", endColorstr="$white", GradientType=0);
}

.shadow-overflow::after {
    bottom: 0;
    top: auto;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 80%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="$white", endColorstr="$white", GradientType=0);
}

.shadow-overflow-horizontal {
    position: relative;
}

.shadow-overflow-horizontal::after, .shadow-overflow-horizontal::before {
    width: 1rem;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    position: absolute;
    z-index: 4;
    content: "";
    background: linear-gradient(to right, white 20%, rgba(255, 255, 255, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="$white", endColorstr="$white", GradientType=0);
}

.shadow-overflow-horizontal::after {
    right: 0;
    left: auto;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 80%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="$white", endColorstr="$white", GradientType=0);
}

.scroll-area-sm-md {
    height: 265px;
    overflow-x: hidden;
}

.timeline-list {
    width: 100%;
    position: relative;
    padding: 1rem 0 0;
}

.timeline-list::after {
    content: "";
    display: table;
    clear: both;
}

.timeline-list::before {
    content: "";
    position: absolute;
    top: 0;
    left: 14px;
    height: 100%;
    width: 4px;
    border-radius: 0.65rem;
}

.timeline-list .timeline-item--icon, .timeline-list::before {
    background: #dfe0ea;
}

.timeline-list .timeline-item {
    position: relative;
    padding: 0.3333333333rem 0 0.3333333333rem 36px;
    margin: 0 0 1rem;
}

.timeline-list .timeline-item p {
    margin: 0;
}

.timeline-list .timeline-item-icon {
    padding: 0.5rem 0 0.5rem 46px;
}

.timeline-list .timeline-item--icon {
    height: 14px;
    width: 14px;
    position: absolute;
    left: -27px;
    top: 1px;
    display: block;
    border-radius: 20px;
}

.timeline-list .timeline-item--icon::after {
    content: "";
    position: absolute;
    background: #fff;
    left: 50%;
    top: 50%;
    margin: -4px 0 0 -4px;
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 20px;
}

.timeline-list .timeline-item--icon.timeline-icon-alternate {
    top: 12px;
}

.timeline-list .timeline-item--icon.timeline-icon-alternate + .timeline-item--label {
    margin-left: -6px;
}

.timeline-list .timeline-item--icon-wrapper {
    border-radius: 0.75rem;
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    display: block;
    position: absolute;
    left: -47px;
    top: -9px;
}

.timeline-list .timeline-item--content {
    font-size: 0.83125rem;
    color: #7a7b97;
    position: relative;
}

.timeline-list .timeline-item--label {
    font-size: 0.83125rem;
    margin: 0;
    color: #070919;
    display: flex;
    align-content: center;
    align-items: center;
    line-height: 1.5;
}

.timeline-list-offset:before {
    left: 76px !important;
}

.timeline-list-offset .timeline-item-offset {
    position: absolute;
    left: 0;
    opacity: 0.5;
    width: 50px;
    text-align: right;
}

.timeline-list-offset .timeline-item-icon {
    padding-left: 108px !important;
}

.timeline-list-offset .timeline-item-icon .timeline-item-offset {
    top: 6px !important;
    font-weight: bold;
}

.timeline-list-offset .timeline-item {
    padding-left: 88px;
}

.timeline-list-offset .timeline-item .timeline-item-offset {
    top: 3px;
}

.timeline-list-offset.timeline-list-offset-dot:before {
    left: 66px !important;
}

.badge.badge-circle-inner, .badge.badge-circle {
    text-indent: -999rem;
    border-radius: 100%;
    height: 10px;
    width: 10px;
    padding: 0;
    box-shadow: 0 0 0 2px #fff;
}

.badge.badge-circle-inner {
    width: 16px;
    height: 16px;
    position: relative;
}

.badge.badge-circle-inner:after {
    content: "";
    background: #fff;
    width: 8px;
    height: 8px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -4px 0 0 -4px;
    border-radius: 100%;
}

.badge-wrapper {
    position: relative;
}

.badge-position {
    position: absolute !important;
}

.badge-position--top-right.badge-circle {
    right: -5px;
    top: -5px;
}

.badge-position--top-right.badge-circle-inner {
    right: -8px;
    top: -8px;
}

.badge-position--top-center.badge-circle {
    left: 50%;
    margin-left: -5px;
    top: -5px;
}

.badge-position--top-center.badge-circle-inner {
    left: 50%;
    margin-left: -8px;
    top: -8px;
}

.badge-position--top-left.badge-circle {
    left: -5px;
    top: -5px;
}

.badge-position--top-left.badge-circle-inner {
    left: -8px;
    top: -8px;
}

.badge-position--bottom-right.badge-circle {
    right: -5px;
    bottom: -5px;
    top: auto;
}

.badge-position--bottom-right.badge-circle-inner {
    right: -8px;
    bottom: -8px;
    top: auto;
}

.badge-position--bottom-center.badge-circle {
    left: 50%;
    margin-left: -5px;
    bottom: -5px;
    top: auto;
}

.badge-position--bottom-center.badge-circle-inner {
    left: 50%;
    margin-left: -8px;
    bottom: -8px;
    top: auto;
}

.badge-position--bottom-left.badge-circle {
    left: -5px;
    bottom: -5px;
    top: auto;
}

.badge-position--bottom-left.badge-circle-inner {
    left: -8px;
    bottom: -8px;
    top: auto;
}

.btn-gradient {
    border: 0;
    position: relative;
    color: #fff;
}

.btn-gradient:after {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    background: #fff;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    content: "";
}

.btn-gradient:hover {
    color: #fff;
}

.btn-gradient:hover:after {
    opacity: 0.15;
}

.btn-gradient-inverse {
    color: #070919;
}

.btn-gradient-inverse:hover {
    color: #070919;
}

.image-title-overlay {
    position: relative;
    display: block;
}

.image-title-overlay img {
    transition: all 0.2s ease-in-out;
}

.image-title-overlay--bottom {
    background: -moz-linear-gradient(top, rgba(7, 9, 25, 0) 0%, rgba(7, 9, 25, 0.8) 100%);
    background: -webkit-linear-gradient(top, rgba(7, 9, 25, 0) 0%, rgba(7, 9, 25, 0.8) 100%);
    background: linear-gradient(to bottom, rgba(7, 9, 25, 0) 0%, rgba(7, 9, 25, 0.8) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00000000", endColorstr="#cc000000", GradientType=0);
    transition: all 0.2s ease-in-out;
    z-index: 3;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 2.5rem 1.25rem 1.25rem;
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
}

.image-title-overlay:hover img {
    opacity: 0.95;
}

.image-title-overlay:hover .image-title-overlay--bottom {
    padding: 3.75rem 1.25rem 1.25rem;
}

.card-box-hover-rise {
    transition: all 0.2s ease-in-out;
}

.card-box-hover-rise:hover {
    box-shadow: 0 0.46875rem 2.1875rem rgba(59, 62, 102, 0.03), 0 0.9375rem 1.40625rem rgba(59, 62, 102, 0.03), 0 0.25rem 0.53125rem rgba(59, 62, 102, 0.05), 0 0.125rem 0.1875rem rgba(59, 62, 102, 0.03);
    transform: translateY(-15px);
}

.card-box-hover-rise.card-box-hover:hover {
    box-shadow: 0 0.46875rem 2.1875rem rgba(59, 62, 102, 0.03), 0 0.9375rem 1.40625rem rgba(59, 62, 102, 0.03), 0 0.25rem 0.53125rem rgba(59, 62, 102, 0.05), 0 0.125rem 0.1875rem rgba(59, 62, 102, 0.03);
    transform: translateY(0);
}

.card-box-hover-rise-alt {
    transition: all 0.2s ease-in-out;
}

.card-box-hover-rise-alt:hover {
    box-shadow: 0rem 5rem 14rem 0 rgba(255, 255, 255, 0.3), 0 0.8rem 2.3rem rgba(0, 0, 0, 0.6), 0 0.2rem 0.3rem rgba(0, 0, 0, 0.45);
    transform: translateY(-15px);
}

.card-box-hover-rise-alt.card-box-hover:hover {
    transform: translateY(0);
}

.card-img-wrapper {
    position: relative;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.card-img-wrapper .img-wrapper-overlay {
    width: 100%;
    height: 100%;
    transition: all 0.2s ease-in-out;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 0;
    top: 0;
    box-shadow: inset 0 0 2.3rem 0.5rem #070919;
    display: flex;
    align-items: center;
    align-content: center;
    color: #fff;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

@media (max-width: 959.98px) {
    .card-img-wrapper .img-wrapper-overlay + img {
        width: 100% !important;
    }
}

.card-img-wrapper .img-wrapper-overlay .overlay-btn-wrapper {
    position: relative;
    z-index: 3;
    text-align: center;
    width: 100%;
}

.card-img-wrapper .img-wrapper-overlay:before {
    z-index: 2;
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    width: 100%;
    height: 100%;
    transition: all 0.2s ease-in-out;
    background: #070919;
    opacity: 0.85;
}

.card-img-wrapper .card-badges {
    z-index: 5;
}

a.card-img-wrapper {
    display: block;
    overflow: hidden;
}

a.card-img-wrapper:hover .img-wrapper-overlay {
    opacity: 1;
    visibility: visible;
}

.dropdown-menu > .bg-composed-wrapper {
    margin: -0.5rem -1px -2px;
    z-index: 5;
    top: -1px;
}

.dropdown-menu > .scroll-area {
    height: 10rem;
}

.dropdown-menu-lg {
    min-width: 15rem;
}

.dropdown-menu-xl {
    min-width: 18rem;
}

.dropdown-menu-xxl {
    min-width: 21rem;
}

.dropdown-toggle::after {
    position: relative;
    top: 2px;
}

.flag-icon {
    background-size: 100% !important;
    border-radius: 0.29rem;
}

.modal-dark {
    color: #fff !important;
}

.modal-dark .MuiDialogActions-root,
.modal-dark .MuiDialogTitle-root {
    border: rgba(255, 255, 255, 0.1) solid 1px;
}

.nav-link-icon {
    width: 28px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    font-size: 1.1875rem;
}

.grid-menu .MuiGrid-item {
    border-right: #eeeff8 solid 0;
    border-bottom: #eeeff8 solid 1px;
    position: relative;
}

@media (min-width: 600px) {
    .grid-menu .MuiGrid-item:nth-last-child(-n+2) {
        border-bottom-width: 0;
    }

    .grid-menu .MuiGrid-item:nth-child(1n) {
        border-right-width: 1px;
    }

    .grid-menu .MuiGrid-item:nth-child(2n) {
        border-right-width: 0;
    }
}

.grid-menu .MuiGrid-item:nth-last-child(-n+1) {
    border-bottom-width: 0;
}

@media (min-width: 1920px) {
    .grid-menu.grid-menu-3col .MuiGrid-item:nth-last-child(-n+3) {
        border-bottom-width: 0;
    }

    .grid-menu.grid-menu-3col .MuiGrid-item:nth-child(2n) {
        border-right-width: 1px;
    }

    .grid-menu.grid-menu-3col .MuiGrid-item:nth-child(3n) {
        border-right-width: 0;
    }
}

.grid-menu > .btn {
    display: block;
    border: 0;
    min-width: 100%;
}

.divider-v {
    position: absolute;
    top: 0;
    height: 100%;
    min-height: 26px;
    right: 0;
    width: 1px;
    background: #eeeff8;
    overflow: hidden;
}

@media (max-width: 1919.98px) {
    .divider-v.divider-v-lg {
        top: auto;
        bottom: 0;
        right: auto;
        left: 0;
        width: 100%;
        height: 1px;
        min-height: 1px;
    }
}

@media (max-width: 1279.98px) {
    .divider-v.divider-v-md {
        top: auto;
        bottom: 0;
        width: 100%;
        height: 1px;
        min-height: 1px;
    }
}

.alerts-alternate .MuiAlert-icon {
    display: none;
}

.nav-logo a {
    font-weight: bold;
    display: flex;
    align-items: center;
    font-size: 1.0795454545rem;
}

.nav-logo a span {
    opacity: 1;
    visibility: visible;
    transition: all 0.2s ease-in-out;
}

.nav-logo a i {
    height: 40px;
    width: 40px;
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    border-radius: 0.65rem;
    margin: 0 0.8rem 0 0;
    position: relative;
    left: 0;
    transition: all 0.2s ease-in-out;
}

.nav-logo a i img {
    width: 28px;
    margin: 0 auto;
}

.header-nav-wrapper {
    min-height: 74px;
    align-items: center;
    display: flex;
    position: relative;
}

.header-nav-wrapper > .container {
    align-items: center;
    display: flex;
}

.header-nav-wrapper .header-nav-logo {
    flex: 1;
    display: flex;
}

.header-nav-wrapper .header-nav-logo .nav-logo a {
    font-weight: bold;
    display: flex;
    align-items: center;
    font-size: 1.0795454545rem;
}

.header-nav-wrapper .header-nav-logo .nav-logo a span {
    opacity: 1;
    visibility: visible;
    transition: all 0.2s ease-in-out;
}

.header-nav-wrapper .header-nav-logo .nav-logo a i {
    height: 40px;
    width: 40px;
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    border-radius: 0.65rem;
    margin: 0 0.8rem 0 0;
    position: relative;
    left: 0;
    transition: all 0.2s ease-in-out;
}

.header-nav-wrapper .header-nav-logo .nav-logo a i img {
    width: 28px;
    margin: 0 auto;
}

.header-nav-wrapper .header-nav-menu {
    flex: 2;
    display: flex;
}

.header-nav-wrapper .header-nav-menu > .nav > .nav-item > .nav-link {
    border-radius: 1.5rem;
    transition: all 0.2s ease-in-out;
    margin: 0 0.25rem;
}

.header-nav-wrapper .header-nav-actions {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

.header-nav-wrapper.header-nav-wrapper-sm {
    min-height: 64px;
}

.header-nav-wrapper.header-nav-wrapper-lg {
    min-height: 84px;
}

.header-nav-wrapper.header-nav-wrapper-xl {
    min-height: 94px;
}

.header-nav-wrapper .nav-collapsed-wrapper {
    position: absolute;
    left: 5%;
    top: 1rem;
    width: 90%;
    background: #fff;
    z-index: 5;
    border-radius: 0.65rem;
    box-shadow: 0 0.5rem 1rem rgba(7, 9, 25, 0.55);
}

.header-nav-wrapper.navbar-dark .header-nav-logo a {
    color: rgba(255, 255, 255, 0.8);
}

.header-nav-wrapper.navbar-dark .header-nav-logo a:hover {
    color: #fff;
}

.header-nav-wrapper.navbar-dark .header-nav-menu > .nav > .nav-item > .nav-link {
    color: rgba(255, 255, 255, 0.8);
}

.header-nav-wrapper.navbar-dark .header-nav-menu > .nav > .nav-item > .nav-link.active, .header-nav-wrapper.navbar-dark .header-nav-menu > .nav > .nav-item > .nav-link:hover {
    color: #fff;
    background: rgba(255, 255, 255, 0.15);
}

.header-nav-wrapper.navbar-dark .header-nav-menu > .nav > .nav-item.dropdown.show > .nav-link {
    color: #fff;
    background: rgba(255, 255, 255, 0.15);
}

.header-nav-wrapper.navbar-light .header-nav-logo a {
    color: rgba(7, 9, 25, 0.8);
}

.header-nav-wrapper.navbar-light .header-nav-logo a:hover {
    color: #070919;
}

.header-nav-wrapper.navbar-light .header-nav-menu > .nav > .nav-item > .nav-link {
    color: rgba(7, 9, 25, 0.8);
}

.header-nav-wrapper.navbar-light .header-nav-menu > .nav > .nav-item > .nav-link.active, .header-nav-wrapper.navbar-light .header-nav-menu > .nav > .nav-item > .nav-link:hover {
    color: #070919;
    background: rgba(7, 9, 25, 0.07);
}

.header-nav-wrapper.navbar-light .header-nav-menu > .nav > .nav-item.dropdown.show > .nav-link {
    color: #070919;
    background: rgba(7, 9, 25, 0.07);
}

.header-nav-wrapper.navbar-white-50 {
    background: rgba(255, 255, 255, 0.15);
}

.nav-menu-collapse {
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    width: 260px;
    box-shadow: 0 1rem 3rem rgba(7, 9, 25, 0.575);
}

.nav-menu-collapse .nav-inner-wrapper {
    position: relative;
    z-index: 6;
}

.nav-menu-collapse:after {
    content: "";
    position: fixed;
    background: rgba(7, 9, 25, 0.1);
    width: 100%;
    height: 100%;
    z-index: 5;
    left: 0;
    top: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-in-out;
}

.nav-menu-collapse.show:after {
    opacity: 1;
    visibility: visible;
}

.navbar-brand {
    padding: 0;
}

.navbar-dark .hamburger .hamburger-inner, .navbar-dark .hamburger .hamburger-inner::before, .navbar-dark .hamburger .hamburger-inner::after {
    background-color: #fff;
}

.navbar-dark .hamburger.is-active .hamburger-inner,
.navbar-dark .hamburger.is-active .hamburger-inner::before,
.navbar-dark .hamburger.is-active .hamburger-inner::after {
    background-color: #fff;
}

.navbar-light .hamburger .hamburger-inner, .navbar-light .hamburger .hamburger-inner::before, .navbar-light .hamburger .hamburger-inner::after {
    background-color: #070919;
}

.navbar-light .hamburger.is-active .hamburger-inner,
.navbar-light .hamburger.is-active .hamburger-inner::before,
.navbar-light .hamburger.is-active .hamburger-inner::after {
    background-color: #070919;
}

.nav-collapse-alt .navbar-collapse-header {
    position: absolute;
    right: 1rem;
    top: 1rem;
}

.nav-collapse-alt .navbar-collapse {
    position: absolute;
    background: #fff;
    border-radius: 0.29rem;
    box-shadow: 0 0.5rem 1rem rgba(7, 9, 25, 0.55);
    padding: 0;
    left: 5%;
    width: 90%;
    top: 1rem;
    z-index: 5;
    visibility: hidden;
    opacity: 0;
}

.nav-collapse-alt .navbar-collapse.collapsing, .nav-collapse-alt .navbar-collapse.show {
    visibility: visible;
    opacity: 1;
}

.navbar-collapse-inner {
    padding: 1rem;
}

.navbar-collapse-inner .list-group-flush .list-group-item:first-child {
    border-top-left-radius: 0.65rem;
    border-top-right-radius: 0.65rem;
}

.navbar-collapse-inner .list-group-flush .list-group-item:last-child {
    border-bottom-right-radius: 0.65rem;
    border-bottom-left-radius: 0.65rem;
}

.navbar-nav .dropdown-menu {
    display: none;
}

.nav-item.dropdown.show .dropdown-menu {
    display: block;
}

.navbar-nav.navbar-nav-dark .nav-item .nav-link {
    color: #7a7b97;
}

.navbar-nav.navbar-nav-dark .nav-item .nav-link:hover {
    color: #070919;
}

@keyframes navbar-collapse-active {
    0% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes navbar-collapse-inactive {
    0% {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.hero-wrapper {
    flex-direction: column;
    position: relative;
}

.hero-wrapper .header-nav-wrapper {
    z-index: 9;
}

.hero-wrapper .bg-composed-wrapper--image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.hero-wrapper .hero-footer {
    z-index: 7;
}

.hero-wrapper .hero-indicator {
    position: absolute;
    right: 0;
    width: 40px;
    text-align: center;
    left: 50%;
    margin-left: -20px;
    bottom: 2rem;
}

.ribbon-horizontal {
    display: block;
    width: calc(100% + 20px);
    height: 50px;
    line-height: 50px;
    text-align: center;
    margin-left: -10px;
    margin-right: -10px;
    top: 1rem;
    position: absolute;
    z-index: 8;
    font-weight: bold;
    box-shadow: 0 0.46875rem 2.1875rem rgba(59, 62, 102, 0.03), 0 0.9375rem 1.40625rem rgba(59, 62, 102, 0.03), 0 0.25rem 0.53125rem rgba(59, 62, 102, 0.05), 0 0.125rem 0.1875rem rgba(59, 62, 102, 0.03);
}

.ribbon-horizontal:before, .ribbon-horizontal:after {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    bottom: -10px;
    border-top: 10px solid transparent;
}

.ribbon-horizontal:before {
    left: 0;
    border-left: 10px solid transparent;
}

.ribbon-horizontal:after {
    right: 0;
    border-right: 10px solid transparent;
}

.ribbon-horizontal--bottom {
    top: auto;
    bottom: 1rem;
}

.ribbon-horizontal--primary {
    background: #2F80ED;
    color: #fff;
}

.ribbon-horizontal--primary:after, .ribbon-horizontal--primary:before {
    border-top-color: rgb(14.4955752212, 79.7256637168, 167.5044247788);
}

.ribbon-horizontal--secondary {
    background: #f8f9ff;
    color: #3b3e66;
}

.ribbon-horizontal--secondary:after, .ribbon-horizontal--secondary:before {
    border-top-color: rgb(146, 161.5714285714, 255);
}

.ribbon-horizontal--success {
    background: #1bc943;
    color: #fff;
}

.ribbon-horizontal--success:after, .ribbon-horizontal--success:before {
    border-top-color: rgb(14.9210526316, 111.0789473684, 37.0263157895);
}

.ribbon-horizontal--info {
    background: #11c5db;
    color: #fff;
}

.ribbon-horizontal--info:after, .ribbon-horizontal--info:before {
    border-top-color: rgb(9.6525423729, 111.8559322034, 124.3474576271);
}

.ribbon-horizontal--warning {
    background: #f4772e;
    color: #fff;
}

.ribbon-horizontal--warning:after, .ribbon-horizontal--warning:before {
    border-top-color: rgb(178.6, 71.7818181818, 9.4);
}

.ribbon-horizontal--danger {
    background: #f83245;
    color: #fff;
}

.ribbon-horizontal--danger:after, .ribbon-horizontal--danger:before {
    border-top-color: rgb(189.5283018868, 6.4716981132, 24.0377358491);
}

.ribbon-horizontal--light {
    background: #f4f5fd;
    color: #3b3e66;
}

.ribbon-horizontal--light:after, .ribbon-horizontal--light:before {
    border-top-color: rgb(157.6923076923, 166.5384615385, 237.3076923077);
}

.ribbon-horizontal--gray {
    background: #f8f9ff;
    color: #3b3e66;
}

.ribbon-horizontal--gray:after, .ribbon-horizontal--gray:before {
    border-top-color: rgb(146, 161.5714285714, 255);
}

.ribbon-horizontal--dark {
    background: #7a7b97;
    color: #fff;
}

.ribbon-horizontal--dark:after, .ribbon-horizontal--dark:before {
    border-top-color: rgb(75.0379746835, 75.7594936709, 95.9620253165);
}

.ribbon-horizontal--first {
    background: #2F80ED;
    color: #fff;
}

.ribbon-horizontal--first:after, .ribbon-horizontal--first:before {
    border-top-color: rgb(14.4955752212, 79.7256637168, 167.5044247788);
}

.ribbon-horizontal--second {
    background: #070919;
    color: #fff;
}

.ribbon-horizontal--second:after, .ribbon-horizontal--second:before {
    border-top-color: black;
}

.ribbon-horizontal.btn {
    line-height: 30px;
    border: 0;
    border-radius: 0;
}

.ribbon-vertical {
    position: absolute;
    width: 60px;
    padding: 0.6666666667rem 0 0.5rem;
    top: -6px;
    left: 1.5rem;
    text-align: center;
    border-top-left-radius: 3px;
    z-index: 8;
    font-weight: bold;
    box-shadow: 0 0.5rem 1rem rgba(7, 9, 25, 0.55);
}

.ribbon-vertical--right {
    left: auto;
    right: 1.5rem;
}

.ribbon-vertical:before {
    height: 0;
    width: 0;
    right: -5.5px;
    top: 0.1px;
    border-bottom: 6px solid transparent;
    border-right: 6px solid transparent;
}

.ribbon-vertical:before, .ribbon-vertical:after {
    content: "";
    position: absolute;
}

.ribbon-vertical:after {
    height: 0;
    width: 0;
    bottom: -29.5px;
    left: 0;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    border-bottom: 30px solid transparent;
}

.ribbon-vertical--primary {
    background: #2F80ED;
    color: #fff;
}

.ribbon-vertical--primary:before {
    border-bottom-color: rgb(14.4955752212, 79.7256637168, 167.5044247788);
}

.ribbon-vertical--primary:after {
    border-left-color: #2F80ED;
    border-right-color: #2F80ED;
}

.ribbon-vertical--secondary {
    background: #f8f9ff;
    color: #3b3e66;
}

.ribbon-vertical--secondary:before {
    border-bottom-color: rgb(146, 161.5714285714, 255);
}

.ribbon-vertical--secondary:after {
    border-left-color: #f8f9ff;
    border-right-color: #f8f9ff;
}

.ribbon-vertical--success {
    background: #1bc943;
    color: #fff;
}

.ribbon-vertical--success:before {
    border-bottom-color: rgb(14.9210526316, 111.0789473684, 37.0263157895);
}

.ribbon-vertical--success:after {
    border-left-color: #1bc943;
    border-right-color: #1bc943;
}

.ribbon-vertical--info {
    background: #11c5db;
    color: #fff;
}

.ribbon-vertical--info:before {
    border-bottom-color: rgb(9.6525423729, 111.8559322034, 124.3474576271);
}

.ribbon-vertical--info:after {
    border-left-color: #11c5db;
    border-right-color: #11c5db;
}

.ribbon-vertical--warning {
    background: #f4772e;
    color: #fff;
}

.ribbon-vertical--warning:before {
    border-bottom-color: rgb(178.6, 71.7818181818, 9.4);
}

.ribbon-vertical--warning:after {
    border-left-color: #f4772e;
    border-right-color: #f4772e;
}

.ribbon-vertical--danger {
    background: #f83245;
    color: #fff;
}

.ribbon-vertical--danger:before {
    border-bottom-color: rgb(189.5283018868, 6.4716981132, 24.0377358491);
}

.ribbon-vertical--danger:after {
    border-left-color: #f83245;
    border-right-color: #f83245;
}

.ribbon-vertical--light {
    background: #f4f5fd;
    color: #3b3e66;
}

.ribbon-vertical--light:before {
    border-bottom-color: rgb(157.6923076923, 166.5384615385, 237.3076923077);
}

.ribbon-vertical--light:after {
    border-left-color: #f4f5fd;
    border-right-color: #f4f5fd;
}

.ribbon-vertical--gray {
    background: #f8f9ff;
    color: #3b3e66;
}

.ribbon-vertical--gray:before {
    border-bottom-color: rgb(146, 161.5714285714, 255);
}

.ribbon-vertical--gray:after {
    border-left-color: #f8f9ff;
    border-right-color: #f8f9ff;
}

.ribbon-vertical--dark {
    background: #7a7b97;
    color: #fff;
}

.ribbon-vertical--dark:before {
    border-bottom-color: rgb(75.0379746835, 75.7594936709, 95.9620253165);
}

.ribbon-vertical--dark:after {
    border-left-color: #7a7b97;
    border-right-color: #7a7b97;
}

.ribbon-vertical--first {
    background: #2F80ED;
    color: #fff;
}

.ribbon-vertical--first:before {
    border-bottom-color: rgb(14.4955752212, 79.7256637168, 167.5044247788);
}

.ribbon-vertical--first:after {
    border-left-color: #2F80ED;
    border-right-color: #2F80ED;
}

.ribbon-vertical--second {
    background: #070919;
    color: #fff;
}

.ribbon-vertical--second:before {
    border-bottom-color: black;
}

.ribbon-vertical--second:after {
    border-left-color: #070919;
    border-right-color: #070919;
}

.ribbon-vertical.btn {
    border-radius: 0;
    border: 0;
}

.ribbon-angle {
    position: absolute;
    z-index: 8;
    width: 120px;
    height: 120px;
    overflow: hidden;
}

.ribbon-angle > small {
    position: absolute;
    display: block;
    width: 100%;
    padding: 0.25rem;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.7307692308rem;
}

.ribbon-angle--top-right {
    top: 0;
    right: 0;
}

.ribbon-angle--top-right > small {
    transform: rotate(45deg);
    top: 16px;
    left: 27px;
}

.ribbon-angle--top-left {
    top: 0;
    left: 0;
}

.ribbon-angle--top-left > small {
    transform: rotate(-45deg);
    top: 16px;
    left: -27px;
}

.ribbon-angle--bottom-right {
    bottom: 0;
    right: 0;
}

.ribbon-angle--bottom-right > small {
    transform: rotate(-50deg);
    bottom: 21px;
    right: -31px;
}

.ribbon-angle--bottom-left {
    bottom: 0;
    left: 0;
}

.ribbon-angle--bottom-left > small {
    transform: rotate(50deg);
    bottom: 21px;
    left: -31px;
}

.ribbon-primary > small {
    color: #fff;
    background-color: #2F80ED;
}

.ribbon-secondary > small {
    color: #3b3e66;
    background-color: #f8f9ff;
}

.ribbon-success > small {
    color: #fff;
    background-color: #1bc943;
}

.ribbon-info > small {
    color: #fff;
    background-color: #11c5db;
}

.ribbon-warning > small {
    color: #fff;
    background-color: #f4772e;
}

.ribbon-danger > small {
    color: #fff;
    background-color: #f83245;
}

.ribbon-light > small {
    color: #3b3e66;
    background-color: #f4f5fd;
}

.ribbon-gray > small {
    color: #3b3e66;
    background-color: #f8f9ff;
}

.ribbon-dark > small {
    color: #fff;
    background-color: #7a7b97;
}

.ribbon-first > small {
    color: #fff;
    background-color: #2F80ED;
}

.ribbon-second > small {
    color: #fff;
    background-color: #070919;
}

.timeline-list.timeline-list--primary .timeline-item--icon, .timeline-list.timeline-list--primary::before {
    background: #2F80ED;
}

.timeline-list.timeline-list--secondary .timeline-item--icon, .timeline-list.timeline-list--secondary::before {
    background: #f8f9ff;
}

.timeline-list.timeline-list--success .timeline-item--icon, .timeline-list.timeline-list--success::before {
    background: #1bc943;
}

.timeline-list.timeline-list--info .timeline-item--icon, .timeline-list.timeline-list--info::before {
    background: #11c5db;
}

.timeline-list.timeline-list--warning .timeline-item--icon, .timeline-list.timeline-list--warning::before {
    background: #f4772e;
}

.timeline-list.timeline-list--danger .timeline-item--icon, .timeline-list.timeline-list--danger::before {
    background: #f83245;
}

.timeline-list.timeline-list--light .timeline-item--icon, .timeline-list.timeline-list--light::before {
    background: #f4f5fd;
}

.timeline-list.timeline-list--gray .timeline-item--icon, .timeline-list.timeline-list--gray::before {
    background: #f8f9ff;
}

.timeline-list.timeline-list--dark .timeline-item--icon, .timeline-list.timeline-list--dark::before {
    background: #7a7b97;
}

.timeline-list.timeline-list--first .timeline-item--icon, .timeline-list.timeline-list--first::before {
    background: #2F80ED;
}

.timeline-list.timeline-list--second .timeline-item--icon, .timeline-list.timeline-list--second::before {
    background: #070919;
}

.timeline-list-horizontal {
    width: 100%;
    position: relative;
    padding: 0;
    overflow-y: hidden;
    overflow-x: auto;
}

.timeline-list-horizontal::before {
    display: none;
}

.timeline-list-horizontal ul {
    padding-left: 0;
    list-style: none;
    margin: 0;
}

.timeline-list-horizontal ul li {
    display: table-cell;
    float: none;
    max-width: 400px;
    min-width: 300px;
}

.timeline-list-horizontal ul::before {
    content: "";
    position: absolute;
    top: 14px;
    left: 0;
    height: 4px;
    width: 100%;
    border-radius: 0.65rem;
    background: #dfe0ea;
}

.timeline-list-horizontal ul::after {
    content: "";
    display: table;
    clear: both;
}

.timeline-list-horizontal .timeline-item--icon {
    background: #dfe0ea;
}

.timeline-list-horizontal .timeline-item {
    text-align: center;
    padding: 0 1rem;
}

.timeline-list-horizontal .timeline-item .timeline-item--content {
    text-align: center;
    margin-top: 32px;
}

.timeline-list-horizontal .timeline-item .timeline-item--icon {
    top: -23px;
    left: 50%;
    margin-left: -7px;
}

.timeline-list-horizontal .timeline-item .timeline-item--label {
    font-size: 0.95rem;
    display: block;
}

.timeline-list-horizontal .timeline-item-icon .timeline-item--icon-wrapper {
    top: -48px;
    left: 50%;
    margin-left: -17px;
}

.timeline-list-horizontal .timeline-item-icon .timeline-item--content {
    margin-top: 48px;
}

.popover-custom-lg {
    max-width: 414px;
}

.popover-custom-md {
    width: 414px;
    max-width: 414px;
}

.popover-custom-xl {
    width: 552px;
    max-width: 552px;
}

.popover-custom-xxl {
    width: 828px;
    max-width: 828px;
}

.dm-sans-font-family {
    font-family: "DM Sans" !important;
}

.bg-primary {
    background-color: #2F80ED !important;
}

.text-blue-dark-valores {
    color: #6F7897;
}

.container-form-width-600 {
    max-width: 600px;
    margin: 0 auto;
}

.border-radius-3 div {
    border-radius: 3px !important;
}

.login-title {
    font-size: 32px;
    line-height: 35px;
}

.login-subtitle {
    font-size: 17px;
    line-height: 30px;
    color: white;
}

.login-subtitle-author {
    font-size: 17px;
    line-height: 30px;
    color: white;
    opacity: 0.7;
    margin-top: 16px;
}

.gold-quote {
    color: #C1921A;
    font-weight: bold;
    font-size: 32px;
    line-height: 25px;
}

.font-gray {
    color: #F2F2F2;
}

.font-red-error {
    color: #EB5757 !important;
}

.logo-login-screen {
    max-height: 100px;
}

.logo-screen-circles {
    background-image: url("images/circles-bg.png");
    opacity: 1;
}

.avatar-icon-wrapper {
    display: inline-block;
    margin-right: 0.25rem;
    position: relative;
    font-size: 1.2rem;
}

.avatar-icon-wrapper .badge {
    position: absolute;
    right: 0;
    bottom: 0;
}

.avatar-icon {
    display: block;
    width: 44px;
    height: 44px;
    line-height: 44px;
    transition: all 0.2s ease-in-out;
    opacity: 1;
    border-radius: 50px;
}

.avatar-icon.rounded {
    border-radius: 0.65rem !important;
}

.avatar-icon-xl {
    font-size: 1.9rem;
}

.avatar-icon-xl .avatar-icon {
    width: 64px;
    height: 64px;
    line-height: 62px;
}

.avatar-icon-lg {
    font-size: 1.6rem;
}

.avatar-icon-lg .avatar-icon {
    width: 54px;
    height: 54px;
    line-height: 52px;
}

.avatar-icon-sm {
    font-size: 0.95rem;
}

.avatar-icon-sm .avatar-icon {
    width: 34px;
    height: 34px;
    line-height: 32px;
}

.avatar-icon-xs {
    font-size: 0.75rem;
    font-weight: bold;
}

.avatar-icon-xs .avatar-icon {
    width: 26px;
    height: 26px;
    line-height: 26px;
    transition: transform 0.2s;
}

.avatar-icon {
    box-shadow: 0 0 0 3px #fff;
    overflow: hidden;
}

.avatar-icon img {
    width: 100%;
    height: 100%;
    display: block;
}

.avatar-wrapper-overlap {
    display: flex;
}

.avatar-wrapper-overlap .avatar-icon-wrapper {
    z-index: 5;
    margin-left: -18px;
}

.avatar-wrapper-overlap .avatar-icon-wrapper:hover {
    z-index: 7;
}

.avatar-wrapper-overlap .avatar-icon-wrapper.avatar-icon-xl {
    margin-left: -30px;
}

.avatar-wrapper-overlap .avatar-icon-wrapper.avatar-icon-lg {
    margin-left: -24px;
}

.avatar-wrapper-overlap .avatar-icon-wrapper.avatar-icon-sm {
    margin-left: -14px;
}

.avatar-wrapper-overlap .avatar-icon-wrapper.avatar-icon-xs {
    margin-left: -10px;
}

.avatar-wrapper-overlap .avatar-icon-wrapper:first-child {
    margin-left: 0 !important;
}

.avatar-initials {
    text-align: center;
    text-transform: uppercase;
}

.gray-light-valores {
    color: #929BB6;
}

.gray-color {
    color: #6F7897;
}

.font-16-size {
    font-size: 16px;
    line-height: 35px;
}

.font-17-size {
    font-size: 16px;
    line-height: 22px;
}

.font-14-size {
    font-size: 14px;
    line-height: 35px;
}

.font-20-size {
    font-size: 20px;
    line-height: 35px;
}

.font-20-size-500 {
    font-weight: 500;
    font-size: 20px;
    line-height: 35px;
}

.font-17-size-500 {
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
}

.font-15-size-500 {
    font-weight: 500;
    font-size: 17px;
    line-height: 19px;
}

#code-input-form {
    max-width: 420px;
    margin: 0 auto;
}

.submit-button {
    border-radius: 5px !important;
    font-size: 18px !important;
    font-weight: bold !important;
    min-width: 150px !important;
    height: 52.5px !important;
}

.tab-text {
    font-weight: 700 !important;
    font-size: 20px !important;
    color: #9aa0b6 !important;
    text-transform: uppercase !important;
}

.tab-text.Mui-selected {
    color: #6F7897 !important;
}

.language-button-gold {
    min-width: 63px;
    background: rgba(193, 146, 26, 0.3) !important;
    font-weight: 700 !important;
    border-radius: 3px !important;
    color: #C1921A !important;
}

.language-button-grey {
    min-width: 63px;
    background: rgba(135, 135, 135, 0.3) !important;
    font-weight: 700 !important;
    border-radius: 3px !important;
    color: #6f7898 !important;
}

.page404-wrapper {
    background: url("images/circles-404-page.png") no-repeat fixed center center/cover;
}

.page404-text {
    font-size: 32px;
}

@media (max-width: 959.98px) {
    .page404-text {
        font-size: 22px;
    }
}

.page404-button {
    width: 193px !important;
    height: 63px !important;
}

.page404-text__404 {
    font-size: 120px;
}

@media (max-width: 959.98px) {
    .page404-text__404 {
        font-size: 100px;
    }
}

body .app-header {
    height: 74px;
    display: flex;
    flex-direction: row;
}

@media (min-width: 1280px) {
    body .app-header-collapsed-sidebar {
        padding-left: 11.25px;
    }
}

body .app-header-toolbar {
    height: 100%;
    flex: 1;
    display: flex;
    justify-content: space-between;
    padding-left: 1rem;
    padding-right: 1rem;
}

body .app-header-toolbar .btn-inverse:hover {
    background: rgba(255, 255, 255, 0.07);
}

body .btn-toggle-collapse {
    margin-left: -75px;
    padding-right: 30px;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

@media (max-width: 1279.98px) {
    body .btn-toggle-collapse {
        display: none;
    }
}

body .btn-toggle-collapse-closed {
    margin-left: 0;
    margin-right: 1rem;
    padding-right: 0;
}

body .app-logo-img {
    width: 28px;
    height: 28px;
}

body .app-logo-wrapper {
    display: flex;
    align-items: center;
}

body .app-logo-btn {
    padding: 0.5rem;
    background: rgba(255, 255, 255, 0.05);
}

body .app-logo-btn:hover {
    background: rgba(255, 255, 255, 0.08);
}

body .app-logo-link {
    text-decoration: none;
}

@media (min-width: 1280px) {
    body .app-logo-link {
        margin-top: 2rem;
    }
}

@media (max-width: 1919.98px) {
    body .app-logo-link {
        margin-right: 1rem;
    }
}

body .app-logo-text {
    font-size: 0.95rem;
    font-weight: bold;
    width: auto;
    display: block;
}

body .toggle-sidebar-btn-mobile {
    display: none;
}

@media (max-width: 1279.98px) {
    body .toggle-sidebar-btn-mobile {
        display: block;
    }
}

body .app-header-logo {
    height: 74px;
    width: 270px;
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.08);
    padding-left: 1rem;
    padding-right: 1rem;
    justify-content: space-between;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

@media (max-width: 1279.98px) {
    body .app-header-logo {
        display: none;
    }
}

body .app-header-logo-close {
    width: 90px;
    overflow: hidden;
}

body .app-header-logo-close .header-logo-wrapper {
    margin: auto;
}

body .app-header-logo-close .header-logo-text {
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    width: 0;
    padding-left: 0;
}

body .app-header-logo-open {
    background: rgba(255, 255, 255, 0.08);
}

body .app-header-logo-img {
    width: 80%;
}

body .header-logo-wrapper {
    display: flex;
    align-items: center;
}

body .header-logo-wrapper-btn {
    padding: 0.3333333333rem;
    color: #fff;
    background: rgba(255, 255, 255, 0.09);
}

body .header-logo-wrapper-btn:hover {
    background: rgba(255, 255, 255, 0.14);
}

body .header-logo-wrapper-link {
    text-decoration: none;
}

body .header-logo-text {
    padding-left: 0.5rem;
    font-size: 1.045rem;
    font-weight: bold;
    width: auto;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

body .badge-header {
    position: absolute;
    top: 1px;
    right: 1px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    padding: 0;
    font-size: 0.83125rem;
}

body .height-280 {
    height: 280px;
}

body .app-header-dots {
    overflow: hidden;
}

body .app-header-drawer {
    width: 450px;
    height: 100%;
}

body .app-search-wrapper {
    background-color: #f8f9ff;
    border-radius: 0.29rem;
    padding: 2rem 0;
    display: flex;
    align-items: center;
}

body .app-search-icon {
    color: inherit;
}

body .app-search-input {
    background: #fff;
}

body .search-results {
    transition: all 0.2s ease-in-out;
    opacity: 1;
    visibility: visible;
    height: auto;
}

body .no-search-results {
    display: flex;
    min-height: 350px;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;
    opacity: 1;
    visibility: visible;
    text-align: center;
    height: auto;
}

body .search-results-hidden {
    opacity: 0;
    height: 0;
    min-height: 0;
    visibility: hidden;
    overflow: hidden;
}

body .app-search-popper {
    z-index: 1333;
}

body .dropdown-mega-menu-lg {
    width: 54rem;
}

@media (max-width: 1919.98px) {
    body .dropdown-mega-menu-lg {
        width: 30rem;
    }
}

body .dropdown-mega-menu-md {
    width: 25rem;
}

body .dropdown-mega-menu-sm {
    width: 20rem;
}

body .app-header-menu {
    position: relative;
    transition: all 0.2s ease-in-out;
    transform: scale(1);
    visibility: visible;
    transition-delay: 0.2s;
    margin-left: 0.5rem;
}

@media (max-width: 1279.98px) {
    body .app-header-menu {
        display: none;
    }
}

.app-sidebar-collapsed-wrapper {
    width: 90px;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1100;
    height: 100vh;
}

.app-sidebar-collapsed-wrapper .app-sidebar--content ul {
    listStyle: none;
    margin: 0;
    padding: 1rem 0;
}

.app-sidebar-collapsed-wrapper .app-sidebar--content ul li {
    text-align: center;
    padding: 0.5rem 1rem;
}

.app-sidebar-btn-wrapper {
    text-align: center;
    display: block;
    padding: 1rem 0;
    border-radius: 0.65rem;
    transition: all 0.2s ease-in-out;
}

.app-sidebar-btn-wrapper.active, .app-sidebar-btn-wrapper:hover {
    color: #2F80ED;
    background: rgb(235.6310344828, 237.5206896552, 246.9689655172);
}

.app-sidebar-btn-icon {
    stroke: #2F80ED;
    fill: rgb(234.0115384615, 245.9461538462, 254.5884615385);
}

.sidebar-collapsed-logo {
    height: 74px;
    width: 90px;
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.08);
    justify-content: center;
    transition: all 0.2s ease-in-out;
}

body .app-sidebar-wrapper-lg {
    min-height: 100%;
    overflowY: auto;
    display: flex;
    flex-direction: column;
    paddingTop: 0;
    width: 270px;
}

body .app-sidebar-menu {
    height: calc(100vh - 74px);
}

body .app-sidebar-wrapper {
    min-height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    z-index: 60;
    position: static;
    width: 270px;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

body .app-sidebar-wrapper-fixed {
    position: fixed;
}

body .app-sidebar-wrapper-close {
    width: 11.25px;
}

body .app-sidebar-wrapper-close .sidebar-menu-children {
    display: none;
}

body .app-sidebar-wrapper-open {
    width: 270px;
}

body .app-sidebar-wrapper-open .sidebar-menu-children {
    display: block;
}

body .app-sidebar-nav-close button span span {
    transition: all 0.2s ease-in-out;
    white-space: nowrap;
    opacity: 0;
    margin-bottom: 1rem;
    visibility: hidden;
}

body .app-sidebar-nav-close svg:first-child {
    transition: all 0.2s ease-in-out;
    margin-right: 50px;
    margin-left: 17px;
}

body .app-sidebar-nav-close .app-sidebar-heading {
    opacity: 0;
    overflow: hidden;
    white-space: nowrap;
    visibility: hidden;
    height: 44px;
}

body .app-sidebar-collapsed .app-sidebar-footer-wrapper {
    visibility: hidden;
    opacity: 0;
}

body .app-sidebar-logo {
    width: 32px;
    height: 32px;
}

body .app-sidebar-header {
    height: 74px;
    width: 270px;
    display: flex;
    align-items: center;
    background: rgb(255, 255, 255);
    padding-left: 1rem;
    padding-right: 1rem;
    justify-content: space-between;
    transition: all 0.2s ease-in-out;
}

body .app-sidebar-header .header-logo-wrapper-btn {
    background: #f8f9ff;
    color: #f4f5fd;
}

body .app-sidebar-header-close {
    width: 90px;
    overflow: hidden;
}

body .app-sidebar-header-close .header-logo-wrapper {
    margin: auto;
}

body .app-sidebar-header-close .header-logo-text {
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    width: 0;
    padding-left: 0;
}

body .app-sidebar-header-btn {
    transition: all 0.2s ease-in-out;
    opacity: 1;
    visibility: visible;
}

@media (max-width: 1919.98px) {
    body .app-sidebar-header-btn {
        display: none;
    }
}

body .app-sidebar-header-btn-close {
    opacity: 0;
    visibility: hidden;
    display: none;
}

body .app-sidebar-header-btn-mobile {
    display: none;
}

@media (max-width: 1919.98px) {
    body .app-sidebar-header-btn-mobile {
        display: block;
    }
}

body .app-sidebar-userbox {
    background: #f4f5fd;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    margin: 0;
    border-radius: 0;
    text-align: center;
    white-space: nowrap;
}

body .app-sidebar-userbox .app-sidebar-userbox-avatar {
    width: 54px;
    height: 54px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0.5rem;
    transition: all 0.2s ease-in-out;
}

body .app-sidebar-userbox .app-sidebar-userbox-name {
    transition: all 0.2s ease-in-out;
    opacity: 1;
    height: auto;
    white-space: nowrap;
}

body .app-sidebar-userbox .app-sidebar-userbox-description {
    opacity: 0.7;
    margin-bottom: 0.5rem;
}

body .app-sidebar-userbox .app-sidebar-userbox-btn {
    position: absolute;
    right: 1rem;
    top: 1rem;
}

body .app-sidebar-userbox .app-sidebar-userbox-btn-profile {
    opacity: 1;
    visibility: visible;
    transition: all 0.2s ease-in-out;
}

body .app-sidebar-userbox--collapsed {
    padding-left: 1rem;
    padding-right: 1rem;
    background: transparent;
}

body .app-sidebar-userbox--collapsed .app-sidebar-userbox-avatar {
    width: 34px;
    height: 34px;
    margin-bottom: 0;
}

body .app-sidebar-userbox--collapsed .app-sidebar-userbox-name {
    opacity: 0;
    height: 0;
}

body .app-sidebar-userbox--collapsed .app-sidebar-userbox-btn {
    opacity: 0;
}

body .app-sidebar-userbox--collapsed .app-sidebar-userbox-btn-profile {
    opacity: 0;
    visibility: hidden;
}

body .app-sidebar-heading {
    padding: 1rem 1rem 0.9090909091rem;
    font-weight: bold;
    color: #2F80ED;
    text-transform: uppercase;
    font-size: 0.83125rem;
    white-space: nowrap;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

body .app-sidebar-item {
    display: block;
    padding: 2px 1rem 1px 0;
}

body .app-sidebar-button {
    padding-top: 0.4166666667rem;
    padding-bottom: 0.4166666667rem;
    padding-right: 0.5rem;
    justify-content: flex-start;
    text-transform: none;
    border-radius: 0 22px 22px 0;
    font-size: 15px;
    width: 100%;
    font-weight: normal;
    transition: all 0.2s ease-in-out;
    color: #2F80ED;
    white-space: nowrap;
}

body .app-sidebar-button.active, body .app-sidebar-button:hover {
    color: #2F80ED;
    background: #eeeff8;
}

body .app-sidebar-button-wrapper {
    padding: 4px 17px;
    justify-content: flex-start;
    text-transform: none;
    width: 100%;
    border-radius: 3px;
    transition: all 0.2s ease-in-out;
    font-weight: normal;
    font-size: 14px;
    color: #2F80ED;
}

body .app-sidebar-button-wrapper.depth-0 {
    font-weight: 500;
}

body .app-sidebar-button-wrapper.active, body .app-sidebar-button-wrapper:hover {
    background: none;
    color: #2F80ED;
}

body .app-sidebar-icon {
    display: flex;
    align-items: center;
    margin-right: 16px;
}

body .sidebar-expand-icon {
    margin-left: auto;
    height: 16px;
    width: 16px;
    opacity: 0.6;
    transition: all 0.2s ease-in-out;
}

body .sidebar-expand-icon-rotate {
    opacity: 0.9;
    transform: rotate(180deg);
}

body .menu-item-label {
    display: flex;
    align-items: center;
    margin-left: auto;
}

body .active-item {
    color: #2F80ED;
    font-weight: 500;
}

body .active-item .app-sidebar-icon {
    color: #2F80ED;
}

.app-sidebar-footer-wrapper {
    background: rgba(0, 0, 0, 0.03);
    margin: 1rem;
    border-radius: 0.29rem;
    transition: all 0.2s ease-in-out;
}

.app-sidebar-footer {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    padding: 0 1rem;
    margin: 0;
}

.app-page-title {
    margin: -2rem -2rem 2rem;
    padding: 2rem 6rem 2rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(255, 255, 255, 0.5);
    transition: all 0.2s ease-in-out;
    position: relative;
}

@media (max-width: 1279.98px) {
    .app-page-title {
        flex-direction: column;
        padding-right: 2rem;
    }
}

.app-page-title--dark .MuiBreadcrumbs-li,
.app-page-title--dark .MuiBreadcrumbs-li a,
.app-page-title--dark .MuiBreadcrumbs-li p,
.app-page-title--dark .MuiBreadcrumbs-separator,
.app-page-title--dark .app-page-title--heading {
    color: white;
}

.app-page-title--first {
    flex-grow: 1;
    display: flex;
    align-items: center;
}

@media (max-width: 1279.98px) {
    .app-page-title--first {
        flex-direction: column;
        margin-bottom: 1.5rem;
    }
}

.app-page-title--second {
    display: flex;
    align-items: center;
}

.app-page-title--heading {
    padding-right: 1rem;
}

@media (max-width: 1279.98px) {
    .app-page-title--heading {
        padding-right: 0;
        text-align: center;
    }
}

.app-page-title--heading h1 {
    font-size: 1.5454545455rem;
    font-weight: 700;
    margin: 0;
}

.app-page-title--iconbox {
    background: #fff;
    border-radius: 100%;
    display: flex;
    margin: 0 1.5rem 0 0;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1279.98px) {
    .app-page-title--iconbox {
        margin: 0 0 1.5rem;
    }
}

@media (max-width: 959.98px) {
    .app-page-title--iconbox {
        display: none !important;
    }
}

.app-page-title--description {
    margin: 0.5rem 0 0;
    font-size: 1.045rem;
    opacity: 0.6;
    font-weight: normal;
}

.app-page-title.app-page-title-alt-1 {
    background: #fff;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.app-page-title.app-page-title-alt-1 .app-page-title--heading {
    display: flex;
    align-items: center;
}

.app-page-title.app-page-title-alt-1 .app-page-title--heading h1 {
    font-size: 1.1875rem;
}

.app-page-title.app-page-title-alt-1 .app-page-title--description {
    width: 75%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0 0 0 0.5rem;
    font-size: 0.95rem;
}

.app-page-title.app-page-title-alt-1 .app-page-title--iconbox {
    box-shadow: none;
    background: none;
    margin-right: 0.5rem;
    width: 32px !important;
    height: 32px !important;
}

.app-page-title.app-page-title-alt-1 .app-page-title--iconbox .d-70 {
    width: 32px !important;
    height: 32px !important;
}

.app-page-title.app-page-title-alt-1 .app-page-title--iconbox .d-70 .display-3 {
    font-size: 1.1875rem !important;
}

.app-page-title.app-page-title-alt-2 {
    margin: -2rem 0 2rem;
    padding: 2rem;
    border-bottom-right-radius: 0.65rem;
    border-bottom-left-radius: 0.65rem;
}

.app-page-title.app-page-title-alt-3 {
    margin: 0 0 2rem;
    padding: 1.3333333333rem 2rem;
    border-radius: 0.65rem;
}

.app-page-title.app-page-title-alt-3 .app-page-title--iconbox {
    border-radius: 100%;
}

.speedial-wrapper {
    position: absolute;
    top: 50%;
    right: 1rem;
    margin-top: -28px;
}

.speedial-wrapper .MuiSpeedDial-root {
    right: 0;
    position: absolute;
}

body .app-footer {
    height: 70px;
    background: #fff;
    width: 100%;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    font-size: 0.83125rem;
}

body .app-footer--inner {
    padding: 0 2rem;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    height: 70px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

@media (max-width: 1919.98px) {
    body .app-footer--inner {
        display: block;
        text-align: center;
        height: auto;
        padding: 0.3333333333rem 0.5rem 0.5rem;
    }

    body .app-footer--inner .MuiList-root {
        justify-content: center;
    }

    body .app-footer--inner .MuiList-root .MuiButtonBase-root {
        text-align: center;
    }
}

@media (max-width: 1919.98px) {
    body .app-footer {
        display: block;
        text-align: center;
        height: auto;
        padding: 0.3333333333rem 0.5rem 0.5rem;
    }

    body .app-footer .MuiList-root {
        justify-content: center;
    }

    body .app-footer .MuiList-root .MuiButtonBase-root {
        text-align: center;
    }
}

body .app-footer:hover {
    background: #fff !important;
}

@media (max-width: 1919.98px) {
    body .app-footer--second span:first-child {
        display: block;
        margin-bottom: 0.5rem;
    }
}

body .app-footer--fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 22;
    padding-left: 270px;
}

body .app-footer--fixed .app-content--inner__wrapper {
    padding-bottom: 70px;
}

@media (min-width: 1280px) {
    body .app-footer--fixed.app-footer--fixed__collapsed {
        padding-left: 11.25px;
    }
}

body .theme-configurator {
    display: none;
}

@media (min-width: 960px) {
    body .theme-configurator {
        position: fixed;
        right: 0;
        top: 0;
        z-index: 1165;
        display: block;
        height: 100vh;
    }

    body .theme-configurator--heading {
        font-size: 0.83125rem;
        padding: 0.6666666667rem 1rem;
        background: #f8f9ff;
        border-color: #eeeff8;
        border-width: 1px 0;
        border-style: solid;
        font-weight: bold;
        text-transform: uppercase;
        color: #3b3e66;
        margin: 0;
    }

    body .theme-configurator--list {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    body .theme-configurator--list li {
        padding: 1rem 1.5rem;
        display: flex;
        align-items: center;
        border-bottom: #eeeff8 solid 1px;
        font-weight: bold;
    }

    body .theme-configurator--list li:last-child {
        border-color: transparent;
    }

    body .theme-configurator--list__heading {
        padding-left: 0.5rem;
    }

    body .theme-configurator--list__heading span {
        display: block;
        opacity: 0.6;
        font-size: 0.83125rem !important;
    }

    body .theme-configurator--drawer {
        background: #fff;
        width: 420px;
        height: 100%;
        box-shadow: 0 0 0 0 transparent;
        transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        position: relative;
        z-index: 5;
    }

    body .theme-configurator--swatches {
        text-align: center;
        padding: 0.5rem;
    }

    body .theme-configurator--swatches .theme-config-swatch {
        width: 32px;
        height: 32px;
        margin: 0.5rem;
        border-radius: 0.29rem;
        display: inline-block;
        opacity: 0.7;
        z-index: 10;
        position: relative;
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        border: #fff solid 1px;
        transform-origin: center;
    }

    body .theme-configurator--swatches .theme-config-swatch.active, body .theme-configurator--swatches .theme-config-swatch:hover {
        opacity: 1;
        transform: scale(1.3);
        z-index: 15;
        box-shadow: 0 0 0 2px #2F80ED;
    }

    body .theme-configurator--swatches .theme-config-swatch--lg {
        width: 38px;
        height: 38px;
        border: #fff solid 2px;
        box-shadow: 0 0 0 1px #a6a6b9;
    }

    body .theme-configurator .bg-current-scheme {
        width: 100%;
        height: 34px;
        border-radius: 0.29rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

body .configurator-btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

body .theme-config-wrapper {
    position: fixed;
    z-index: 1111;
    right: 0;
    top: 30%;
}

body .theme-config-content {
    width: 440;
    height: 100%;
}

body .drawer-wrapper-right {
    box-shadow: 2px 2px 22px #ccc;
}

body .drawer-backdrop {
    background: transparent;
}

body .drawer-close-btn {
    position: fixed;
    right: 16px;
    top: 16px;
}

.popover-share-box {
    padding: 15px;
    width: 552px;
    max-width: 552px;
}

.promo-section-wrapper {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1163;
    display: block;
    height: 100vh;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

@media (max-width: 1279.98px) {
    .promo-section-wrapper {
        display: none;
        opacity: 0;
        visibility: hidden;
    }
}

.promo-section-buttons-wrapper {
    position: absolute;
    right: 0;
    top: 45%;
}

.promo-section-buttons {
    box-shadow: 0 0.313rem 0.8rem rgba(122, 123, 151, 0.5), 0 0.126rem 0.225rem rgba(122, 123, 151, 0.3);
    display: flex;
    padding: 10px 15px;
    flex-direction: column;
    align-items: center;
    background: #fff;
    border-top-left-radius: 0.65rem;
    border-bottom-left-radius: 0.65rem;
}

.promo-section-buttons .btn-squared {
    width: 50px;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
}

.promo-section-buttons .btn {
    margin: 5px 0;
}

.toolBarTitle {
    background-color: #fff;
    background-position: center;
    background-size: cover;
    background-image: url("images/toolbar.png");
}

.light-text {
    color: rgba(111, 120, 152, 0.7);
    font-family: "DM Sans", serif;
}

.dark-text {
    color: #6F7898;
    font-family: "DM Sans", serif;
}

.bottomBar {
    background-image: url("images/footer.png");
    background-color: #fff;
    background-position: center;
    background-size: cover;
}

.font-size-16 {
    font-size: 16px !important;
}

@media (max-width: 599.98px) {
    .font-size-16 {
        font-size: 14px !important;
    }
}

.font-size-18 {
    font-size: 18px !important;
}

@media (max-width: 599.98px) {
    .font-size-18 {
        font-size: 15px !important;
    }
}

.title {
    color: #C1921A;
    font-size: 30px;
    line-height: 35px;
    font-weight: bold;
    font-family: "DM Sans", serif;
}

@media (max-width: 599.98px) {
    .title {
        font-size: 25px !important;
        line-height: 27px !important;
    }
}

.text-gold {
    color: #C1921A;
}

.content {
    color: #6F7897;
    font-size: 16px;
    font-weight: normal;
    font-family: "DM Sans", serif;
}

@media (max-width: 599.98px) {
    .content {
        font-size: 14px !important;
    }
}

.text-md-right {
    text-align: right;
}

@media (max-width: 1919.98px) {
    .text-md-right {
        text-align: left;
    }
}

.revision-account-table-header {
    background-color: rgba(47, 128, 237, 0.2);
    color: #2F80ED;
    font-family: "DM Sans", serif;
    border-radius: 0.29rem;
}

.revision-account-table-item {
    padding: 24px 0;
    font-weight: normal;
    text-align: center;
    font-family: "DM Sans", serif;
    color: #6F7897;
}

.bg-gold {
    background-color: #C1921A !important;
    color: #fff !important;
}

.bg-silver {
    background-color: #878787 !important;
    color: #fff !important;
}

.bg-platinum {
    background-color: #c1c1c1 !important;
    color: #fff !important;
}

.bg-palladium {
    background-color: #dbdbdb !important;
    color: #fff !important;
}

.border-radius-sm {
    border-radius: 0.29rem !important;
}

html,
body {
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: auto;
    height: 100%;
}

body {
    overflow-x: hidden;
}

.app-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

.app-main {
    position: relative;
    min-height: 100vh;
}

.app-content {
    flex: 1;
    display: flex;
    padding: 0;
    flex-direction: column;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    padding-top: 74px;
}

.app-content--inner {
    padding: 2rem 2rem 1rem;
    display: flex;
    flex: 1;
}

.app-content--inner__wrapper {
    display: block;
    width: 100%;
}

.app-content-sidebar-fixed {
    min-height: 100vh;
}

@media (min-width: 1280px) {
    .app-content-sidebar-fixed {
        padding-left: 270px;
    }
}

.app-main-sidebar-static {
    display: flex;
}

.app-content-footer-fixed {
    padding-bottom: 70px;
    height: auto;
}

@media (min-width: 1280px) {
    .app-content-sidebar-collapsed {
        padding-left: 11.25px;
    }
}

.app-inner-content-layout {
    display: flex;
    flex-direction: column;
}

@media (min-width: 1280px) {
    .app-inner-content-layout {
        flex-direction: row;
    }
}

.app-inner-content-layout-fixed {
    bottom: 0;
    left: 0;
    right: 0;
    top: 74px;
    position: absolute;
}

.app-inner-content-layout--sidebar {
    width: 280px;
    display: flex;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    flex-direction: column;
    position: relative;
    z-index: 900;
    box-shadow: 0px 0px 28px 0px rgba(47, 128, 237, 0.08);
}

.app-inner-content-layout--sidebar__content {
    padding: 1rem;
}

.app-inner-content-layout--sidebar__lg {
    width: 380px;
}

@media (max-width: 1919.98px) {
    .app-inner-content-layout--sidebar__lg {
        width: 280px;
    }
}

.app-inner-content-layout--sidebar__sm {
    width: 250px;
}

.app-inner-content-layout--sidebar__xl {
    width: 440px;
}

@media (max-width: 1919.98px) {
    .app-inner-content-layout--sidebar__xl {
        width: 280px;
    }
}

@media (max-width: 1279.98px) {
    .app-inner-content-layout--sidebar {
        left: -380px;
        position: fixed;
        z-index: 1175;
        top: 0;
        height: 100vh;
        width: 280px;
    }

    .app-inner-content-layout--sidebar.layout-sidebar-open {
        left: 0;
        box-shadow: 0.46875rem 0 2.1875rem rgba(59, 62, 102, 0.03), 0.9375rem 0 1.40625rem rgba(59, 62, 102, 0.03), 0.25rem 0 0.53125rem rgba(59, 62, 102, 0.05), 0.125rem 0 0.1875rem rgba(59, 62, 102, 0.03);
        padding-top: 74px;
    }

    .app-inner-content-layout--sidebar.pos-r {
        left: auto;
        right: -380px;
    }

    .app-inner-content-layout--sidebar.pos-r.layout-sidebar-open {
        left: auto;
        right: 0;
        box-shadow: -0.46875rem 0 2.1875rem rgba(59, 62, 102, 0.03), -0.9375rem 0 1.40625rem rgba(59, 62, 102, 0.03), -0.25rem 0 0.53125rem rgba(59, 62, 102, 0.05), -0.125rem 0 0.1875rem rgba(59, 62, 102, 0.03);
    }
}

.app-inner-content-layout--main {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: calc(100vh - 74px);
}

@media (max-width: 1279.98px) {
    .app-inner-content-layout--main {
        height: calc(100vh - (74px + 88px));
    }
}

.app-content > .app-inner-content-layout--main {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.app-content > .app-inner-content-layout--main > div:not([class]) {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

@media (min-width: 1280px) {
    .app-inner-content-layout-fixed {
        left: 90px;
    }
}

.sidebar-inner-layout-overlay {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(7, 9, 25, 0.4);
    z-index: 1165;
}

@media (max-width: 1919.98px) {
    .sidebar-inner-layout-overlay.active {
        opacity: 1;
        visibility: visible;
    }
}

.app-main.flex-column {
    display: flex;
    flex: 1 auto;
    min-width: 0;
}

.w-full-width {
    width: 100% ;
}

.text-wrapper {
    max-width: 35%;
    margin-left: 35px;
}

@media (max-width: 1279.98px) {
    .text-wrapper {
        max-width: 28vh;
    }
}

@media (max-width: 599.98px) {
    .text-wrapper {
        max-width: 25vh;
        margin-right: 20px;
        margin-left: -10px;
    }
}

.divider-bottom {
    width: 100% !important;
    background-color: #dfe0ea !important;
}

.divider-top {
    border-bottom: 1px solid #dfe0ea !important;
}

.input-field {
    width: 357px;
}

.input-field-text {
    color: #6f7898 !important;
}

.term-subscription-title {
    color: #6f7898;
}

.text {
    color: #6f7898;
}

.box-overflow {
    overflow-x: hidden !important;
}

.terms {
    color: #C1921A !important;
}

.term-margin {
    margin-top: -5px;
}

@media (max-width: 599.98px) {
    .term-margin {
        max-width: 30vh;
    }
}

.login-links-container {
    margin: 30px 0;
}

.login-links-container > *:not(:last-child) {
    margin-right: 30px;
}

.login-link-icon:hover .MuiIconButton-label svg > * {
    opacity: 1;
    transition: 0.1s ease-in;
}

@media (min-width: 900px) {
    .justify-content-sx-md-start {
        justify-content: flex-start !important;
    }
}

.header-nav-wrapper-min-height {
    min-height: 84px
}